import Vue from 'vue'
import store from './store'

import { Toast, Loading, Drawer } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import { getOpts4FreeShippingFromCheckout } from './utils/helper'

Vue.prototype.$toast = Toast
Vue.component(Loading.name, Loading)
Vue.component(Drawer.name, Drawer)
Vue.component(Icon.name, Icon)

let instance

export default {
  open(config) {
    if (Vue.prototype.$isServer) return

    if (instance) {
      return instance.open(config)
    }

    return new Promise((resolve) => {
      import(/* webpackChunkName: "add_on_container" */ './container.vue').then((components) => {
        const vm = new Vue({
          render (h) {
            return h(components.default)
          },
          store,
        }).$mount()
        document.body.appendChild(vm.$el)
        const comp = vm.$children[0]
        comp.open(config)
        instance = comp

        resolve(instance)
      })
    })
  },
  close() {
    if (!instance) return
    instance.handleClose()
  },
  isOpened() {
    if (!instance) return false
    return instance.visible
  },
  updateOpts(options) {
    if (!instance) return
    instance.updateOpts(options)
  },
  getOpts4FreeShippingFromCheckout,
}
