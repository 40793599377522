<template>
  <s-drawer
    ref="payDrawerRef"
    :visible.sync="show"
    direction="btt"
    append-to-body
    class="footer-pay-methods-drawer"
    @close="close"
  >
    <template slot="top">
      {{ language.SHEIN_KEY_PWA_15145 }}
    </template>
    <div class="paid-member-pay">
      <pay-price
        :language="language"
        :is-select="isSelect"
        :show="show"
        :all-pay-had-discount="isAllPayHadDiscount"
        :z-index="zIndex"
        :discount-map="discountMap"
      />
      <pay-list
        ref="payMethodsListRef"
        :paymentMethods="paymentMethods"
        :paymentMethodsProps="paymentMethodsProps"
        :language="language"
        :show="show"
        @changePay="changePay"
        @confirm-ppgv-type="confirmPPGVType"
        @handle-toggle-ppga-type="handleTogglePpgaType"
        @handle-toggle-afterpay-Type="handleToggleAfterpayType"
      />
    </div>

    <template slot="footer">
      <div @click="handlerConfirm">
        <slot></slot>
      </div>
    </template>
  </s-drawer>
</template>

<script setup>
// components
import { Drawer as SDrawer } from '@shein/sui-mobile'
import PayList from './pay_list.vue'
import { Toast as $toast } from '@shein/sui-mobile'
import PayPrice from './pay_price.vue'

// utils
import { ref, watch, defineEmits, onMounted, computed } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { useDiscount } from './useDiscount'
import { useStore } from 'public/src/pages/checkout/hooks/index.js'

// 使用useStore获取store实例
const store = useStore()

// 使用计算属性获取isShowHomogenizationDrawer的值
const isShowHomogenizationDrawer = computed(() => store.state?.checkout?.isShowHomogenizationDrawer)

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default: () => {}
  },
  paymentMethodsProps: {
    type: Object,
    default: () => {}
  },
  paymentMethods: {
    type: Array,
    default: () => []
  },
  isSelect: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits([
  'change-visible',
  'create-order',
  'handle-toggle-ppga-type'
])

const show = ref(false)
const nowSelectPay = ref({})
const payMethodsListRef = ref(null)
const isAllPayHadDiscount = ref(false)
const payDrawerRef = ref(null)
const zIndex = ref(0)
const discountMap = ref(new Map())

watch(
  () => props.visible,
  val => {
    if (typeof window === 'undefined') return
    show.value = val

    if (val) {
      daEventCenter.triggerNotice({
        daId: '1-11-1-212'
      })
    }

    setTimeout(() => {
      zIndex.value = parseInt(payDrawerRef.value?.$el?.style?.zIndex) + 1
    }, 0)
  }
)

const close = () => {
  emits('change-visible', show.value)
}

const changePay = pay => {
  nowSelectPay.value = pay
}

const handlerConfirm = event => {
  if (Object.keys(nowSelectPay.value)?.length === 0) {
    $toast(props.language.SHEIN_KEY_PWA_24844)
    event.stopPropagation()
    return
  }

  show.value = false

  emits('create-order')
}

const confirmPPGVType = () => {
  emits('confirm-ppgv-type')
}

const handleTogglePpgaType = () => {
  emits('handle-toggle-ppga-type')
}

const handleToggleAfterpayType = () => {
  emits('handle-toggle-afterpay-type')
}

const setAllPayHadDiscount = () => {
  let discountList = []
  let tempMap = new Map()
  props.paymentMethods?.map(pay => {
    const { isHadDisCount } = useDiscount({
      initialPayment: pay,
      language: props.language,
      isUseInitialPayment: true
    })
    if (isHadDisCount()) {
      discountList.push(pay)

      tempMap.set(pay.code, isHadDisCount())
    }
  })

  isAllPayHadDiscount.value = discountList.length > 0
  discountMap.value = tempMap
}

onMounted(() => {
  setAllPayHadDiscount()
  window?.appEventCenter.$on('applepay-create-order', () => {
    show.value = false
  })

  window?.appEventCenter?.$on('capitecBankSaved', () => {
    if(!isShowHomogenizationDrawer.value) {
      show.value = false
    }
  })
})
</script>

<style lang="less">
// stylelint-disable-next-line
.footer-pay-methods-drawer {
  .S-drawer__container {
    background-color: white;
  }
  .S-drawer__normal-footer {
    margin: 8 * 2/75rem 12 * 2/75rem;
  
  }
  
  .footer-summary-btn {
    // stylelint-disable-next-line
    width: unset !important;
    // stylelint-disable-next-line
    max-width: unset !important;

    .S-adapterText {
      // stylelint-disable-next-line
      .S-text-container {
        // stylelint-disable-next-line
        width: unset !important;
      }
    }
  }
}
</style>
