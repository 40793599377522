<template>
  <div>
    <div 
      class="shipping-method-item__large-wrap"
      @click.stop="handleTipsClick"
    >
      <GoodsInfo 
        v-if="lagreShipInfo.largeShipGoods && lagreShipInfo.largeShipGoods.length"
        class="shipping-method-item__large-wrap-goods"
        :largeShipGoods="lagreShipInfo.largeShipGoods"
      />
      <div class="shipping-method-item__large-wrap-tips">
        <div 
          class="detail"
          v-html="largeShipDetail"
        >
        </div>
        <i
          class="sui_icon_more_right_16px"
          :class="[fsIconPrefix]"
        ></i>
      </div>
    </div>

    <ClientOnly>
      <LargeInformDrawer 
        v-if="isClient"
        ref="largeInformDrawerRef" 
        :lagreShipInfo="lagreShipInfo"
        :largeShipTips="largeShipTips"
      />
    </ClientOnly>
  </div>
</template>

<script setup>
import { useMapGetters, useMapState } from 'public/src/pages/checkout/hooks/index.js'
import { computed, ref, defineAsyncComponent, onMounted } from 'vue'
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'

import GoodsInfo from './GoodsInfo'
const LargeInformDrawer = defineAsyncComponent(() => import('./LargeInformDrawer'))

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

const props = defineProps({
  lagreShipInfo: {
    type: Object,
    default: () => {}
  },
})

const isClient = ref(false)

const { 
  fsIconPrefix
} = useMapGetters([
  'fsIconPrefix'
])
const { 
  language,
} = useMapState([
  'language',
])

const largeShipTips = computed(() => {
  // 当前有大件商品且时效文案不为空时展示
  if(!!props.lagreShipInfo.largeShipTimeDesc && !!props.lagreShipInfo.largeShipGoods?.length) {
    const num = props.lagreShipInfo.largeShipGoods.reduce((prev, next) => prev += +next?.quantity, 0)
    return template(num, props.lagreShipInfo.largeShipTitle, language.value.SHEIN_KEY_PWA_32018)
  }

  return ''
})

const largeShipDetail = computed(() => {
  return props.lagreShipInfo.largeShipTimeDesc + largeShipTips.value
})

const largeInformDrawerRef = ref(null)

const handleTipsClick = () => {
  daEventCenter.triggerNotice({
    daId: '1-11-1-253',
  })
  largeInformDrawerRef.value?.openDrawer()
}

onMounted(() => {
  isClient.value = true
})
</script>

<style lang="less" scoped>
.shipping-method-item__large-wrap {
  border-radius: 2px;
  border: 0.5px solid #FFE2D3;
  background: #FFF6F3;

  display: flex;
  padding: 12/75rem 16/75rem;
  align-items: center;

  .shipping-method-item__large-wrap-goods {
    margin-right: 16/75rem;
  }

  .shipping-method-item__large-wrap-tips {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: @sui_color_highlight;
    .font-dpr(24px);

    .detail {
      overflow : hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
