var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isShowShipSwitch || _vm.isShowShipJoin)?_c('div',{staticClass:"ship-prime-wrap"},[(_vm.isShowShipSwitch)?_c('div',{staticClass:"ship-methods-prime"},[_c('span',{staticClass:"prime-des"},[_vm._v("\n      "+_vm._s(_vm.shipSwitchTxt)+"\n    ")]),_vm._v(" "),_c('span',{staticClass:"prime-switch",on:{"click":_vm.goCouponPage}},[_vm._v("\n      "+_vm._s(_vm.language.SHEIN_KEY_PWA_22734)+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.isShowShipJoin)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
      id: '1-11-1-104',
      data: _vm.shipJoinPrimeAnalysis
    }),expression:"{\n      id: '1-11-1-104',\n      data: shipJoinPrimeAnalysis\n    }"}],staticClass:"join-prime"},[_c('span',{staticClass:"prime-free"},[_c('i',{staticClass:"suiiconfont sui_icon_club_logo_15px"}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.joinFreeTxt)}})]),_vm._v(" "),_c('span',{directives:[{name:"tap",rawName:"v-tap",value:({
        id: '1-11-1-105',
        data: _vm.shipJoinPrimeAnalysis
      }),expression:"{\n        id: '1-11-1-105',\n        data: shipJoinPrimeAnalysis\n      }"}],staticClass:"prime-btn",on:{"click":_vm.joinPrime}},[_c('span',[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_23786)+"\n      ")])])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }