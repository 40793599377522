<template>
  <div 
    :id="`shipping-options__container-${mallCode}`" 
    class="shipping-options__container checkout-scroll__shipping"
  >
    <div
      class="shipping-options-title fsp-element"
      fsp-key="checkout_shipping_options_1"
      tabindex="0"
    >
      {{ language.SHEIN_KEY_PWA_15218 }}
    </div>

    <ShippingPrime
      :mallCode="mallCode"
      :shippingMethod="shippingMethod"
      :primeCouponCount="primeCouponCount"
    />

    <quickShipFreeTips />

    <ul class="shipping-options-content">
      <template v-if="shippingMethods && shippingMethods.length">
        <ShippingMethodSwiper 
          v-if="shippingSlideByAbt"
          :shipping-methods="shippingMethods" 
          :mall-code="mallCode"
          :quickShipInfo="quickShipInfo"
          :primeCouponCount="primeCouponCount"
          :defaultShippingItem="defaultShippingItem"
          :addItemContentInfo="addItemContentInfo"
          :isAllQuickShip="isAllQuickShip"
          :isQuickShipTitle="isQuickShipTitle"
          :descQuickShipTime="descQuickShipTime"
          @selected="handleShippingSelect"
          @showDayPercent="showDayPercent"
        />
        <shipping-list 
          v-else
          :shipping-methods="shippingMethods" 
          :mall-code="mallCode"
          :quickShipInfo="quickShipInfo"
          :primeCouponCount="primeCouponCount"
          :defaultShippingItem="defaultShippingItem"
          :addItemContentInfo="addItemContentInfo"
          :isAllQuickShip="isAllQuickShip"
          :isQuickShipTitle="isQuickShipTitle"
          :descQuickShipTime="descQuickShipTime"
          @selected="handleShippingSelect"
          @showDayPercent="showDayPercent"
        />
      </template>

      <ShippingInsurance 
        :mallCode="mallCode"
        @click-insurance-tips="tipsInsurance"
        @toggle-insurance="insuranceCheckOpt"
      />
     
      <li
        v-if="showMallPrice"
        class="mshe-flexbetween total-price-effiency"
      >
        <span class="stand-left">
          {{ language.SHEIN_KEY_PWA_15097 }}
        </span>
        <span>{{ totalPrice.amountWithSymbol }}</span>
      </li>
    </ul>

    <ClientOnly>
      <insurance-dialog
        v-if="showInsuranceTip"
        :visible.sync="showInsuranceTip"
        :language="language"
        :description="insuranceDescript"
      />
    </ClientOnly>
  </div>
</template>

<script>
import ClientOnly from 'vue-client-only'
import Vue from 'vue'
import { template } from '@shein/common-function'
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { isShippingMethodDisabled } from 'public/src/pages/checkout/config/tools.js'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'

import shippingList from './ShippingMethodList.vue'
import ShippingMethodSwiper from './ShippingMethodSwiper'
import ShippingPrime from 'public/src/pages/checkout/page_tpls/shippingPrime.vue'
import ShippingInsurance from './ShippingInsurance'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })
Vue.component('ClientOnly', ClientOnly)

export default {
  name: 'ShippingOptions',
  components: {
    shippingList,
    ShippingMethodSwiper,
    ShippingPrime,
    ShippingInsurance,
    InsuranceDialog: () => import('public/src/pages/components/InsuranceDialog.vue'),
    quickShipFreeTips: () => import('public/src/pages/checkout/page_tpls/components/atom/quickShipFreeTips.vue'),
  },
  props: {
    mallCode: {
      type: String,
      default: ''
    },
    shippingMethods: {
      type: Array,
      default: () => []
    },
    cartsData: {
      type: Array,
      default: () => []
    },
    quickShipInfo: {
      type: Object,
      default: () => {}
    },
  },
  data: function () {
    return {
      showInsuranceTip: false,
      insuranceDescript: '',
    }
  },
  computed: {
    ...mapState('checkout', [
      'language', 
      'checkout', 
      'defaultShipping', 
      'coupon',
      'shippingContentInfo',
      'usableCouponList',
      'disabledCouponList',
      'storeData',
    ]),
    ...mapGetters('checkout', [
      'shoppingBagPosition', 
      'orderInterceptByAbt',
      'isSiteMallList',
      'showQuickShipByAbt',
      'shippingSlideByAbt',
      'isNotHomeAddressScene',
      'isStoreShipAtShowAddressDialog',
      'showAddEntranceByAbt',
      'showNewAddEntranceByAbt',
    ]),
    isAllQuickShip() {
      if (!this.showQuickShipByAbt || !this.cartsData?.length) return false
      const list = this.cartsData?.filter(item => item.real_quick_ship == 1) || []
      return list.length == this.cartsData.length
    },
    isQuickShipTitle() {
      return this.isAllQuickShip && this.shippingMethods.length == 1
    },
    descQuickShipTime() {
      let descQuickShipTime = ''
      if (this.isAllQuickShip) descQuickShipTime = this.quickShipDescribe(this.quickShipInfo)
      return descQuickShipTime
    },
    // 当前mall选中运输方式
    defaultShippingItem() {
      const filterDefauShip = this.defaultShipping?.find(mall => mall.mall_code == this.mallCode) || {}
      return { shipping_method: filterDefauShip.shipping_method, index: filterDefauShip?.index || 0 }
    },
    shippingPriceAll() {
      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      const targetCalcu = mall_list.find(mall => mall.mall_code == this.mallCode)
      const shipping_price_all = targetCalcu?.shipping_price_all || []

      return shipping_price_all
    },
    // 凑单免邮活动
    shippingContentFreeInfo() {
      return {
        detail: this.shippingContentInfo?.[this.mallCode] || {},
        origin: this.shippingContentInfo
      }
    },
    // 凑单券
    shippingCouponFreeInfo() {
      let couponActivity

      const nowTransportType = this.defaultShippingItem?.shipping_method?.transport_type
      let usableOriginCouponList = this.usableCouponList?.filter(_ => _.apply_for == 9 && _.shipping_discount_type == 0) || []
      let disabledOriginCouponList = this.disabledCouponList?.filter(_ => _.apply_for == 9 && _.shipping_discount_type == 0) || []

      const couponAddInfoList = this.handleShippingCouponAdd({ shippingMethods: this.shippingMethods, shippingPriceAll: this.shippingPriceAll, usableCouponList: usableOriginCouponList, disabledCouponList: disabledOriginCouponList })
      if(!!couponAddInfoList?.[0]?.transportType) {
        const nowCouponInfo = couponAddInfoList.find(_ => _.transportType == nowTransportType)
        if(!!nowCouponInfo) { // 优先寻找当前选中运输方式的
          couponActivity = {
            transportType: nowCouponInfo?.transportType,
            info: nowCouponInfo?.info,
            diffPrice: nowCouponInfo?.diffPrice,
            type: 'coupon'
          }
        } else { // 寻找未选中中差额最小的（如果存在多运输方式相同差额的，返回第一个运输方式的）
          const otherCouponInfo = JSON.parse(JSON.stringify(couponAddInfoList)).sort((a, b) => +a.diffPrice?.amount - +b.diffPrice?.amount)?.[0]
          couponActivity = {
            transportType: otherCouponInfo?.transportType,
            info: otherCouponInfo?.info,
            diffPrice: otherCouponInfo?.diffPrice,
            type: 'coupon'
          }
        }
      }

      return couponActivity
    },
    addItemContentInfo() {
      let shippingFreeActivity,
          couponActivity
      const { showShipSupplement, transportType, shippingInfoAvailable } = this.shippingContentFreeInfo?.detail
      const method = this.shippingMethods?.find(_ => _.transport_type == transportType)
      let isDisabled,
          targetPrice
      if(!!method) {
        isDisabled = isShippingMethodDisabled({ item: method, orderInterceptByAbt: this.orderInterceptByAbt })
        targetPrice = this.shippingPriceAll?.find(_ => _.transport_type == transportType)?.price
      }
      if(showShipSupplement && (!!method && !isDisabled && +targetPrice?.amount != 0)) {
        shippingFreeActivity = {
          transportType,
          detail: shippingInfoAvailable,
          info: this.shippingContentFreeInfo?.origin,
          diffPrice: shippingInfoAvailable?.price,
          type: 'activity'
        }
      }
      if(!!this.shippingCouponFreeInfo?.transportType) couponActivity = this.shippingCouponFreeInfo
      
      let contentInfo
      const shippingFreeDiffPriceUsd = shippingFreeActivity?.detail?.diff_price?.usdAmount
      const shippingFreeThresholdPriceUsd = shippingFreeActivity?.detail?.threshold_price?.usdAmount
      let progress = 0 // 凑单进度
      let shippingFreeProgress = 0 // 免邮活动凑单进度
      let couponProgress = 0 // 免邮券凑单进度
      if (shippingFreeDiffPriceUsd && shippingFreeThresholdPriceUsd) {
        shippingFreeProgress = 1 - (Number(shippingFreeDiffPriceUsd) / Number(shippingFreeThresholdPriceUsd))
        shippingFreeProgress = Math.floor(shippingFreeProgress * 100) / 100 // 两位小数，无需四舍五入
      }
      const couponDiffPriceUsd = couponActivity?.info?.needPrice?.usdAmount
      const couponGoodsTotlePriceUsd = couponActivity?.info?.apply_goods?.total_value?.usdAmount
      if (couponDiffPriceUsd && couponGoodsTotlePriceUsd) {
        couponProgress = 1 - (Number(couponDiffPriceUsd) / (Number(couponDiffPriceUsd) +  +  Number(couponGoodsTotlePriceUsd)))
        couponProgress = Math.floor(couponProgress * 100) / 100 // 两位小数，无需四舍五入
      }
      if(!!shippingFreeActivity?.transportType && !!couponActivity?.transportType) {
        contentInfo = +shippingFreeActivity?.diffPrice?.amount <= +couponActivity?.diffPrice?.amount ? shippingFreeActivity : couponActivity
        progress = +shippingFreeActivity?.diffPrice?.amount <= +couponActivity?.diffPrice?.amount ? shippingFreeProgress : couponProgress
      } else {
        contentInfo = !!shippingFreeActivity?.transportType ? shippingFreeActivity : (!!couponActivity?.transportType ? couponActivity : {})
        progress = !!shippingFreeActivity?.transportType ? shippingFreeProgress : (!!couponActivity?.transportType ? couponProgress : 0)
      }
      if (contentInfo.transportType && this.showAddEntranceByAbt && this.showNewAddEntranceByAbt) { // 可凑单免邮，且新展示逻辑开启。pageId=1451690127 on
        const { shipping_method } = this.defaultShippingItem
        const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
        const filterCalcu = mall_list.filter(mall => mall.mall_code == this.mallCode)
        const shipping_price_all = filterCalcu[0]?.shipping_price_all || []
        const shipping_price = shipping_price_all.filter(price => price.transport_type == shipping_method?.transport_type) || []
        const selectShippingPrice = shipping_price[0] || {}// 当前选中运输方式价格信息，包括原价，计算后价格等
        const selectShippingPricesUsd = selectShippingPrice?.price?.usdAmount
        if (shipping_method?.transport_type && +selectShippingPricesUsd === 0) {
          // 已有运输方式被选中，且选中的运费已免邮，不展示入口
          return {}
        }
        // 未免邮，凑单进度小于配置值，不展示入口
        if (progress < this.checkout?.CHECKOUT_SHOW_ADD_ENTRANCE_THRESHOLD_PROGRESS) {
          return {}
        }
      }
      return Object.assign(contentInfo, {
        activityContent: this.shippingContentFreeInfo?.origin,
        couponContent: this.shippingCouponFreeInfo?.info,
        progress
      })
    },
    showMallPrice() {
      const mallLenth = +this.defaultShipping?.length || 0
      return this.shoppingBagPosition && mallLenth > 1
    },
    primeCouponCount() {
      const applyCouponsSuccess = this.coupon?.applyCouponsSuccess || []
      // coupon_dimension 1平台券，2mall券
      const primeCoupon = applyCouponsSuccess.filter(item => item.applyFor == 9 && item.typeId == 53 && (item.coupon_dimension == 1 || item.mallCode == this.mallCode)) || []
      return primeCoupon.length || 0
    },
    // 已选中运输方式
    shippingMethod() {
      return this.defaultShipping?.find(mall => mall.mall_code == this.mallCode)?.shipping_method || {}
    },
    totalPrice() {
      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      const filterCaucl = mall_list.filter(mall => mall.mall_code == this.mallCode)
      return filterCaucl[0]?.total_price || {}
    },
  },
  watch: {
    addItemContentInfo: {
      handler() {
        if(typeof window == 'undefined') return
        this.exposeScene()
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('checkout', ['changeParamsStatus', 'assignState', 'updateState']),
    ...mapActions('checkout', ['handleShipInterceptModal']),
    template,
    showDayPercent(type) {
      const shipPercentMethods = this.shippingMethods.filter(item => !!item?.day_percents?.length) || []
      const dayPercentInx = shipPercentMethods?.findIndex(item => item.transport_type == type) || 0
      this.assignState({ 
        shipPercentMethods: shipPercentMethods,
        dayPercentInx: dayPercentInx
      })
      this.updateState({
        key: 'showShippingDayPercent', value: true
      })
    },
    // quickship文案描述
    quickShipDescribe(item) {
      if(!item.desc_quick_ship_time) return ''
      let _html = item.desc_quick_ship_time || ''

      if( _html ){
        _html = _html.replace(/script/g, '').replace(/\s/g, ' ').replace(/&nbsp;/g, ' ')

        const quickShipWord = +(this.checkout?.abtInfo?.TradeQuickshipWord?.param?.TradeQuickshipWordStatus || 0)
        const quickShipLabelText = quickShipWord ? this.template(quickShipWord, this.language.SHEIN_KEY_PWA_30733) : this.language.SHEIN_KEY_PWA_22276
        quickShipLabelText && (_html = _html.replace(/{QSname}/g, `<span style="color:#198055;font-weight: 400;">${quickShipLabelText}</span>`)
          .replace(/\[time\]/g, `<span style="color:#198055">${item.quick_ship_time}</span>`))
      }
      console.log('_html', _html)
      return _html
    },
    exposeScene() {
      // 多mall的时候不上报
      if(this.isSiteMallList) return

      daEventCenter.triggerNotice({
        daId: '1-11-1-172',
        extraData: {
          scenes: 'checkout_shipping_add',
          type: !!this.addItemContentInfo?.transportType ? 1 : 0,
          progress: !!this.addItemContentInfo?.transportType ? this.addItemContentInfo?.progress : '-'// 当type=1时，上报当前邮费券或免邮活动的凑单进度百分比；当type=0时，上报"-"
        }
      })
    },
    // 获取各运输方式支持凑单的券信息
    handleShippingCouponAdd({ shippingMethods, shippingPriceAll, usableCouponList, disabledCouponList }) {
      // 运输方式满足凑单条件的券信息
      let couponAddInfoList = []
      if(!shippingMethods.length) return couponAddInfoList

      shippingMethods.forEach(method => {
        let couponInfo = {}
        const isDisabled = isShippingMethodDisabled({ item: method, orderInterceptByAbt: this.orderInterceptByAbt })
        const shipping_price = shippingPriceAll.find(price => price.transport_type == method.transport_type)
        
        // 去除 不可用、已免邮运输方式
        if(!isDisabled && +shipping_price?.price?.amount != 0) {
          const nowTransportType = method?.transport_type
          const usableTargetCouponList = usableCouponList.filter(_ => (!_.shipping_list || _.shipping_list?.map(i => i.shipping_method_code)?.includes(nowTransportType))) || []
          const disabledTargetCouponList = disabledCouponList.filter(_ => (!_.shipping_list || _.shipping_list?.map(i => i.shipping_method_code)?.includes(nowTransportType) && [2, 4, 9].includes(parseInt(_.reason, 10)))) || []
          // 可用券列表中不包含指定券且不可用券列表中包含指定券
          const couponShow = !usableTargetCouponList.length && !!disabledTargetCouponList.length

          if(couponShow) {
            // 选取距离门槛差额最小的指定券
            const targetCoupon = JSON.parse(JSON.stringify(disabledTargetCouponList)).sort((a, b) => +a.needPrice?.amount - +b.needPrice?.amount)?.[0]

            couponInfo = Object.assign(couponInfo, {
              transportType: nowTransportType,
              info: targetCoupon,
              diffPrice: targetCoupon.needPrice
            })
          }
        }

        if(!!couponInfo?.transportType) couponAddInfoList.push(couponInfo)
      })

      return couponAddInfoList
    },
    insuranceCheckOpt: function (item) {
      if (item.isForceCheck) {
        this.showInsuranceTip = true
        daEventCenter.triggerNotice({
          daId: '1-11-1-110',
          extraData: {
            mall_code: this.mallCode
          }
        })
        return
      }
      this.$emit('insurance-check-opt', this.mallCode)
    },
    isOrderIntercept(item) {
      const interceptList = [1, 2, 3, 7]
      return this.orderInterceptByAbt && item.is_available == 0 && item.is_available_msg == 9 && interceptList.includes(+item.rule_info?.hint_type)
    },
    handleShippingSelect({ item, addItemUpdate, isInterceptBack, skipShipIntercept }) {
      // 运输方式是否为店配
      const isStoreAddressShipping = TRANSPORT_TYPE_SHOP.includes(item.transport_type)
      
      // 当在无宅配地址，或者当前店配地址不能用的时候，命中了运输方式拦截规则
      if (item.is_available == 0 && item.is_available_msg == 9 && ((!isStoreAddressShipping && this.isNotHomeAddressScene) || (isStoreAddressShipping && Object.keys(this.storeData || {}).length > 0 && this.storeData?.status != 1))) {
        this.assignState({ isShowNoAddressTipsDialog: true, isShowSelectShippingAddressTips: true, isStoreShipAtShowAddressDialog: isStoreAddressShipping })
        return
      }
      
      // 切换到店配时，先判断是否有店配地址，没有则先弹出添加地址弹窗
      if (isStoreAddressShipping && (!Object.keys(this.storeData || {}).length || this.storeData?.status != 1)) {
        appEventCenter.$emit('add-store-address', { mall_code: this.mallCode, transport_type: item.transport_type })
        this.assignState({
          selectStoreAddressShippingInfo: {
            shippingMethod: item,
            mallCode: this.mallCode,
            isAdd: true
          }
        })
        return
      }

      // 点击切换前选中的是否为店配
      const isPrevStoreAddress = TRANSPORT_TYPE_SHOP.includes(this.defaultShippingItem.shipping_method.transport_type)
      const isChangeType = isPrevStoreAddress != isStoreAddressShipping
      if(isChangeType || addItemUpdate) {
        if(addItemUpdate) {
          this.assignState({
            forceUpdateShippingMethod: {
              isUpdate: true
            }
          })
        }

        appEventCenter.$emit('update-shipping-method', { 
          address: this.checkout.default_address,
          isLimit: 1,
          shippingMethod: item,
          mallCode: this.mallCode,
          isInterceptBack,
          skipShipIntercept,
          modifyCart: !!addItemUpdate,
        })

        return
      }

      const isOrderIntercept = this.isOrderIntercept(item)
      if(isOrderIntercept) {
        // 不涉及商品-0,删除全部商品-1,删除部分商品-2,需修改地址-3
        const hintType = item.rule_info?.hint_type
        this.handleShipInterceptModal({ 
          hintType, 
          shippingMethod: item, 
          mallCode: this.mallCode
        })

        daEventCenter.triggerNotice({
          daId: '1-11-1-175',
          extraData: {
            rules_type: hintType,
            shipping_method_id: item.transport_type,
            mall_code: this.mallCode
          }
        })
        return
      }

      const index = this.shippingMethods.findIndex(_ => _.transport_type == item.transport_type)
      this.$emit('handle-shipping-select', { 
        item, 
        index, 
        mallCode: this.mallCode, 
        transport_type: item.transport_type
      })
    },
    tipsInsurance(tips) {
      this.showInsuranceTip = true
      this.insuranceDescript = tips
      daEventCenter.triggerNotice({
        daId: '1-11-1-110',
        extraData: {
          mall_code: this.mallCode
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.icon-tips {
  color: @sui_color_gray_light1;
}

li {
  .stand-left {
    width: 100%;
    .shipping-title{
      .fl();
    }
  }
}

.insurance-effiency {
  border-top: 1px solid #e5e5e5;
  padding: 20/75rem 24/75rem;
  .padding-l(0);

  .icon-tips {
    .margin-l(8/75rem);
  }
}

.total-price-effiency {
  padding: 20/75rem 24/75rem;
  .padding-l(0);
}

.shipping-options-title {
  padding-top: 20/75rem;
  .padding-r(24/75rem);

  text-transform: capitalize;
	color: #222;
  font-weight: 700;
  & when (@IS_RW) {
    font-weight: 400;
    font-family: Adieu-Regular, Adieu;
  }
}

.label__success_green{
  color:#198055
}
.shipping-limit-tip {
	color: @color_red_dark;
	padding: .26rem .4rem .53rem 0;
	line-height: 14px;
}

.shipping-options__container {
  background: #fff;
  .padding-l(.32rem);
  margin-top: 16/75rem;
}
</style>
