<template>
  <div
    class="apple-pay-btn"
    :style="{
      'justify-content': propsStyle.justifyContent,
      'display': propsStyle.display,
      'width': propsStyle.width
    }"
  >
    <ClientOnly>
      <apple-pay-button
        :style="{
          '--apple-pay-button-width': propsStyle.width,
          '--apple-pay-button-height': propsStyle.height
        }"
        buttonstyle="black"
        type="pay"
        :locale="applePayBtnLocale"
        @click="handleBtnClick"
      />
    </ClientOnly>
  </div>
</template>
<script>
import { asyncLoadFile } from '@shein/common-function'
// const IS_SSR = typeof window == 'undefined'
import ClientOnly from 'vue-client-only'

// 因apple按钮语种与shein侧的语种规则不一致，为了获取当前站点国家对应的语种，查询当前用户所在国家
// https://developer.apple.com/documentation/apple_pay_on_the_web/applepaybuttonlocale
const applePayLocal = {
  arg: 'es-AR', // 阿根廷西班牙语
  ar: 'ar-AB', // 阿拉伯语
  asia: 'zh-CN', // 亚洲，没有明确匹配，暂设为简体中文
  asiaja: 'ja-JP', // 亚洲日语
  asiako: 'ko-KR', // 亚洲韩语
  au: 'en-AU', // 澳大利亚英语
  br: 'pt-BR', // 巴西葡萄牙语
  ca: 'en-CA', // 加拿大英语
  cafr: 'fr-CA', // 加拿大法语
  ch: 'de-CH', // 瑞士德语
  chfr: 'fr-CH', // 瑞士法语
  cl: 'es-CL', // 智利西班牙语
  co: 'es-CO', // 哥伦比亚西班牙语
  de: 'de-DE', // 德语
  en: 'en-US', // 默认为美国英语
  enes: 'es-ES', // 英语->西班牙语，暂选西班牙语
  enpt: 'pt-PT', // 英语->葡萄牙语，暂选葡萄牙语
  es: 'es-ES', // 西班牙语
  euqs: 'en-US', // 欧洲查询，没有明确匹配，暂空
  eur: 'en-US', // 欧洲，没有明确的匹配，暂空
  eurbg: 'bg-BG', // 欧洲保加利亚语
  eurnl: 'nl-NL', // 欧洲荷兰语
  eurfr: 'fr-FR', // 欧洲法语，设为法国法语
  fr: 'fr-FR', // 法兰西岛法语
  hk: 'zh-HK', // 香港繁体中文
  id: 'id-ID', // 印度尼西亚
  iden: 'en-US', // 印尼英语，暂选美国英语
  il: 'he-IL', // 以色列
  ilen: 'en-US', // 以色列英语，暂无明确匹配，设为美国英语
  in: 'hi-IN', // 印度
  it: 'it-IT', // 意大利语
  jp: 'ja-JP', // 日语
  jpen: 'en-US', // 日语->英语，暂选英语
  kr: 'ko-KR', // 韩语
  kren: 'en-US', // 韩语->英语，暂选英语
  ma: 'ar-MA', // 摩洛哥阿拉伯语
  maar: 'ar-MA', // 摩洛哥阿拉伯语重复
  mx: 'es-MX', // 墨西哥西班牙语
  my: 'ms-MY', // 马来西亚
  nl: 'nl-NL', // 荷兰语
  nz: 'en-NZ', // 新西兰英语
  ph: 'en-PH', // 菲律宾英语
  pl: 'pl-PL', // 波兰语
  pt: 'pt-PT', // 葡萄牙语
  ptpt: 'pt-PT', // 葡萄牙葡萄牙语
  roe: 'nb-NO', // 挪威
  ru: 'ru-RU', // 俄语
  se: 'sv-SE', // 瑞典语
  sesv: 'sv-SE', // 瑞典语缩写，必须匹配瑞典语
  sg: 'en-SG', // 新加坡英语
  th: 'th-TH', // 泰语
  then: 'en-US', // 泰语->英语，暂选美国英语
  tr: 'tr-TR', // 土耳其语
  tw: 'zh-TW', // 台湾繁体中文
  uk: 'uk-UA', // 乌克兰语
  us: 'en-US', // 美国英语
  uses: 'es-US', // 美国西班牙语
  vn: 'vi-VN', // 越南语
  za: 'en-ZA' // 南非英语
}

export default {
  name: 'ApplePayBtn',
  components: {
    ClientOnly
  },
  props: {
    propsStyle: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loadingStatus: false,
      applePayBtnLocale: 'en-US'
    }
  },
  mounted() {
    // 使用 Vue.nextTick 确保 DOM 更新完成
    this.$nextTick(() => {
      asyncLoadFile({
        label: 'script',
        attrs: {
          src: 'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js'
        },
        inBody: false
      })
        .then(() => {
          this.loadingStatus = false
          // 找到所有标签名为 apple-pay-button 的元素
          let applePayButtons = document.getElementsByTagName('apple-pay-button')
          // 遍历所有找到的元素
          for (let i = 0; i < applePayButtons.length; i++) {
            let button = applePayButtons[i]
            button.removeAttribute('hidden')
            button.removeAttribute('aria-hidden')
            button.style.display = 'block'
            if (this.propsStyle.direction) {
              button.style.direction = this.propsStyle.direction
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.loadingStatus = false
        })
    })

    const { lang } = window.gbCommonInfo || {}
    this.applePayBtnLocale = applePayLocal[lang] || 'en-US'
  },

  methods: {
    handleBtnClick() {
      this.$emit('handleBtnClick', true)
    },
  }
}
</script>
<style scoped>
.apple-pay-btn {
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

apple-pay-button {
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 100%;
  --apple-pay-button-border-radius: 0px;
  --apple-pay-button-padding: 5px 0px;
  --apple-pay-button-box-sizing: border-box;
}
</style>
