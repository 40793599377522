
<template>
  <div>
    <GoodsList 
      v-if="primeClubPromotion.length"
      class="j-activity-left"
      :language="language"
      :goodsList="primeClubPromotion"
      :isShowGoodsDicount="isShowGoodsDicount"
    />
    <Tips 
      v-if="tipsDescription && tipsDescription.length"
      :language="language"
      :icon="tipsIcon"
      :description="tipsDescription"
      :bubbleCopyWritingByAbt="bubbleCopyWritingByAbt"
      :isShowNewActivityPopverLayoutByAbt="isShowNewActivityPopverLayoutByAbt"
    />
  </div>
</template>

<script>
import { template } from '@shein/common-function'
import GoodsList from './goods_info/GoodsList.vue'
import Tips from './Tips.vue'
import { iconConfig } from './config'

export default {
  name: 'PrimeClubPopover',
  components: {
    GoodsList,
    Tips,
  },
  props: {
    checkout: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    primeClubPromotion: {
      type: Array,
      default: () => []
    },
    bubbleCopyWritingByAbt: {
      type: Boolean,
      default: false
    },
    isShowNewActivityPopverLayoutByAbt: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isShowGoodsDicount() {
      return !this.bubbleCopyWritingByAbt
    },
    tipsDescription() {
      const savePrice = this.checkout?.mall_caculate_info?.prime_deduce_price?.amountWithSymbol
      return this.template(`<span class="prime-club">${this.template(savePrice, this.language.SHEIN_KEY_PWA_31820)}</span>`, this.language.SHEIN_KEY_PWA_31811)
    },
    tipsIcon() {
      return iconConfig?.primeClub || {}
    },
  },
  methods: {
    template,
  }
}
</script>
