<template>
  <li
    v-if="checkout.mall_caculate_info"
    v-enterkey
    v-expose="{
      id: '1-11-1-196',
      data: {
        is_choosed: isShowPrice ? 1 : 0,
        is_folded: isFolded,
      }
    }"
    class="use-coupon checkout-points__entry"
    tabindex="0"
    @click="goPointPage"
  >
    <div class="stand-left">
      <div class="c-form-group">
        <div class="input-icon">
          <span
            style="white-space: nowrap; vertical-align: middle;"
            :class="{'font': IS_RW}"
            class="input-icon-cell"
          >
            {{ language.SHEIN_KEY_PWA_16455 }}:
          </span>
          <div class="use-coupon-right input-icon-cell">
            <code>
              <span v-if="isShowPrice">
                <span class="use-red">
                  -{{ price.pointPrice.amountWithSymbol }}
                </span>
                <span class="use-points">
                  -{{ language.SHEIN_KEY_PWA_22192 && language.SHEIN_KEY_PWA_22192.replace('{0}', price.points) }}
                </span>
              </span>
              <span
                v-else
                class="use-total"
              >
                {{ language.SHEIN_KEY_PWA_14955 + ":" + checkout.results.supplement.availablePoints }}
              </span>
            </code>
            <i
              class="sui_icon_more_right_16px mcheo-icon-grep"
              :class="[fsIconPrefix]"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="pointShipPriceDiff.length"
      class="price-coupon-diff"
    >
      <div 
        v-for="item in pointShipPriceDiff" 
        :key="item.mall_code"
        class="price-diff-tip"
      >
        <strong v-if="isSiteMallList">{{ item.mall_name }}: </strong>
        <span v-html="freeShipDiffTips(item)"></span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'

import analysisCoupon from 'public/src/pages/checkout/components/analysis_coupon.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'


export default {
  name: 'AssetsPoints',
  props: {
    isSiteMallList: {
      type: Boolean,
      default: false
    },
    isFolded: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapState('checkout', [
      'checkout',
      'language',
      'price',
      'locals',
      'freeShipTip',
    ]),
    ...mapGetters('checkout', [
      'fsIconPrefix'
    ]),
    IS_RW(){
      return this.locals?.IS_RW ?? gbCommonInfo?.IS_RW
    },
    isShowPrice() {
      return +this.price?.pointPrice?.amount > 0
    },
    pointShipPriceDiff () {
      const { point, other, otherType } = this.freeShipTip
      let newShipList = []
      if (point.length) newShipList = point
      if (otherType == 'points' && other.length) newShipList = newShipList.concat(other)
      return newShipList
    },
  },
  methods: {
    ...mapMutations('checkout', ['changeParamsStatus']),
    freeShipDiffTips (item) {
      const { not_free_shipping_tips, not_free_shipping_diff } = item
      let tips = ''
      switch (not_free_shipping_tips){
        case 1:
          tips = this.language.SHEIN_KEY_PWA_16086   // coupon
          break
        case 2:
          tips = this.language.SHEIN_KEY_PWA_16087  // point
          break
        case 3:
          tips = this.language.SHEIN_KEY_PWA_16106 // both
          break
        case 4:
          tips = this.language.SHEIN_KEY_PWA_16105  // or
          break
      }

      typeof window != 'undefined' && analysisCoupon.ApplyCouponNotFreeShipping()

      return tips.replace('{money}', '<em class="tip-red">' + not_free_shipping_diff.amountWithSymbol + '</em>')
    },
    goPointPage() {
      this.changeParamsStatus({ type: 'pageStatus', params: { showPointInputPanel: true } })

      daEventCenter.triggerNotice({
        daId: '1-11-1-136',
        extraData: {
          is_choosed: this.isShowPrice ? 1 : 0,
          is_folded: this.isFolded,
        },
      })
      daEventCenter.triggerNotice({
        daId: '1-11-1-137',
        extraData: {
          button_included: +this.price?.pointPrice?.amount > 0 && this.price?.pointPrice?.amountWithSymbol ? 'remove' : 'apply'
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.checkout-points__entry {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.use-points {
  color: @sui_color_gray_light1;
  .font-dpr(24px);
  font-weight: 400;
  display: block;
}
</style>
