var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.isShow)?_c('s-label',{staticClass:"payment-discount-zero",attrs:{"type":"promo"}},[(_setup.onlinePayDiscountInfoValue.discountType == 0)?[(_setup.GB_cssRight)?[_vm._v("\n      "+_vm._s(_vm.language.SHEIN_KEY_PWA_14988 +
        ' ' +
        _setup.onlinePayDiscountInfoValue.amount.amountWithSymbol)+"\n    ")]:[_vm._v("\n      "+_vm._s(_setup.onlinePayDiscountInfoValue.amount.amountWithSymbol +
        ' ' +
        _vm.language.SHEIN_KEY_PWA_14988)+"\n    ")]]:(_setup.onlinePayDiscountInfoValue.discountType == 1)?[(_setup.GB_cssRight)?[_vm._v("\n      "+_vm._s(_vm.language.SHEIN_KEY_PWA_14988 +
        ' ' +
        _setup.onlinePayDiscountInfoValue.percent +
        '%')+"\n    ")]:[_vm._v("\n      "+_vm._s(_setup.onlinePayDiscountInfoValue.percent +
        '% ' +
        _vm.language.SHEIN_KEY_PWA_14988)+"\n    ")]]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }