<template>
  <div class="pay-price">
    <div class="title">
      {{ language.SHEIN_KEY_PWA_25686 }}
    </div>
    <div class="price-detail">
      <span
        ref="totalPriceRef"
        class="total-price"
      >
        {{ totalPrice }}
      </span>
      <span
        v-if="checkShowTotalFee"
        ref="totalTaxRef"
        class="total-tax"
      >
        + {{ govTaxPrice }}
      </span>
    </div>
    <!-- totalFee相关部分 -->
    <div
      v-if="checkShowTotalFee"
      class="total-fee"
    >
      ( <span v-html="totalFeeText"></span> )
      <Icon
        v-enterkey
        name="sui_icon_doubt_16px_2"
        size="14px"
        class="mask-tip-icon"
        @click.stop="totalFeeMaskTips"
      />
    </div>

    <!-- COD COD fee -->
    <s-popover
      :value="isShowPopover"
      :content="codCurrencyTip"
      trigger-type="click"
      theme="light"
      :show-close-icon="true"
      :append-to-body="true"
      ref="popoverRef"
      :prop-style="{ width: '5.97rem', zIndex: zIndex }"
      :fix-disappear-position="false"
    >
      <span class="pay-cod-tip">{{ codCurrencyTip }}</span>
      <div
        class="cod-price-detail"
        slot="reference"
        v-show="actualCodePrice"
      >
        {{ actualCodePriceText }}
      </div>
    </s-popover>

    <div
      class="bottom-tip"
      v-if="isShowBottomTip"
    >
      {{ bottomTip }}
    </div>
  </div>
</template>

<script setup>
/* globals shem_caculate_data */
import { Icon } from '@shein-aidc/icon-vue2'
import {
  useStore,
  useMapMutations
} from 'public/src/pages/checkout/hooks/index.js'
import { computed, ref, onMounted, watch } from 'vue'
import { template } from '@shein/common-function'
import { Popover } from '@shein/sui-mobile'
import { useDiscount } from './useDiscount'

Vue.use(Popover)
const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  },
  isSelect: {
    type: Boolean,
    default: false
  },
  show: {
    type: Boolean,
    default: false
  },
  allPayHadDiscount: {
    type: Boolean,
    default: false
  },
  zIndex: {
    type: Number,
    default: 0
  },
  discountMap: {
    type: Map,
    default: () => {
      return new Map()
    }
  }
})

const actualCodePrice = ref('')
const isShowCodPrice = ref(false)
const popoverRef = ref(null)
const popoverTop = ref(0)
const tempPopoverEl = ref(null)

const store = useStore()
const { showMaskTips } = useMapMutations(['showMaskTips'])

const bottomTip = computed(() => {
  return !props.isSelect
    ? props.language.SHEIN_KEY_PWA_25690
    : props.language.SHEIN_KEY_PWA_25691
})

const isShowBottomTip = computed(() => {
  return !props.isSelect ? props.allPayHadDiscount : props.discountMap?.has(cardPay.value)
})

const totalPrice = computed(() => {
  return store?.state?.checkout?.price?.grandTotalPrice?.amountWithSymbol
})

const isShowTotalFee = computed(() => {
  return store?.getters?.['checkout/isShowTotalFee']
})
const price = computed(() => {
  return store?.state?.checkout?.price
})
const codCurrencyTip = computed(() => {
  return template(
    store?.state?.checkout?.now_currency,
    props.language.SHEIN_KEY_PWA_17358
  )
})

const cardPay = computed(() => {
  return store?.state?.checkout?.status?.cardPay
})

const checkShowTotalFee = computed(() => {
  return (
    isShowTotalFee.value &&
    store?.state?.checkout?.price?.pay_method_gov_tax_info
      ?.pay_method_gov_tax_rate > 0 &&
    Number(
      store?.state?.checkout?.price?.pay_method_gov_tax_info
        ?.pay_method_gov_tax_amount?.amount || '0'
    ) > 0
  )
})

const govTaxPrice = computed(() => {
  return (
    store?.state?.checkout?.price?.pay_method_gov_tax_info
      ?.pay_method_gov_tax_amount?.amountWithSymbol || 0
  )
})

const totalFeeText = computed(() => {
  return template(
    `<em style='color: #000;'>${govTaxPrice.value}</em>`,
    props.language.SHEIN_KEY_PWA_18953
  )
})

const actualCodePriceText = computed(() => {
  return template(actualCodePrice.value, props.language.SHEIN_KEY_PWA_30637)
})

const isShowPopover = computed(() => {
  return props.show && isShowCodPrice.value
})

watch(
  () => price.value,
  () => {
    if (typeof window === 'undefined') return
    codPriceChange()
  },
  {
    deep: true,
    immediate: true
  }
)

// Popover 组件 append-to-body 开启后，top 计算有问题
watch(isShowPopover, () => {
  if (typeof window === 'undefined') return
  if (isShowPopover.value) {
    const { top = 0 } = document.body.getBoundingClientRect()

    setTimeout(() => {
      tempPopoverEl.value =
        popoverRef.value?.popover?.$children?.[0]?.$children?.[0]?.$el?.firstChild
      const tempElTop = tempPopoverEl.value?.offsetTop

      if (popoverTop.value !== 0) {
        tempPopoverEl.value.style.top =
          parseInt(popoverTop.value) + Math.abs(top) + 'px'
        return
      }

      tempPopoverEl.value.style.top =
        parseInt(tempPopoverEl.value.style.top) + Math.abs(top) + 'px'
      popoverTop.value = tempElTop
    }, 0)
  }
})


const totalFeeMaskTips = () => {
  showMaskTips(props.language.SHEIN_KEY_PWA_18954)
}

function codPriceChange() {
  if (shem_caculate_data?.payment_code_unique !== 'cod') {
    actualCodePrice.value = ''
    isShowCodPrice.value = false
    return
  }
  const {
    show_type = '',
    codPrice: {
      amountWithSymbol: currentPrice,
      amount: currentPriceAmount
    } = {}
  } = price.value

  const actualNum = Number(currentPriceAmount) > 0 ? currentPrice : 0
  actualCodePrice.value = actualNum
  isShowCodPrice.value = true

  if ([1, 2].includes(show_type)) return

  actualCodePrice.value = ''
  isShowCodPrice.value = false
}

onMounted(() => {
  codPriceChange()
})
</script>

<style lang="less" scoped>
.pay-price {
  padding: 60/75rem 40/75rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: black;
    font-size: 17px;
    font-family: SF UI Display;
    font-weight: 400;
    word-wrap: break-word;
  }

  .price-detail {
    color: #222222;
    font-size: 16px;
    font-family: SF UI Text;
    font-weight: 700;
    word-wrap: break-word;
    .total-price {
      color: #fa6338;
      font-size: 32px;
      font-family: SF UI Text;
      font-weight: 700;
      word-wrap: break-word;
    }
  }

  .total-fee {
    color: #767676;
    font-size: 10px;
    font-family: SF UI Display;
    font-weight: 400;
    word-wrap: break-word;
    display: flex;
    align-items: center;

    .mask-tip-icon {
      margin-left: 8/75rem;
    }
  }

  .bottom-tip {
    color: #fa6338;
    font-size: 10px;
    font-family: SF UI Text;
    font-weight: 400;
    word-wrap: break-word;
  }

  .cod-price-detail {
    color: black;
    font-size: 10px;
    font-family: SF UI Display;
    font-weight: 400;
    word-wrap: break-word;
  }
}
</style>
