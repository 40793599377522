<template>
  <swiper-container
    ref="shippingMethodSwiper"
    class="shipping-method-swiper"
    :class="{'shipping-method-swiper__only': shippingMethods.length == 1}"
    init="false"
    :dir="GBCssRight ? 'rtl' : 'ltr'"
  >
    <swiper-slide
      v-for="(item, index) in shippingMethods"
      :key="`${item.transport_type}-${mallCode}`"
      class="shipping-method-swiper__item"
    >
      <ShippingListItem 
        :item="item"
        :index="index"
        :isClickMore="true"
        :checked="checked"
        :mallCode="mallCode"
        :descQuickShipTime="descQuickShipTime"
        :isAllQuickShip="isAllQuickShip"
        :isQuickShipTitle="isQuickShipTitle"
        :primeCouponCount="primeCouponCount"
        :quickShipInfo="quickShipInfo"
        :addItemContentInfo="addItemContentInfo"
        :default-shipping-item="defaultShippingItem"
        :defaultSelectedIndex="defaultSelectedIndex"
        :class="[{'mshe-ship-disabled': !item.is_available}, {'last-shipping-item': (index + 1) == shippingMethods.length}]"
        @handleSelect="handleSelect"
        @showDayPercentList="showDayPercentList"
      />
    </swiper-slide>
  </swiper-container>
</template>

<script>
import ShippingListItem from '@/public/src/pages/checkout/vue_tpls/shipping_method/ShippingMethodItem.vue'

import { mapState, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { register } from 'swiper/element'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })
typeof window !== 'undefined' && register()


export default {
  name: 'ShippingMethodSwiper',
  components: {
    ShippingListItem
  },
  props: {
    mallCode: {
      type: String,
      default: ''
    },
    shippingMethods: {
      type: Array,
      default: () => []
    },
    quickShipInfo: {
      type: Object,
      default: () => {}
    },
    primeCouponCount: {
      type: Number,
      default: 0
    },
    defaultShippingItem: {
      type: Object,
      default: () => {}
    },
    addItemContentInfo: {
      type: Object,
      default: () => {}
    },
    isAllQuickShip: {
      type: Boolean,
      default: false
    },
    isQuickShipTitle: {
      type: Boolean,
      default: false
    },
    descQuickShipTime: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      defaultSelectedIndex: -1,
      shippingSwiper: null,
    }
  },
  computed: {
    ...mapState('checkout', [
      'language', 
      'checkout', 
      'defaultShipping', 
    ]),
    ...mapGetters('checkout', [
      'iconPrefix', 
      'orderInterceptByAbt',
      'GBCssRight',
    ]),
    checked() {
      return this.defaultShippingItem?.index || 0
    },
  },
  watch: {
    shippingMethods: {
      deep: true,
      handler() {
        this.initSwiper()
      }
    }
  },
  mounted() {
    this.initSwiper()
    const filterDefauShip = this.defaultShipping?.find(mall => mall.mall_code == this.mallCode)
    this.defaultSelectedIndex = filterDefauShip?.index || 0
  },
  methods: {
    initSwiper() {
      this.shippingSwiper = null

      // swiper的参数属性
      const swiperParams = {
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        slidesOffsetAfter: 12,
        normalizeSlideIndex: false,
        injectStyles: [
          `.swiper {
            overflow: visible;
          }
          `,
        ],
      }

      this.$nextTick(() => {
        this.shippingSwiper = this.$refs.shippingMethodSwiper

        if(this.shippingSwiper) {
          Object.assign(this.shippingSwiper, swiperParams)
          this.shippingSwiper.initialize()

          // msg类型为9时，默认选中，不展示勾选，不滚动
          const shippingMethod = this.defaultShippingItem.shipping_method || {}
          const cannotChecked = shippingMethod.is_available != 1 && this.orderInterceptByAbt
          if (!cannotChecked) {
            this.shippingSwiper.swiper?.slideTo(this.defaultShippingItem?.index)
          }
        }
      })
    },
    showDayPercentList(type) {
      this.$emit('showDayPercent', type)
    },
    handleSelect({ item, addItemUpdate, isInterceptBack, skipShipIntercept }) {
      const index = this.shippingMethods.findIndex(_ => _.transport_type == item.transport_type)
      if (this.shippingSwiper) {
        this.shippingSwiper.swiper?.slideTo(index)
      }

      this.$emit('selected', { item, addItemUpdate, isInterceptBack, skipShipIntercept })
    },
  },
}
</script>

<style lang="less" scoped>
.shipping-method-swiper {
  display: flex;
  flex-wrap: nowrap;
  padding: 12/75rem 0;
  z-index: 0;
  .shipping-method-swiper__item {
    flex-shrink: 0;
    width: 85%;
    height: auto;

    border-radius: 2px;
    background: @sui_color_gray_weak2;
    padding: 20/75rem 16/75rem;
    .margin-r(16/75rem);

    .shipping-list-item {
      padding-top: 0;
    }

    &:last-child {
      .margin-r(0);
    }
  }
  .swiper-slide-active {
    width: 75%;
  }
}
.shipping-method-swiper__only {
  .margin-r(16/75rem);
  .shipping-method-swiper__item {
    width: 100%;
  }
  .swiper-slide-active {
    width: 100%;
  }
}
</style>
