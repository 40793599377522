<template>
  <!-- 违禁品购买提示，比如：烟草 -->
  <div class="contraband-tips-wrapper">
    <p class="contraband-tips-wrapper__tips-text">
      {{ language.SHEIN_KEY_PWA_30445 }}
    </p>
  </div>
</template>

<script setup>
import { useMapState } from 'public/src/pages/checkout/hooks/index.js'

const { language } = useMapState(['language'])
</script>
<style lang="less" scoped>
.contraband-tips-wrapper {
  position: absolute;
  top: -2/75rem;
  transform: translateY(-100%);
  width: 100%;
  padding: 16/75rem 24/75rem;
  background: var(---sui_color_prompt_common, #EBF4FF);
  z-index: -1;
  &__tips-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(---sui_color_brand, #000);
    font-size: 12px;
    line-height: 1.25;
  }
}
</style>
