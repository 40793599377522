
// ----该文件只放/footer文件下的一些方法----

/**
 * @description: 处理利诱点类型的映射，主要用在埋点
 * @param {String} activityPopoverType 当前的利诱点类型
 * @return {String} 利益点类型
 */
export const handleBenefitsReflectionType = (activityPopoverType) => {
  const benefitMapType = {
    'promotion': 'huodong',
    'inventory': 'kucun',
    'coupon': 'quan',
    'taskpoints': 'TaskPoints',
    'lowestprice': 'LowestPrice',
    'saverpromotion': 'SaverPromotion',
    'sheinclubpromotion': 'SHEINClubPromotion',
  }

  return benefitMapType[activityPopoverType] || ''
}

/**
 * @description: 当前利诱点类型是促销活动的时候，做活动映射，主要用在埋点
 * @param {String} activityPopoverType 当前的利诱点类型
 * @param {Object} integrityPromotionInfo 活动的完整数据 具体看public/src/pages/common/promotion/logic/transformPromotionTipNew.js
 * @param {Array} promotionList 活动的数据，一般是单个活动，具体看src/pages/checkout/hooks/promotionInfo.js
 * @return {String} 映射的埋点促销活动类型
 */
export const handlePromotionBenefitsType = ({ activityPopoverType, integrityPromotionInfo, promotionList }) => {
  let promotiontype = ''

  if (activityPopoverType == 'promotion') {
    if (promotionList?.length == 1) {
      const { typeId = -1, id } = promotionList[0]
      const { rules = [], flash_type, goods = {} } = integrityPromotionInfo?.[id] || {}

      switch(+typeId) {
        case 1: promotiontype = 'promotion_1';break // 买折
        case 9: {
          const type = rules[0]?.type || -1

          if (type == 1) promotiontype = 'promotion_2' // 买N折N-按照原价折扣
          else if (type == 3) promotiontype = 'promotion_3' // 买N折N-按照特价折扣
          break
        }
        case 14: {
          const type = rules[0]?.type || -1
          const isAllDecrease = goods.type == 3 // goods?.type != 3 非全场 goods?.type == 3 全场
          const discountType = rules[0]?.discount?.type

          if (isAllDecrease && discountType == 6 && [3, 4].includes(+type)) promotiontype = 'promotion_4' // 全场满减-满元
          else if (isAllDecrease && discountType == 6 && [1, 2].includes(+type)) promotiontype = 'promotion_5' // 全场满减-满件
          else if (!isAllDecrease && discountType == 6 && [3, 4].includes(+type)) promotiontype = 'promotion_6' // 满减-满元
          else if (!isAllDecrease && discountType == 6 && [1, 2].includes(+type)) promotiontype = 'promotion_7' // 满减-满件
          break
        }
        case 15: promotiontype = 'promotion_8';break // X元N件-满N元
        case 16: promotiontype = 'promotion_9';break // X元N件-每满N元
        case 26: promotiontype = 'promotion_10';break // 全场满折
        case 25: promotiontype = 'promotion_11';break // 满折
        case 10: {
          if (flash_type == 1) promotiontype = 'promotion_12' // flash Sale（普通闪购
          else if (flash_type == 2) promotiontype = 'promotion_13' // New Users Only（新人闪购）
          else if (flash_type == 3) promotiontype = 'promotion_14' // EXCLUSIVE（特殊闪购
          else if (flash_type == 4) promotiontype = 'promotion_15' // 分人群定价专区闪购
          else if (flash_type == 5) promotiontype = 'promotion_16' // 不分人群定价专区闪购
          break
        }
        case 11: promotiontype = 'promotion_17';break // 直播闪购
        case 8: promotiontype = 'promotion_18';break // 专享折扣
        case 3: promotiontype = 'promotion_19';break // 限时折扣
        case 12: promotiontype = 'promotion_20';break // 会员专属折
        case 29: promotiontype = 'promotion_21';break // 付费会员
        case 30: promotiontype = 'promotion_22';break // 一口价
        case 31: promotiontype = 'promotion_23';break // 新人专享价
        case 32: promotiontype = 'promotion_24';break // AB价
        default: promotiontype = ''
      }
    } else {
      promotiontype = 'promotion_25' // 多个活动
    }
  }
  return promotiontype
}

export default {
  handleBenefitsReflectionType,
  handlePromotionBenefitsType
}
