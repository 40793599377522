var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id:'expose_unusedbenefits.page_checkout:simple',
    data: {
      benefits_type: _vm.resultTips.curShowBenefitsType // 展示了气泡就上报
    }
  }),expression:"{\n    id:'expose_unusedbenefits.page_checkout:simple',\n    data: {\n      benefits_type: resultTips.curShowBenefitsType // 展示了气泡就上报\n    }\n  }"},{name:"tap",rawName:"v-tap",value:({
    id: 'click_unusedbenefits.page_checkout:simple',
    data: {
      benefits_type: _vm.resultTips.curShowBenefitsType,
    }
  }),expression:"{\n    id: 'click_unusedbenefits.page_checkout:simple',\n    data: {\n      benefits_type: resultTips.curShowBenefitsType,\n    }\n  }"}],staticClass:"coupon-auto-tips__wrap",on:{"click":_vm.handleClick}},[_c('div',{staticClass:"tip-left",domProps:{"innerHTML":_vm._s(_vm.resultTips.htmlstr)}}),_vm._v(" "),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-right"},[_c('i',{staticClass:"icon suiiconfont sui_icon_viewmore_14px_2"})])
}]

export { render, staticRenderFns }