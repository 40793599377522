<template>
  <div
    class="coupon-auto-tips__wrap"
    @click="handleClick"
  >
    <div 
      class="tip-left" 
      v-html="resultTips"
    >
    </div>
    <div class="tip-right">
      <i class="icon suiiconfont sui_icon_viewmore_14px_2"></i>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { template as _commonTemplate } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'CouponAutoTips',
  props: {
    showCouponAutoTips: {
      type: Boolean,
      default: false
    },
    isAutoUsePoints: {
      type: Boolean,
      default: false
    },
    autoUseResourcesPrice: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('checkout', ['autoCouponInfo', 'language', 'price']),
    ...mapGetters('checkout', ['showNewCouponLanguage']),
    couponTips() {
      if (this.showNewCouponLanguage) {
        const tips = !!this.autoCouponInfo.couponGift ? this.language.SHEIN_KEY_PWA_31493 : this.language.SHEIN_KEY_PWA_31497
        const { coupon_discount_info: couponDiscountInfo = [] } = this.price
        const { amount, amountWithSymbol } = couponDiscountInfo[0]?.discount_price || {}
        let savedPrice = amount
        let savedPriceWithSymbol = amountWithSymbol
        if (couponDiscountInfo.length > 1) {
          const symbol = amountWithSymbol.replace(amount, '{0}')
          savedPrice = couponDiscountInfo.reduce((acc, cur) => acc + Number(cur.discount_price.amount), 0)
          savedPriceWithSymbol = this.template(savedPrice.toFixed(2), symbol)
        }
        if (+savedPrice > 0) {
          return this.template(
            `<span style="color: var(--sui-color-promo, #FA6338);font-weight: 700;">${savedPriceWithSymbol}</span>`, 
            tips
          )
        }
      }

      let tips = !!this.autoCouponInfo.couponGift ? this.language.SHEIN_KEY_PWA_24711 : this.language.SHEIN_KEY_PWA_24712
      const applyCouponsSuccess = this.autoCouponInfo.coupon || []
      return this.template(applyCouponsSuccess.length, tips)
    },

    resultTips() {
      // 仅使用券
      if (this.showCouponAutoTips && !this.isAutoUsePoints) {
        // 自动用券后上报
        this.reportShippingCoupon()
        return this.couponTips
      }
      // 仅使用积分
      if (!this.showCouponAutoTips && this.isAutoUsePoints) {
        return this.template(
          `<span style="color: var(--sui-color-promo, #FA6338);font-weight: 700;">${this.autoUseResourcesPrice}</span>`, 
          this.language.SHEIN_KEY_PWA_27077
        )
      }

      // 使用券 + 积分
      let tips = !!this.autoCouponInfo.couponGift ? this.language.SHEIN_KEY_PWA_27079 : this.language.SHEIN_KEY_PWA_27078
      if (this.showNewCouponLanguage) {
        tips = !!this.autoCouponInfo.couponGift ? this.language.SHEIN_KEY_PWA_31495 : this.language.SHEIN_KEY_PWA_31494
      }
      const applyCouponsSuccess = this.autoCouponInfo.coupon || []
      return this.template(
        applyCouponsSuccess.length,
        `<span style="color: var(--sui-color-promo, #FA6338);font-weight: 700;">${this.autoUseResourcesPrice}</span>`, 
        tips
      )
    }
  },
  methods: {
    template: _commonTemplate,
    handleClick() {
      this.$emit('click-auto-tips')
    },
    // 上报自动使用指定运输方式运费券
    reportShippingCoupon() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-172',
        extraData: { 
          scenes: 'shipping_coupon_stackable',
          type: this.autoCouponInfo?.coupon?.some(coupon => (
            ![53, 55].includes(+coupon.typeId) && coupon.applyFor == 9
          )) ? 1 : 0
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.coupon-auto-tips__wrap {
  position: absolute;
  top: -20/75rem;
  transform: translateY(-100%);
  background-color: rgba(0,0,0,.8);
  width: 702/75rem;
  height: auto;
  padding: 24/75rem;
  border-radius: 8/75rem;
  .flexbox();
  align-items: center;
  justify-content: space-between;
  color: @sui_color_white;

  .tip-right {
    .padding-l(12/75rem);
  }
}
</style>
