<template>
  <div
    class="shopping-bag-header"
    :class="{
      'bg-effiency': isSiteMallList && !GB_cssRight, 
      'bg-ar-effiency': isSiteMallList && GB_cssRight
    }"
  >
    <div class="shopping-bag-header__left">
      <span
        class="store-title"
        :class="{
          'store-title-mall': isSiteMallList
        }"
      >
        {{ shoppingBagTitle }}
      </span>
      <ShoppingBagBenefit 
        v-if="benefitList.length"
        :benefitList="benefitList"
      />
    </div>
    <span
      v-if="!showShoppingBagItemDetail"
      class="shopping-bag-header__right"
      @click="emits('openModal', 'button')"
    >
      {{ viewTips }}
      <Icon 
        name="sui_icon_more_right_16px" 
        size="16px" 
      />
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { template } from '@shein/common-function'
import { useMapState, useMapGetters } from 'public/src/pages/checkout/hooks/index.js'
import { Icon } from '@shein-aidc/icon-vue2'
import ShoppingBagBenefit from './ShoppingBagBenefit.vue'

const { GB_cssRight } = gbCommonInfo

const emits = defineEmits(['openModal'])

const props = defineProps({
  cartsInfo: {
    type: Array,
    default: () => []
  },
  cartsMallInfo: {
    type: Object,
    default: () => {}
  },
  showShoppingBagItemDetail: {
    type: Boolean,
    default: false
  },
  benefitList: {
    type: Array,
    default: () => []
  }
})

const { language } = useMapState(['language'])
const { isSiteMallList } = useMapGetters(['isSiteMallList']) 

const productsQuantity = computed(() => {
  if(!props.cartsInfo?.length) return 0
  const total = props.cartsInfo.reduce((total, item) => {
    total = total + Number(item.quantity)
    return total
  }, 0)

  return total
})

const shoppingBagTitle = computed(() => {
  let title = language.value.SHEIN_KEY_PWA_24067
  if (isSiteMallList.value) {
    title = props.cartsMallInfo?.mall_name
    if (props.benefitList.length) title += `(${productsQuantity.value})`
  } else if (props.benefitList.length) {
    title = template(
      productsQuantity.value,
      language.value.SHEIN_KEY_PWA_33296
    )
  }
  return title
})

const viewTips = computed(() => {
  if (productsQuantity.value === 0 || props.benefitList.length) return ''
  if (productsQuantity.value === 1) return language.value.SHEIN_KEY_PWA_23260
  return template(productsQuantity.value, language.value.SHEIN_KEY_PWA_23258)
})

</script>

<style lang="less" scoped>
.shopping-bag-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20/75rem 24/75rem 0 0;
  position: relative;

  &.bg-effiency {
    background: url(/pwa_dist/images/checkout/shopping_mall_title-87f1df1cc5.png);
    background-size: 100% 100%;
    margin-left: -24/75rem;
    padding: 16/75rem 20/75rem;
  }

  &.bg-ar-effiency {
    background: url(/pwa_dist/images/checkout/shopping_mall_title_ar-202a0604e7.png);
    background-size: 100% 100%;
    margin-left: -24/75rem;
    padding: 16/75rem 20/75rem;
  }

  &__left {
    display: flex;
    align-items: center;
    margin-right: 8/75rem;
    overflow: hidden;
  }

  .store-title {
    white-space: nowrap;
    padding-right: 16/75rem;
    font-weight: 700;
    padding-right: 8/75rem;
    & when (@IS_RW) {
			font-weight: 400;
      font-family: Adieu-Regular, Adieu;
		}
  }

  .store-title-mall {
    font-size: 16px;
  }

  &__right {
    font-size: 12px;
    color: #222;
    font-weight: normal;
    white-space: nowrap;
    display: flex;
    gap: 4/75rem;

    i {
      color: #767676;
    }
  }
}
</style>
