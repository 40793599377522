<template>
  <li 
    v-show="isShowItem"
    v-expose="{
      id: '1-11-1-58',
      data: shippingItemAnalysis
    }"
    v-tap="{
      id: '1-11-1-101',
      data: shippingItemAnalysis
    }"
    class="shipping-list-item"
    role="radio"
    @click="handleSelect"
  >
    <div :style="{width: radioWidth, height: '0.5333rem'}">
      <ClientOnly>
        <s-radio 
          :id="item.id"
          :value="userChecked"
          class="label-new"
          :disabled="isDisabled" 
          name="shipping" 
          :label="index"
          :margin-r="shipRadioMarginR"
        />
      </ClientOnly>
    </div>
    <div class="mcho-ship">
      <div 
        class="shipping-item-des" 
        :class="{ 'shipping-item-disabled': isDisabled }"
      >
        <span v-html="shippingItemTitle"></span>
        <s-label
          v-if="isPrimeShip"
          type="promo"
          class="prime-label"
        >
          <i
            class="sui_icon_club_logo_14px"
            :class="[iconPrefix]"
          ></i>
          <span>{{ +handlerShippingPrice.shipPrice === 0 ? language.SHEIN_KEY_PWA_15807 : language.SHEIN_KEY_PWA_22737 }}</span>
        </s-label>
        <s-label
          v-else-if="+handlerShippingPrice.shipPrice === 0"
          :class="[{'free-shipping-enhance': shippingTipEnhanceByAbt}, 'free-size']"
          type="success"
        >
          {{ language.SHEIN_KEY_PWA_15807 }}
        </s-label>

        <!-- 增加环保标签 -->
        <span v-if="isEnvironMental">
          <i
            class="suiiconfont sui_icon_environmental_16px environmental-icon"
          ></i>
          <span class="environmental-text">{{ language.SHEIN_KEY_PWA_24370 }}</span>
          <i 
            class="sui_icon_doubt_16px_2 icon-tips"
            :class="[iconPrefix]"
            @click.stop="cliskEnvironMental"
          ></i>
        </span>
      </div>

      <div 
        class="ship-type-list shipping-item-des" 
        :class="{ 'shipping-item-disabled': isDisabled }"
      >
        <span
          v-if="handlerShippingPrice.shipPriceWithSymbol"
          class="price"
        >
          <template v-if="isPrimeShip">
            <span class="price-free price-prime">
              <span>{{ handlerShippingPrice.shipPriceWithSymbol }}</span>
            </span>
            <span
              v-if="!isSuggested && isFreeShipLine"
              class="price-line"
            >{{ selectedShippingPrice.amountWithSymbol }}</span>
          </template>
          <template v-else-if="isFreeShip">
            <span class="price-free">
              <span>{{ handlerShippingPrice.shipPriceWithSymbol }}</span>
            </span>
            <span
              v-if="!isSuggested && isFreeShipLine"
              class="price-line"
            >{{ selectedShippingPrice.amountWithSymbol }}</span>
          </template>
          <template v-else-if="freightActivityType">
            <span class="price-flash">
              <i
                class="sui_icon_flashsale_15px"
                :class="[iconPrefix]"
              ></i>
              <span>{{ handlerShippingPrice.shipPriceWithSymbol }}</span>
            </span>
            <span
              v-if="!isSuggested && isPriceLine"
              class="price-line"
            >{{ handlerShippingPrice.policyPriceWithSymbol }}</span>
          </template>
          <template v-else>
            <span class="price-normal">{{ handlerShippingPrice.shipPriceWithSymbol }}</span>
          </template>
        </span>

        <template v-if="descQuickShipTime || largeShipTime || shippingDescribe">
          <template v-if="isAllQuickShip">
            <span class="price-des">
              (<span v-html="descQuickShipTime"></span>)
            </span>
            <i
              class="sui_icon_doubt_16px_2 icon-tips"
              :class="[iconPrefix]"
              @click.stop="showQuickPop"
            ></i>
          </template>
          <template v-else>
            <span class="price-des">
              (<span v-html="largeShipTime || shippingDescribe"></span>)
            </span>
            <i
              v-if="isShowDayPercent"
              class="sui_icon_doubt_16px_2 icon-tips"
              :class="[iconPrefix]"
              @click.stop="showDayPercentList"
            ></i>
          </template>
        </template>
      </div>

      <div class="ship-text-red shipping-item-des">
        <div v-if="!item.is_available">
          <template v-if="item.is_available_msg == 1">
            {{ language.SHEIN_KEY_PWA_15129 }}
          </template>
          <template v-if="item.is_available_msg == 2">
            ({{ language.SHEIN_KEY_PWA_16214 }})
          </template>
          <template v-if="item.is_available_msg == 3">
            ({{ language.SHEIN_KEY_PWA_16101 }}) 
          </template>
          <template v-if="item.is_available_msg == 4">
            ({{ language.SHEIN_KEY_PWA_16215 }})
          </template>
          <template v-if="item.is_available_msg == 8">
            ({{ language.SHEIN_KEY_PWA_24364 }})
          </template>
          <template v-if="isShowOrderInterceptMsg">
            ({{ item.rule_info.msg }})
          </template>
          <template v-if="item.is_available_msg == 14">
            ({{ language.SHEIN_KEY_PWA_32247 }})
          </template>
        </div>
      </div>
      
      <div 
        v-if="isShowShippingFee"
        v-expose="{
          id: '1-11-1-156',
          data: {
            mall_code: shippingItemAnalysis.mall_code,
            shipping_method: shippingItemAnalysis.shipping_method
          }
        }"
        v-tap="{
          id: '1-11-1-157',
          data: {
            mall_code: shippingItemAnalysis.mall_code,
            shipping_method: shippingItemAnalysis.shipping_method
          }
        }"
        class="shipping-item-des shipping-fee-info"
        @click.stop="clickShippingFeeDrawer"
        v-html="language.SHEIN_KEY_PWA_25534"
      >
      </div>

      <!-- 凑单入口 -->
      <ClientOnly>
        <AddOnItem 
          v-if="showAddEntrance"
          :addItemContentInfo="addItemContentInfo"
          :mallCode="mallCode"
          @addSuccess="addSuccess"
        />
      </ClientOnly>

      <!-- 大件海陆运提示 -->
      <LargeShipTips 
        v-if="showBigSizeEnter"
        :lagreShipInfo="lagreShipInfo"
      />
    </div>
  </li>
</template>

<script>
import ClientOnly from 'vue-client-only'
import Vue from 'vue'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { TRANSPORT_ENVIRONMENTAL, TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import { handleShipFeeGoods, isShippingMethodDisabled, handleLargeShipInfo } from 'public/src/pages/checkout/config/tools.js'

import { Label as SLabel } from '@shein/sui-mobile'
import AddOnItem from './AddItem.vue'
import LargeShipTips from './large_ship_info/LargeShipTips.vue'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })
Vue.component('ClientOnly', ClientOnly)

export default {
  name: 'ShippingMethodItem',
  components: {
    SLabel,
    AddOnItem,
    LargeShipTips,
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    isClickMore: {
      type: Boolean,
      default: false
    },
    checked: { // 接口选中数据
      type: Number,
      default: 0
    },
    mallCode: {
      type: [String, Number],
      default: ''
    },
    descQuickShipTime: {
      type: String,
      default: ''
    },
    isAllQuickShip: {
      type: Boolean,
      default: false
    },
    primeCouponCount: {
      type: Number,
      default: 0
    },
    isQuickShipTitle: {
      type: Boolean,
      default: false
    },
    morePosition: {
      type: Number,
      default: 0
    },
    quickShipInfo: {
      type: Object,
      default: () => {}
    },
    addItemContentInfo: {
      type: Object,
      default: () => {}
    },
    defaultShippingItem: {
      type: Object,
      default: () => {}
    },
    defaultSelectedIndex: {
      type: Number,
      default: 0
    },
  },
  computed: {
    ...mapState('checkout', [
      'language', 
      'checkout', 
      'changeShopType', 
      'locals',
      'selectStoreAddressShippingInfo',
      'price',
      'shippingAddStatusInfo',
      'forceUpdateShippingMethod',
      'collectShippingTimeText',
      'mallCartsList',
    ]),
    ...mapGetters('checkout', [
      'iconPrefix', 
      'timePercentAbt', 
      'showQuickShipByAbt', 
      'orderInterceptByAbt',
      'shipTimeDesList',
      'shippingTipEnhanceByAbt',
      'showAddEntranceByAbt',
      'quickShipCheckoutStatus',
      'isSuggested',
      'serviceType',
      'isSiteMallList',
      'largeShipExtendsInfo',
    ]),
    cartsData() {
      return this.mallCartsList.find(mall => mall?.[0]?.mall_code == this.mallCode) || []
    },
    lagreShipInfo() {
      const { 
        largeShipTimeDesc = '', 
        largeShipTitle = '', 
        largeShipGoods = [], 
        largeShipType = '', 
        largeShipIcon = '',
      } = handleLargeShipInfo({ 
        isSiteMallList: this.isSiteMallList, 
        largeShipExtendsInfo: this.largeShipExtendsInfo, 
        mallCode: this.mallCode, 
        carts: this.cartsData
      })
      return {
        largeShipTimeDesc,
        largeShipTitle,
        largeShipGoods,
        largeShipType,
        largeShipIcon,
      }
    },
    isLargeShipTitle() {
      const isAllLarge = this.cartsData?.length == this.lagreShipInfo?.largeShipGoods?.length
      return !!this.lagreShipInfo.largeShipTimeDesc && isAllLarge
    },
    largeShipTime() {
      return !!this.isLargeShipTitle ? this.lagreShipInfo.largeShipTimeDesc : ''
    },
    showBigSizeEnter() {
      // 只展示当前选中的
      if(this.index != this.checked) return false
      // 全部为大件商品时不展示入口
      return !!this.lagreShipInfo.largeShipTimeDesc && !!this.lagreShipInfo.largeShipGoods?.length && !this.isLargeShipTitle
    },
    // qs优先级高于大件
    shippingItemTitle() {
      let title = this.item.title
      if(this.isQuickShipTitle) { // qs时效标题
        title = this.language.SHEIN_KEY_PWA_22276
      } else if(this.isLargeShipTitle) { // 大件海陆运时效标题
        title = this.lagreShipInfo.largeShipTitle
      }
      return title
    },
    showAddEntrance() {
      if(!this.showAddEntranceByAbt) return false

      // 凑单组件打开时不能隐藏凑单入口
      if(this.shippingAddStatusInfo?.transportType == this.item?.transport_type) return true
      // 已经完成一次凑单流程后不再露出凑单入口
      if(!this.shippingAddStatusInfo?.getStatus || this.shippingAddStatusInfo?.addStatus) return false

      return this.addItemContentInfo?.transportType == this.item?.transport_type
    },
    userChecked() { // 用户选中展示勾选
      // 选择店配运输方式时不存在店配地址 -> 新增店配地址后选中的逻辑，是当前mall且运输方式相同
      const { mallCode, shippingMethod } = this.selectStoreAddressShippingInfo || {}
      // 完成凑单关闭弹窗时触发的下单限制不展示限制弹窗，且运输方式展示勾选态
      const { mallCode: mall_code, shippingMethod: shipping_method } = this.forceUpdateShippingMethod || {}
      if (+this.mallCode === +mallCode && shippingMethod?.transport_type === this.item?.transport_type) return this.checked
      if (+this.mallCode === +mall_code && shipping_method?.transport_type === this.item?.transport_type) return this.checked

      if(!this.isDisabled) return this.checked

      return -1
    },
    isDisabled() {
      return isShippingMethodDisabled({ item: this.item, orderInterceptByAbt: this.orderInterceptByAbt })
    },
    isShowOrderInterceptMsg() {
      return this.orderInterceptByAbt && this.item.is_available_msg == 9 && +this.item.rule_info?.hint_type == 0
    },
    IS_RW() {
      return this.locals?.IS_RW ?? gbCommonInfo.IS_RW
    },
    radioWidth() {
      let width = 0.5333
      let marginRight = 16 / 75
      width += marginRight
      return `${width}rem`
    },
    shipRadioMarginR() {
      let marginRight = 16 / 75
      return `${marginRight}rem`
    },
    shippingItemAnalysis() {
      const filterMall = this.shipTimeDesList?.filter(item => item.mall_code == this.mallCode) || []
      const shipping_time_desc = filterMall?.[0]?.shipping_methods?.filter(method => method.transport_type == this.item.transport_type)?.[0]?.shipping_time_desc || '-'
      const interceptList = [0, 1, 2, 3, 7]
      const scenes = this.item.is_available == 0 && this.item.is_available_msg == 9 && interceptList.includes(+this.item.rule_info?.hint_type) ? 'interceptionRules' : '-'
      const currentIndexIsDefault = this.defaultSelectedIndex == this.index
      const isUseCacheShipping = this.checkout?.cacheInfo?.mall_transport_type?.used
      let isFolded = 0

      if (this.userChecked < 1 && this.index > 0 && this.isClickMore) {
        isFolded = 1
      }
      return { 
        is_default: currentIndexIsDefault ? '1' : '0',
        shipping_method: this.item.transport_type,
        shipping_time: this.isAllQuickShip ? this.quickShipInfo.quick_ship_time : this.item.shipping_time || '-',
        shipping_fee: this.handlerShippingPrice?.shipUsdPrice,
        mall_code: this.mallCode,
        shipping_time_desc,
        scenes,
        is_folded: isFolded,
        sequence: this.index + 1,
        style: 'list',
        default_type: currentIndexIsDefault ? (isUseCacheShipping ? 2 : 1) : 0,
      }
    },
    // 判断是否展示环保图标
    isEnvironMental(){
      return this.serviceType !== 'eur' && TRANSPORT_ENVIRONMENTAL.includes(this.item.transport_type)
    },
    isShowDayPercent() {
      return this.timePercentAbt && !!this.item?.day_percents?.length
    },
    isShowItem() {
      const { index, isClickMore, changeShopType, morePosition } = this
      return morePosition == 0 || (index < +morePosition || isClickMore) || changeShopType
    },
    shippingPriceItem() {
      const item = this.item
      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      const filterCalcu = mall_list.filter(mall => mall.mall_code == this.mallCode)
      const shipping_price_all = filterCalcu[0]?.shipping_price_all || []
      const shipping_price = shipping_price_all.filter(price => price.transport_type == item.transport_type) || []
      return shipping_price[0] || {}
    },
    handlerShippingPrice() {
      return { 
        shipPriceWithSymbol: this.shippingPriceItem.price?.amountWithSymbol || '', 
        shipPrice: this.shippingPriceItem.price?.amount || '', 
        shipUsdPrice: this.shippingPriceItem.price?.usdAmount || '',
        policyPriceWithSymbol: this.shippingPriceItem.policy_price?.amountWithSymbol || '',
        policyPrice: this.shippingPriceItem.policy_price?.amount || ''
      }
    },
    shippingFeeRuleList() {
      const originList = JSON.parse(JSON.stringify(this.shippingPriceItem.shipping_free_rule_info_list || []))
      return originList.sort((a, b) => +a.freight_activity_type - +b.freight_activity_type)
    },
    isShowShippingFee() {
      if(this.IS_RW || !this.shippingFeeRuleList.length) return false

      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      let rule_id_list = []
      mall_list.forEach(mall => { 
        mall.shipping_price_all?.forEach(ship => {
          ship.shipping_free_rule_info_list?.forEach(item => {
            rule_id_list.push(+item.freight_free_rule_id)
          })
        })
      })
      rule_id_list = [...new Set(rule_id_list)]
      return rule_id_list.length > 1
    },
    isPrimeShip() {
      const { index, checked, primeCouponCount } = this
      if (index != checked) return false
      if (+primeCouponCount > 0) return true
      return false
    },
    isFreeShip() {
      const { index, checked, handlerShippingPrice } = this
      if (index != checked) return false
      const { shipPrice } = handlerShippingPrice
      return +shipPrice === 0
    },
    isFreeShipLine() { // 活动用券
      const { shipPrice } = this.handlerShippingPrice
      const originShipPirce = this.selectedShippingPrice?.amount
      if (+shipPrice < +originShipPirce) return true
      return false
    },
    isPriceLine() { // 闪购
      const { policyPrice, shipPrice } = this.handlerShippingPrice
      if (+policyPrice <= 0) return false
      if (+policyPrice > +shipPrice) {
        this.assignState({ showMarkedPrice: 1 })
        return true
      }
      return false
    },
    freightActivityType() {
      const { policyPrice } = this.handlerShippingPrice
      // 当前用policy_price 不为0判断是否闪购，待后期优化调整
      return +policyPrice > 0
    },
    selectedShippingPrice() {
      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      const filterCalcu = mall_list.filter(mall => mall.mall_code == this.mallCode)
      return filterCalcu[0]?.shipping_price || {}
    },
    shippingDescribe () {
      const item = this.item
      if (!item) return ''

      let key = 0; let _html = ''

      if (item.desc_type == 1) {
        _html = item.desc_expect_time ? item.desc_expect_time.split(/&amp;&amp;|&&/)[key] : ''
        if (this.timePercentAbt) _html += item?.delivery_day_percent_desc || ''
      } else {
        _html = item.desc_shipping_time ? item.desc_shipping_time.split(/&amp;&amp;|&&/)[key] : ''
        if (this.timePercentAbt) _html += item?.shipping_day_percent_desc || ''
      }

      const replaceValue = item.cmmdty_infos?.length ? 
        `<i class="sh_m_sui_icon_sensitive_16px"></i>` : ''
      _html = _html.replace('{sensitive_icon}', replaceValue)

      if( _html ){
        _html = _html.replace(/script/g, '').replace(/\s/g, ' ').replace(/&nbsp;/g, ' ')

        this.quickShipCheckoutStatus?.quickShipLabelText && (_html = _html.replace(/{QSname}/g, `<span style="color:#198055;font-weight: 400;">${this.quickShipCheckoutStatus.quickShipLabelText}</span>`)
          .replace(/\[time\]/g, `<span style="color:#198055">${item.quick_ship_time}</span>`))
      }
      return _html
    },
    currentShippingTimeDesc() {
      return this.isAllQuickShip ? this.descQuickShipTime : this.shippingDescribe
    },
  },
  watch: {
    descQuickShipTime: {
      handler(v) {
        if (v) {
          daEventCenter.triggerNotice({
            daId: '1-11-1-88',
            extraData: {
              quickship_tp: '1'
            }
          })
        }
      },
      immediate: true
    },
    currentShippingTimeDesc: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          const shippingTimeText = Object.assign({}, this.collectShippingTimeText)
          const currentCodeShipping = shippingTimeText[this.mallCode] || []

          if (!!currentCodeShipping?.length) {
            const existIndex = currentCodeShipping?.findIndex(val => val?.transport_type == this.item?.transport_type)

            if (existIndex > -1 && currentCodeShipping[existIndex]?.time_text != newVal) {
              currentCodeShipping[existIndex].time_text = newVal
            } else if(existIndex == -1){
              currentCodeShipping.push({ transport_type: this.item?.transport_type, time_text: newVal })
            }
            this.assignState({ collectShippingTimeText: { ...shippingTimeText, [this.mallCode]: currentCodeShipping } })
          } else {
            shippingTimeText[this.mallCode] = [{ transport_type: this.item?.transport_type, time_text: newVal }]
            this.assignState({ collectShippingTimeText: shippingTimeText })
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('checkout', ['showMaskTips', 'assignState', 'updateState', 'updateShippingAddStatusInfo']),
    ...mapActions(['updateCart']),
    /**
     * status 当前是否完成凑单（0未完成、1已完成）
     */
    addSuccess({ status = 0 }) {
      this.updateCart({ noUpdataShipping: 1 })
      /**
       * 1.未完成凑单保留当前选中的运输方式（如果当前选中的受限了，不弹受限弹窗）
       * 2.完成凑单切换为凑单的运输方式
       * 2.1宅店配切换场景，不论切换的是否受限都切过去，不弹受限弹窗
       * 2.2未触发宅店配切换，切换的受限则返回当前选中的，切换的不受限帮用户切过去，不弹受限弹窗
       */
      const isPrevStoreAddress = TRANSPORT_TYPE_SHOP.includes(this.defaultShippingItem.shipping_method.transport_type)
      const isStoreAddress = TRANSPORT_TYPE_SHOP.includes(this.item.transport_type)
      const isChangeType = isPrevStoreAddress != isStoreAddress
      this.handleSelect({ addItemUpdate: true, item: status == 0 ?  this.defaultShippingItem?.shipping_method : this.item, skipShipIntercept: true, isInterceptBack: (status == 1 && !isChangeType) })

      if(status == 1) {
        sessionStorage.setItem('checkout_shipping_additem_success', 1)
        this.updateShippingAddStatusInfo({
          addStatus: true
        })
      }
    },
    async clickShippingFeeDrawer() {
      const cartsMallInfo = this.checkout.results?.carts?.mall_list?.find(item => item.mall_code == this.mallCode) || {}
      const limitGoods = await handleShipFeeGoods(this.shippingFeeRuleList || [])
      const ruleInfo = {
        mall_list: [
          {
            mall_code: this.mallCode,
            mall_name: cartsMallInfo.mall_name || '',
            rule_list: limitGoods,
          }
        ],
        total_price: this.shippingPriceItem.price,
        save_price: this.shippingPriceItem.price_diff
      }
      this.assignState({
        shippingFeeRuleInfo: ruleInfo
      })
      this.updateState({
        key: 'showShippingFeeDrawer', value: true
      })
    },
    cliskEnvironMental() {
      this.assignState({
        showEnvironMental: true
      })
    },
    showDayPercentList() {
      this.$emit('showDayPercentList', this.item?.transport_type)
      daEventCenter.triggerNotice({
        daId: '1-11-1-100',
        extraData: {
          mall_code: this.mallCode,
          shipping_method: this.item.transport_type
        }
      })
    },
    /**
     * addItemUpdate 是否由凑单触发强制更新运输方式接口
     * item 当前要切换的运输方式（默认当前item，强制更新时可传入其他运输方式）
     * isInterceptBack 即将切换的运输方式受限是否回退到之前选中的
     * skipShipIntercept 触发下单限制前置是否不展示限制弹窗
     */
    handleSelect({ addItemUpdate = false, item = this.item, isInterceptBack = false, skipShipIntercept = false } = {}) { 
      // 强制更新时跳过校验
      if(!addItemUpdate) {
        if ((this.userChecked != -1 && this.index == this.checked) || this.isDisabled) return
      }

      this.$emit('handleSelect', { item, addItemUpdate, isInterceptBack, skipShipIntercept })
    },
    showQuickPop() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-89',
        extraData: {
          quickship_tp: '1'
        }
      })
      this.assignState({
        isShowQuickPop: true
      })
    },
  }
}
</script>

<style lang="less">
@import '../style/svgicon.less';
.shipping-list-item {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  border: none;
  padding: 12/75rem 0 0;
  > label {
    .txt-r();
  }
  
  .label-new {
    width: auto;
    align-items: flex-start;
  }
}
.mcho-ship {
  width: 100%;
  >.shipping-item-des {
    margin-bottom: 8/75rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}


.ship-type-list {
  .font-dpr(24px);
  .price-normal {
    font-weight: bold;
  }
  .price-free {
    font-weight: bold;
  }
  .price-flash {
    i {
      color: @sui_color_flash;
    }
    span {
      color: @sui_color_discount_dark;
    }
  }
  .price-prime {
    color: @sui_color_club_rosegold_dark1;
  }
  .price-line {
    text-decoration: line-through;
    color: @sui_color_gray_light1;
  }
  .price-des {
    color: @sui_color_gray_dark3;
  }
}
.prime-label {
  color: @sui_color_club_rosegold_dark3;
}
.free-shipping-enhance {
  background: @sui_color_safety;
  border-radius: 2px;

  &.S-label__success {
    color: @sui_color_white;
  }
}
.free-size{
  font-size: 10px !important;
  padding: 2/75rem 0.1067rem !important;
}
.icon-tips {
  color: @sui_color_gray_light1;
}
.environmental-label{
  margin-bottom: 5px;
}
.environmental-text{
    font-size: 12px;
}
.environmental-icon{
    color: #008C00;
}

.shipping-item-disabled {
  opacity: 0.3;
}
.ship-text-red {
  color: @sui_color_unusual;
}
.shipping-fee-info {
  color: @sui_color_link;
  .font-dpr(24px);
  margin-top: -4/75rem;
}
</style>
