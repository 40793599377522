<template>
  <ul>
    <template
      v-for="item in handleComponentList"
    >
      <component
        :is="item.name"
        v-if="item.show || isClickedMore"
        :key="item.name"
        :is-folded="item.isFolded"
        class="checkout-assets-item"
        v-bind="item.props"
      />
    </template>

    <li
      v-if="isShowMore && !isClickedMore"
      v-expose="{
        id: '1-11-1-115',
      }"
      v-tap="{
        id: '1-11-1-116',
      }"
      class="assets-more"
      @click="isClickedMore=true"
    >
      {{ language.SHEIN_KEY_PWA_21350 }}
      <i
        class="sui_icon_more_down_12px_2"
        :class="[iconPrefix]"
      ></i>
    </li>
  </ul>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import AssetsCoupon from '../vue_tpls/assets_list/assets_coupon_effiency.vue'
import AssetsWallet from '../vue_tpls/assets_list/assets_wallet.vue'
import AssetsPoints from '../vue_tpls/assets_list/assets_points.vue'
import AssetsGiftcard from '../vue_tpls/assets_list/assets_giftcard.vue'

import { daEventCenter } from 'public/src/services/eventCenter/index'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'AssetsListEffiency',
  components: {
    AssetsCoupon,
    AssetsWallet,
    AssetsPoints,
    AssetsGiftcard,
  },
  props: {
    checkCouponPrice: {
      type: String,
      default: ''
    },
    isSiteMallList: {
      type: Boolean,
      default: false
    },
    riskStr: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      isClickedMore: false,
    }
  },
  computed: {
    ...mapState('checkout', [
      'checkout',
      'language',
    ]),
    ...mapGetters('checkout', [
      'iconPrefix'
    ]),
    showPoints() {
      return +this.checkout?.results?.supplement?.availablePoints > 0
    },
    showWallet() {
      return !!this.checkout?.results?.wallet?.length
    },
    isShowMore() { // 存在钱包或者积分为空的场景展示more
      return !this.showPoints || !this.showWallet
    },
    componentList() {
      return [
        {
          name: 'AssetsCoupon',
          props: {
            checkCouponPrice: this.checkCouponPrice,
            isSiteMallList: this.isSiteMallList,
          },
          type: 'coupon',
          show: true,
          sort: 0
        },
        {
          name: 'AssetsWallet',
          props: {
            riskStr: this.riskStr,
            goWalletPage: () => { this.goWalletPage() }
          },
          type: 'wallet',
          show: this.showWallet,
          sort: 0
        },
        {
          name: 'AssetsPoints',
          props: {
            isSiteMallList: this.isSiteMallList,
          },
          type: 'points',
          show: this.showPoints,
          sort: 0
        },
        {
          name: 'AssetsGiftcard',
          props: {},
          type: 'giftcard',
          show: true,
          sort: 0
        }
      ]
    },
    handleComponentList() {
      let list = this.componentList
      if (!this.showWallet && !this.showPoints) { // 钱包和积分均折叠：券、礼品卡、钱包、积分
        let tmpList = list.map(item => {
          let sort = 0
          let isFolded = 0 // 当前的模块是否折叠
          if(item.type == 'coupon') sort = 0
          if(item.type == 'giftcard') sort = 1
          if(item.type == 'wallet') { 
            sort = 2
            isFolded = 1
          }
          if(item.type == 'points') {
            sort = 3
            isFolded = 1
          }

          return Object.assign({}, item, { sort: sort, isFolded })
        })

        return tmpList.sort((a, b) => a.sort - b.sort)
      } else if(!this.showWallet && this.showPoints) { // 仅折叠钱包：券、积分、礼品卡、钱包
        let tmpList = list.map(item => {
          let sort = 0
          let isFolded = 0 // 当前的模块是否折叠
          if(item.type == 'coupon') sort = 0
          if(item.type == 'wallet') {
            sort = 3
            isFolded = 1
          }
          if(item.type == 'points') sort = 1
          if(item.type == 'giftcard') sort = 2

          return Object.assign({}, item, { sort: sort, isFolded })
        })

        return tmpList.sort((a, b) => a.sort - b.sort)
      } else if(this.showWallet && !this.showPoints) { // 仅折叠积分：券、钱包、礼品卡、积分
        let tmpList = list.map(item => {
          let sort = 0
          let isFolded = 0 // 当前的模块是否折叠
          if(item.type == 'coupon') sort = 0
          if(item.type == 'wallet') sort = 1
          if(item.type == 'points') { 
            sort = 3
            isFolded = 1
          }
          if(item.type == 'giftcard') sort = 2

          return Object.assign({}, item, { sort: sort, isFolded })
        })

        return tmpList.sort((a, b) => a.sort - b.sort)
      } else { // 都不折叠：券、钱包、积分、礼品卡
        let tmpList = list.map(item => {
          let sort = 0
          if(item.type == 'coupon') sort = 0
          if(item.type == 'wallet') sort = 1
          if(item.type == 'points') sort = 2
          if(item.type == 'giftcard') sort = 3

          return Object.assign({}, item, { sort: sort, isFolded: 0 })
        })

        return tmpList.sort((a, b) => a.sort - b.sort)
      }
    },
  },
  methods: {
    goWalletPage() {
      const isFolded = this.handleComponentList?.find(val => val.name === 'AssetsWallet')?.isFolded || 0
      this.$emit('goWalletPage', isFolded)
    },
  }
}
</script>

<style lang="less" scoped>
.assets-more {
  text-align: center;
  .font-dpr(28px);
  color: @sui_color_gray_dark3;
  border-bottom: 0;
  padding: 20/75rem 0;
}
</style>
