<template>
  <!-- 0元门槛优惠 -->
  <s-label
    v-if="isShow"
    type="promo"
    class="payment-discount-zero"
  >
    <template v-if="onlinePayDiscountInfoValue.discountType == 0">
      <template v-if="GB_cssRight">
        {{
          language.SHEIN_KEY_PWA_14988 +
          ' ' +
          onlinePayDiscountInfoValue.amount.amountWithSymbol
        }}
      </template>
      <template v-else>
        {{
          onlinePayDiscountInfoValue.amount.amountWithSymbol +
          ' ' +
          language.SHEIN_KEY_PWA_14988
        }}
      </template>
    </template>

    <template v-else-if="onlinePayDiscountInfoValue.discountType == 1">
      <template v-if="GB_cssRight">
        {{
          language.SHEIN_KEY_PWA_14988 +
          ' ' +
          onlinePayDiscountInfoValue.percent +
          '%'
        }}
      </template>
      <template v-else>
        {{
          onlinePayDiscountInfoValue.percent +
          '% ' +
          language.SHEIN_KEY_PWA_14988
        }}
      </template>
    </template>
  </s-label>
</template>

<script setup>
import { useStore } from 'public/src/pages/checkout/hooks/index.js'
import { computed } from 'vue'

const { GB_cssRight } = gbCommonInfo

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  },

  language: {
    type: Object,
    default: () => {}
  }
})

const store = useStore()

const price = computed(() => {
    return store?.state?.checkout?.price
  })

const onlinePayDiscountInfoValue = computed(() => {
    return (
      price.value?.onlinePayDiscountInfo?.onlinePayDiscountValue?.[
        props.item?.code
      ] || null
    )
  })

const itemCode = computed(() => {
  return props.item?.code?.toLowerCase() || ''
})

const onlinePayDiscountInfoMinAmount = computed(() => {
  return +onlinePayDiscountInfoValue.value?.min?.amount
})

const isShow = computed(() => {
  return (
    props.item.enabled &&
    onlinePayDiscountInfoValue.value &&
    ((itemCode.value != 'cod' && onlinePayDiscountInfoMinAmount.value == 0) ||
      itemCode.value == 'cod')
  ) && (
    [0, 1].includes(onlinePayDiscountInfoValue.value?.discountType)
  )
})
</script>

<style lang="less" scoped>
.payment-discount-zero {
    border: .5px solid #ffe1db;
}
</style>