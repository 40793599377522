<template>
  <div class="footer-no-pay">
    <div class="title">
      <span
        class="title-text"
        ref="titleRef"
      >
        {{ language.SHEIN_KEY_PWA_23556 }}
      </span>
    </div>
    <div class="pay-img">
      <template v-for="(payment, index) in paymentMethodsHadImg">
        <img
          v-if="index < limitImgNum"
          :key="payment.id"
          class="payment-src"
          :src="payment.url"
        />
      </template>
      <div
        class="exceed-five-block"
        v-if="isExceedFivePay"
      >
        <span class="exceed-text">...</span>
      </div>
    </div>
    <div
      class="select-btn"
      @click="showSelectDrawer"
    >
      <span class="select-btn-text">
        {{ language.SHEIN_KEY_PWA_30068 }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, defineEmits } from 'vue'

const props = defineProps({
  checkout: { type: Object, default: () => ({}) },
  banksConfig: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  },
  isTextOverflow: {
    type: Function,
    default: () => {}
  },
  checkoutGuideLogoList: {
    type: Array,
    default: () => []
  }
})

const emits = defineEmits(['show-select-drawer'])

const titleRef = ref(null) // 标题ref
const limitImgNum = ref(4)
const isMiniTitle = ref(false)

const paymentMethodsWithImgList = computed(() => {
  const MAX_GUIDE_LOGO_COUNT = 6
  const { paymentMethods = [], checkout_guide_logo = [] } =
    props.checkout?.results || {}
  const laterGuideLogo = (paymentMethods || [])
    .filter(v => v.enabled && v.is_display && v.logo_url)
    .slice(0, MAX_GUIDE_LOGO_COUNT - checkout_guide_logo.length)
  const guideLogoList = [...checkout_guide_logo, ...laterGuideLogo].map(
    (v, index) => ({
      url: v.url || v.logo_url || '',
      id: `${index}-${v.id}`
    })
  )
  return guideLogoList
})

const paymentMethodsHadImg = computed(() => {
  // 当标题达到最宽宽度仅展示3个+更多
  if (isMiniTitle.value && paymentMethodsWithImgList.value?.length > 3) {
    return paymentMethodsWithImgList.value.slice(0, 3)
  }
  return paymentMethodsWithImgList.value
})

// 是否超过5个支付方式
const isExceedFivePay = computed(() => {
  return (
    paymentMethodsWithImgList.value?.filter(item => item?.url?.length > 0)?.length >
    limitImgNum.value
  )
})

const showSelectDrawer = () => {
  emits('show-select-drawer')
}

// 检测是否文本溢出设置字号
const setTextOverflow = () => {
  props.isTextOverflow(titleRef.value, () => {
    titleRef.value?.classList?.add('mini-title')
    isMiniTitle.value = true
  })
}

onMounted(() => {
  setTextOverflow()
})
</script>

<style lang="less" scoped>
.footer-no-pay {
  display: flex;
  align-content: center;
  padding: 16/75rem 24/75rem;
  .title {
    color: #222222;
    font-size: 14px;
    font-family: SF Pro;
    font-weight: 700;
    word-wrap: break-word;
    margin-right: 12/75rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .title-text {
      display: inline-block;
      max-width: 140 * 2/75rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .mini-title {
    font-size: 10px;
    font-weight: 590;
    line-height: 17px;
  }

  .pay-img {
    display: flex;
    align-items: center;
    flex: 1;
    .payment-src {
      width: 48/75rem;
      height: 32/75rem;
      margin-right: 8/75rem;
    }
  }

  .exceed-five-block {
    width: 48/75rem;
    height: 32/75rem;
    background: #e5e5e5;
    border-radius: 2px;
    color: #959595;
    display: flex;
    align-items: center;
    justify-content: center;

    .exceed-text {
      display: inline-block;
      height: 20/75rem;
      line-height: 4/75rem;
    }
  }

  .select-btn {
    min-width: 120/75rem;
    max-width: 164/75rem;
    height: 46/75rem;
    border-radius: 22px;
    border: 1px #fe3b30 solid;
    color: #fe3b30;
    font-size: 12px;
    font-family: SF Pro;
    font-weight: 510;
    word-wrap: break-word;
    display: flex;
    align-items: center;
    justify-content: center;

    .select-btn-text {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }
  }
}
</style>
