var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apple-pay-btn",style:({
    'justify-content': _vm.propsStyle.justifyContent,
    'display': _vm.propsStyle.display,
    'width': _vm.propsStyle.width
  })},[_c('ClientOnly',[_c('apple-pay-button',{style:({
        '--apple-pay-button-width': _vm.propsStyle.width,
        '--apple-pay-button-height': _vm.propsStyle.height
      }),attrs:{"buttonstyle":"black","type":"pay","locale":_vm.applePayBtnLocale},on:{"click":_vm.handleBtnClick}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }