<template>
  <div
    v-if="cartsInfo.length"
    class="mcheo-stand shopping-bag-options__wrap"
  >
    <ShoppingBagHeader 
      :benefitList="benefitList"
      :cartsInfo="cartsInfo"
      :cartsMallInfo="cartsMallInfo"
      :showShoppingBagItemDetail="showShoppingBagItemDetail"
      @openModal="openModal"
    />
    <ClientOnly>
      <s-alert
        v-if="showTaxHint"
        v-expose="{
          id: '1-11-1-168',
          data: { mall_code: mallCode, location: 'page' }
        }"
        v-tap="{
          id: '1-11-1-169',
          data: { mall_code: mallCode, location: 'page' }
        }"
        class="shopping-bag__hint"
        type="info" 
        :max-rows="1000"
        :jumpable="!isLastGoods"
        @click.native.stop="clickTaxHint"
      >
        <div v-html="taxHint"></div>
      </s-alert>
    </ClientOnly>
    <ul 
      :id="`shopping_bag_checkout_${mallCode}`" 
      class="shopping-bag-content" 
      :class="{ 'shopping-bag-content__edit': canEditShoppingbag }"
      @click="openModal('pictures')"
    >
      <!-- 全部非qs -->
      <template v-if="!quickShipCarts.length">
        <ShoppingBagItemDetail
          v-if="showShoppingBagItemDetail"
          ref="shoppingBagItemDetailRef"
          :id="mallCode_quickShip(0)" 
          :key="`noshow-${noQuickShipCarts?.[0]?.id}`"
          class="shopping-bag-options__item-detail"
          :cart="noQuickShipCarts[0]"
          :index="0"
          :show-arrival-price-by-abt="showArrivalPriceByAbt"
          isLastGoods
        />
        <TransitionGroup 
          v-else
          :id="mallCode_quickShip(0)" 
          tag="div" 
          name="fadeedit" 
          class="shopping-bag-options__list shopping-bag-options__list-noshow current_dom_together_buy_mall_quick"
        >
          <template v-for="(item, index) in noQuickShipCarts">
            <ShoppingBagItem 
              v-if="canEditShoppingbag ? true : index < 6"
              ref="shoppingBagItemRef"
              :id="`checkout-cart-item-${item.id}`"
              :key="`noshow-${item.id}`"
              :index="index"
              :item="item"
              :carts-count="item.cart_count || 0"
              :from-type="fromType"
              :showEditShoppingbag="canEditShoppingbag"
              :isLastGoods="isLastGoodsWithQs"
            />
          </template>
        </TransitionGroup>
      </template>
      <template v-else>
        <!-- 全部qs -->
        <template v-if="!noQuickShipCarts.length">
          <div 
            v-if="!showShoppingBagItemDetail"
            :class="['bag-quick-ship','bag-quick-wrap', quickShipCheckoutStatus.quickShipStyle && 'bag-quick-mt']"
          >
            <template v-if="!quickShipCheckoutStatus.quickShipStyle">
              {{ template(quickShipCheckoutStatus.quickShipLabelText, language.SHEIN_KEY_PWA_30854) }}
            </template>
            <template v-else>
              <div class="quickShip-tage">
                <quickShipLabel 
                  :text="quickShipCheckoutStatus.quickShipLabelText"
                  :style-config="quickshiLabelStyle"
                />
                <span>{{ language.SHEIN_KEY_PWA_30853 }}</span>
              </div>
            </template>
          </div>
          <ShoppingBagItemDetail
            v-if="showShoppingBagItemDetail"
            ref="shoppingBagItemDetailRef"
            :id="mallCode_quickShip(1)" 
            :key="`all-${quickShipCarts?.[0]?.id}`"
            class="shopping-bag-options__item-detail"
            :cart="quickShipCarts[0]"
            :index="0"
            :show-arrival-price-by-abt="showArrivalPriceByAbt"
            isLastGoods
          />
          <TransitionGroup 
            v-else
            :id="mallCode_quickShip(1)" 
            tag="div" 
            name="fadeedit" 
            class="shopping-bag-options__list shopping-bag-options__list-all current_dom_together_buy_mall_quick"
          >
            <template v-for="(item, index) in quickShipCarts">
              <ShoppingBagItem 
                v-if="canEditShoppingbag ? true : index < 6"
                ref="shoppingBagItemRef"
                :id="`checkout-cart-item-${item.id}`"
                :key="`all-${item.id}`"
                :index="index"
                :item="item"
                :carts-count="item.cart_count || 0"
                :from-type="fromType"
                :showEditShoppingbag="canEditShoppingbag"
                :isLastGoods="isLastGoodsWithQs"
              />
            </template>
          </TransitionGroup>
        </template>
        <!-- 部分qs -->
        <template v-else>
          <div :class="['bag-quick-wrap', quickShipCheckoutStatus.quickShipStyle && 'bag-quick-mt']">
            <div class="bag-quick-ship">
              <template v-if="!quickShipCheckoutStatus.quickShipStyle">
                {{ template(cartNumTotal(quickShipCarts), quickShipCheckoutStatus.quickShipLabelText, language.SHEIN_KEY_PWA_30856) }}
              </template>
              <template v-else>
                <div class="quickShip-tage">
                  <quickShipLabel 
                    :text="quickShipCheckoutStatus.quickShipLabelText"
                    :style-config="quickshiLabelStyle"
                  />
                  <span>{{ template(cartNumTotal(quickShipCarts), `{0} ${language.SHEIN_KEY_PWA_15456}`) }}</span>
                </div>
              </template>
            </div>
            <!-- qs不需要运费, 根据quickShipStyle abt控制样式调整 -->
            <template v-if="!showQsFreight && !quickShipCheckoutStatus.quickShipStyle">
              <div
                v-if="descQuickShipTime"
                class="bag-quick-time"
                @click.stop="showQuickPop"
              >
                <span v-html="descQuickShipTime">
                </span>
              </div>
            </template>
            <!-- qs需要运费 -->
            <template v-if="showQsFreight && !quickShipCheckoutStatus.quickShipStyle">
              <!-- freightChecked 状态与计价入参绑定 -->
              <div 
                class="qs__freight-wrap"
                @click.stop.prevent="toggleQsFreight"
              >
                <div style="width: 0.8533rem;height: 0.5333rem">
                  <ClientOnly>
                    <s-radio
                      :value="freightChecked"
                      :reverse-select="true"
                      :label="true"
                      :inline="false"
                      :margin-r="16/75 + 'rem'"
                    />
                  </ClientOnly>
                </div>
                <div class="qs__freight-content">
                  <div class="qs__freight-name">
                    <span v-html="qsFreightInfo.qs_freight_name"></span>
                    <i
                      class="sui_icon_doubt_16px_2"
                      :class="[iconPrefix]"
                      @click.stop.prevent="showFreightTips(qsFreightInfo.qs_freight_tips)"
                    ></i>
                  </div>
                  <div
                    v-if="descQuickShipTime"
                    class="bag-quick-time"
                    @click.stop="showQuickPop"
                    v-html="descQuickShipTime"
                  >
                  </div>
                </div>
              </div>
            </template>
          </div>
          <TransitionGroup 
            :id="mallCode_quickShip(1)" 
            tag="div" 
            name="fadeedit" 
            class="shopping-bag-options__list shopping-bag-options__list-quick current_dom_together_buy_mall_quick"
          >
            <template v-for="(item, index) in quickShipCarts">
              <ShoppingBagItem 
                v-if="canEditShoppingbag ? true : index < 6"
                ref="shoppingBagItemRef"
                :id="`checkout-cart-item-${item.id}`"
                :key="`quick-${item.id}`"
                :index="index"
                :item="item"
                :carts-count="item.cart_count || 0"
                :from-type="fromType"
                :showEditShoppingbag="canEditShoppingbag"
                :isLastGoods="isLastGoodsWithQs"
              />
            </template>
          </TransitionGroup>
  
          <div class="bag-quick-ship bag-quick-wrap">
            {{ template(cartNumTotal(noQuickShipCarts), language.SHEIN_KEY_PWA_22343) }}
          </div>
          <TransitionGroup 
            :id="mallCode_quickShip(0)" 
            tag="div" 
            name="fadeedit" 
            class="shopping-bag-options__list shopping-bag-options__list-noquick current_dom_together_buy_mall_quick"
          >
            <template v-for="(item, index) in noQuickShipCarts">
              <ShoppingBagItem 
                v-if="canEditShoppingbag ? true : index < 6"
                ref="shoppingBagItemRef"
                :id="`checkout-cart-item-${item.id}`"
                :key="`noquick-${item.id}`"
                :index="index"
                :item="item"
                :carts-count="item.cart_count || 0"
                :from-type="fromType"
                :showEditShoppingbag="canEditShoppingbag"
                :isLastGoods="isLastGoodsWithQs"
              />
            </template>
          </TransitionGroup>
        </template>
      </template>
    </ul>
  </div>
</template>

<script>
/* globals shem_caculate_data */
import ClientOnly from 'vue-client-only'
import Vue from 'vue'
import { useMapState, useMapGetters } from 'public/src/pages/checkout/hooks/index.js'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'

import { sortCartsByNum, filterPromitionGoods, sortTogetherBuyCart, sortLargeShipCarts } from 'public/src/pages/checkout/config/tools.js'
import useCartsBenefit from 'public/src/pages/checkout/hooks/useCartsBenefit.js'

import ShoppingBagItem from 'public/src/pages/checkout/vue_tpls/shopping_bag/shopping_bag_option/ShoopingBagItem.vue'
import ShoppingBagItemDetail from 'public/src/pages/checkout/vue_tpls/shopping_bag_item.vue'
import quickShipLabel from 'public/src/pages/checkout/page_tpls/components/atom/quickShipLabel.vue'
import ShoppingBagHeader from 'public/src/pages/checkout/vue_tpls/shopping_bag/shopping_bag_header/index.vue'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })
const { GB_cssRight } = gbCommonInfo
Vue.component('ClientOnly', ClientOnly)

export default {
  name: 'ShoppingBagOptions',
  components: {
    ShoppingBagItem,
    ShoppingBagItemDetail,
    quickShipLabel,
    ShoppingBagHeader
  },
  setup(props) {
    const { language } = useMapState(['language'])
    const { outOfStockTips, shoppingBagTipsPriority } = useMapGetters(['outOfStockTips', 'shoppingBagTipsPriority']) 
    const list = Vue.ref([])
    const benefitReportType = Vue.ref(0)
    Vue.watch(
      () => props.cartsInfo,
      (carts) => {
        const { benefitList, reportType } = useCartsBenefit({ 
          carts,
          abt: {
            outOfStockTips: outOfStockTips.value,
            shoppingBagTipsPriority: shoppingBagTipsPriority.value
          },
          language: language.value
        })
        list.value = benefitList.value
        benefitReportType.value = reportType.value
      },
      { 
        immediate: true,
        deep: true 
      }
    )
    return {
      benefitList: list,
      benefitReportType
    }
  },
  props: {
    cartsInfo: {
      type: Array,
      default () {
        return []
      }
    },
    cartsMallInfo: {
      type: Object,
      default: () => {}
    },
    quickShipInfo: {
      type: Object,
      default: () => {}
    },
    mallCode: {
      type: [String, Number],
      default: '',
    },
    fromType: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      GB_cssRight,
      quickShipCarts: [],
      noQuickShipCarts: [],
      isSendedEvent: false,
    }
  },
  computed: {
    ...mapState('checkout', [
      'language', 
      'checkout', 
      'defaultShipping', 
      'cacuMallParams', 
      'price', 
      'locals', 
      'togetherBuy',
    ]),
    ...mapGetters('checkout', [
      'iconPrefix', 
      'fsIconPrefix', 
      'isSiteMallList', 
      'showQuickShipByAbt', 
      'isShowbrTaxhint', 
      'touchRuleInfo',
      'forcedShippingByAbt',
      'canEditShoppingbag',
      'shoppingBagTypeAbt',
      'switchOnEvoluSHEIN',
      'EVOLU_SHEIN_ID',
      'chemicalsIds',
      'showArrivalPriceByAbt',
      'quickShipCheckoutStatus',
      'largeShipExtendsInfo',
      'shoppingBagBeltPriority'
    ]),
    IS_RW() {
      return this.locals?.IS_RW ?? gbCommonInfo.IS_RW
    },
    SiteUID(){
      return this.locals?.SiteUID ?? gbCommonInfo.SiteUID
    },
    quickshiLabelStyle(){
      return {
        'color': '#198055',
        'background-color': '#F3FAF6',
        'font-size': '10px',
        'icon-size': '16px',
        'border-radius': '2px',
        'border': '0.5px solid rgba(25, 128, 85, 0.20)',
        'padding': '1px 3px 1px 0'         
      }
    },
    descQuickShipTime() {
      let descQuickShipTime = ''
      if (this.quickShipCarts.length) descQuickShipTime = this.quickShipDescribe(this.quickShipInfo)
      return descQuickShipTime
    },
    shippingMethod() {
      const list = this.defaultShipping?.filter(item => item.mall_code == this.mallCode) || []
      return list?.[0]?.shipping_method
    },
    qsFreightInfo() {
      const { qs_freight_name = null, qs_freight_price = null, qs_freight_status = null, qs_freight_tips = null } = this.shippingMethod || {}
      return { qs_freight_name, qs_freight_price, qs_freight_status, qs_freight_tips }
    },
    freightChecked() {
      const mall_params = this.cacuMallParams || []
      const params = mall_params?.find(mall => mall.mall_code == this.mallCode) || {}
      // 0=不处理，1=按照qs处理，2=a按照跨境处理(只有入参为1时选中)
      const { switch_qs_flag = 0 } = params
      if (this.showQsFreight) {
        this.assignState({
          qsFreightSelected: switch_qs_flag == 1
        })
      }
      return switch_qs_flag == 1
    },
    showQsFreight() {
      return this.qsFreightInfo.qs_freight_status == 1 && this.qsMakeup != 0
    },
    qsMakeup() { // quickshipFeeSwitchAbt abt结果
      const quickshipFeeSwitchAbt = this.checkout?.results?.quickshipFeeSwitchAbt || {}
      return quickshipFeeSwitchAbt?.p?.['qsMakeup'] || 0
    },
    showTaxHint() {
      const isBrSite = !!~this.SiteUID?.indexOf('br')

      const { match_goods = [] } = this.touchRuleInfo || {}
      const touchRuleId = match_goods?.map(item => item.id) || []
      let cartInclude =  false
      this.cartsInfo.forEach(cart => {
        if(touchRuleId.includes(cart.id)) cartInclude = true
      })

      return !this.IS_RW && this.isShowbrTaxhint && isBrSite && !!this.taxHint && cartInclude
    },
    taxHint() {
      const { tax = [] } = this.price || {}
      const taxList = tax.filter(item => +item.taxPrice?.amount > 0 && item.taxType == 7) || []
      // 当前不会存在配置多条的情况，默认取第一个处理
      const taxPrice = taxList[0]?.taxPrice?.amountWithSymbol || ''
      const taxHint = this.touchRuleInfo.msg || ''

      return (!!taxList.length && taxHint) ? taxHint.replace('{br_tax_1}', taxPrice) : ''
    },
    // qs拆车算不同的商品
    isLastGoodsWithQs() {
      const listLen = this.quickShipCarts.length + this.noQuickShipCarts.length
      const noSplit = listLen == this.cartsInfo.length

      return this.isLastGoods && noSplit
    },
    isLastGoods() {
      // 是否是最后一个商品（当前结算车内最后一个，并非当前mall），最后一个商品去除赠品 ,qs拆车数据算同个商品
      const otherCarts = this.checkout.results?.carts?.carts?.filter(item => item.mall_code != this.mallCode)
      const otherCartsWithoutPromition = filterPromitionGoods(otherCarts)
      const carts = filterPromitionGoods(this.cartsInfo)

      return otherCartsWithoutPromition.length == 0 && carts.length == 1
    },
    showShoppingBagItemDetail() {
      let cartsLength = 0
      if (!this.quickShipCarts.length) {
        // 没有 qs 的商品，即只有非 qs 的商品
        cartsLength = this.noQuickShipCarts.length
      } else if(!this.noQuickShipCarts.length) {
        // 没有非 qs 的商品，即只有 qs 的商品
        cartsLength = this.quickShipCarts.length
      }
      return !this.IS_RW &&
        cartsLength === 1 && 
        this.shoppingBagTypeAbt
    }
  },
  watch: {
    cartsInfo: {
      handler(v, oldValue) {
        const itemDetailVisible = this.showShoppingBagItemDetail
        if(!oldValue) { // 初始化
          const { quickShipCarts, noQuickShipCarts } = this.initCartsList()
          this.quickShipCarts = quickShipCarts
          this.noQuickShipCarts = noQuickShipCarts
        } else {
          const { quickShipCarts, noQuickShipCarts } = this.initCartsList()
          this.quickShipCarts = this.handleCartsList(quickShipCarts, this.quickShipCarts)
          this.noQuickShipCarts = this.handleCartsList(noQuickShipCarts, this.noQuickShipCarts)

          this.handleTogetherBuyData(itemDetailVisible)
        }

        // 待修改，此处需要兼容SSR
        //存储descQuickShipTime
        this.assignState({
          descQuickShipTime: this.descQuickShipTime,
        })

        if (JSON.stringify(v || []) !== JSON.stringify(oldValue || [])) {
          this.handleBenefitReport()
        }
      },
      immediate: true,
      deep: true
    },
    descQuickShipTime: {
      handler(v) {
        if (v && typeof window !== 'undefined') {
          daEventCenter.triggerNotice({
            daId: '1-11-1-88',
            extraData: {
              quickship_tp: !this.quickShipCarts.length ? '0' : (this.quickShipCarts.length == this.cartsInfo.length ? '1' : '2')
            }
          })
        }
      },
      immediate: true
    },
    'qsFreightInfo.qs_freight_tips': {
      handler(n) {
        if (n) {
          this.assignState({
            qsFreightTips: n
          })
        }
      },
      immediate: true
    },
    'shippingMethod.transport_type': {
      handler(n, o) {
        if (n && n != o && this.showQsFreight) {
          daEventCenter.triggerNotice({
            daId: '1-11-1-102',
            extraData: {
              default_qs_freight_status: this.freightChecked ? 1 : 0,
              mall_code: this.mallCode,
              shipping_method: this.shippingMethod?.transport_type || '',
            }
          })
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.trackPr65Event(false)
  },
  methods: {
    ...mapMutations('checkout', ['assignState', 'updateState', 'changeTogetherBuy']),
    ...mapActions('checkout', ['mallCacuTotal']),
    //增加mallcode quickShip
    mallCode_quickShip(real_quick_ship){
      return `_current_dom_together_buy_mall${this.mallCode}_quick${real_quick_ship}`
    },
    template,
    handleTogetherBuyData(noAnimation) {
      if(this.togetherBuy?.addCartProducs) {
        this.quickShipCarts = sortTogetherBuyCart(this.quickShipCarts, this.togetherBuy.addCartProducs)
        this.noQuickShipCarts = sortTogetherBuyCart(this.noQuickShipCarts, this.togetherBuy.addCartProducs)
      }
      // 更新随手购浮窗数据 1.当前mall 2.当前qs
      const { quickViewCartDataMallCode, quickViewQuickShip } = this.togetherBuy || {}
      if(quickViewCartDataMallCode === this.mallCode){
        const result = !!quickViewQuickShip ? this.quickShipCarts : this.noQuickShipCarts
        this.changeTogetherBuy({
          currentCartList: [...result],
          quickViewCartData: {}
        })
        appEventCenter.$emit('together-currentCartList-change', { noAnimation })
      }
    },
    initCartsList() {
      let quickShipCarts = []
      let noQuickShipCarts = []
      // 购物袋外层按商品数量排序
      const sortedList = sortCartsByNum( JSON.parse(JSON.stringify(this.cartsInfo)))
      const fullyNewCarts = sortLargeShipCarts({ isSiteMallList: this.isSiteMallList, largeShipExtendsInfo: this.largeShipExtendsInfo, carts: sortedList })
      if(this.showQuickShipByAbt) {
        const list = this.handleQsCarts(fullyNewCarts)
        quickShipCarts = list?.filter(item => item.quick_ship && item?.over_quick_ship != 1) || []
        noQuickShipCarts = list?.filter(item => !item.quick_ship || item?.over_quick_ship == 1) || []
      } else {
        quickShipCarts = []
        noQuickShipCarts = fullyNewCarts
      }

      if(quickShipCarts.length > 5) {
        quickShipCarts[4].cart_count = quickShipCarts.length - 5
      }
      if(noQuickShipCarts.length > 5) {
        noQuickShipCarts[4].cart_count = noQuickShipCarts.length - 5
      }

      return {
        quickShipCarts,
        noQuickShipCarts
      }
    },
    scrollShoppingBag() {
      if(typeof window == 'undefined') return
      if(document.querySelector(`#shopping_bag_checkout_${this.mallCode} .shopping-bag-options__list-noshow`)) {
        document.querySelector(`#shopping_bag_checkout_${this.mallCode} .shopping-bag-options__list-noshow`).scrollLeft = 0
      }
      if(document.querySelector(`#shopping_bag_checkout_${this.mallCode} .shopping-bag-options__list-all`)) {
        document.querySelector(`#shopping_bag_checkout_${this.mallCode} .shopping-bag-options__list-all`).scrollLeft = 0
      }
      if(document.querySelector(`#shopping_bag_checkout_${this.mallCode} .shopping-bag-options__list-quick`)) {
        document.querySelector(`#shopping_bag_checkout_${this.mallCode} .shopping-bag-options__list-quick`).scrollLeft = 0
      }
      if(document.querySelector(`#shopping_bag_checkout_${this.mallCode} .shopping-bag-options__list-noquick`)) {
        document.querySelector(`#shopping_bag_checkout_${this.mallCode} .shopping-bag-options__list-noquick`).scrollLeft = 0
      }
    },
    handleCartsList(newList, list) {
      let results = []
      const delIds = []
      list.forEach(item => {
        const target = newList.find(f => f.id === item.id)
        if (!target) delIds.push(item.id)
      })
      // del
      results = list.filter(f => !delIds.includes(f.id))
      
      newList.forEach(item => {
        const target = results.find(f => f.id === item.id)
        if (!target) {
          // add
          this.scrollShoppingBag()
          results.unshift(item)

        } else {
          // update
          const findIndex = results.findIndex(f => f.id === item.id)
          results[findIndex] = item
        }
      })

      return results
    },
    // 根据qs_stock与quantity拆分qs数据（qs库存与购买数量）
    handleQsCarts(originCarts) {
      if (!originCarts.length) return []
      // 超出库存范围商品数据
      let overQsCarts = []
      const list = originCarts.map(item => {
        // 下单页字段 real_quick_ship qs_stock
        const quantity = item?.quantity || 0
        let qs_stock = item?.product?.qs_stock || 0

        if (item?.real_quick_ship != 2) return item

        let overQuantity = +quantity - +qs_stock
        // 手动增加 over_quick_ship 标识处理超出库存数据
        let overItem = Object.assign({}, item, { quantity: overQuantity, over_quick_ship: 1 })
        overQsCarts.push(overItem)

        return Object.assign({}, item, { quantity: qs_stock })
      })
      
      // 数量大于0才保留
      const mergeList = list.concat(overQsCarts)?.filter(item => +item.quantity > 0) || []
      const sortedList = sortCartsByNum(mergeList)

      return sortLargeShipCarts({ isSiteMallList: this.isSiteMallList, largeShipExtendsInfo: this.largeShipExtendsInfo, carts: sortedList })
    },
    showFreightTips(tips) {
      this.assignState({
        isShowQsFreight: true,
        qsFreightTips: tips || ''
      })
    },
    toggleQsFreight() {
      const { mall_params = [] } = shem_caculate_data || {}
      const params = mall_params.map(mall => {
        if (mall.mall_code != this.mallCode) return mall
        // 选中入参传1，未选中传2
        return Object.assign({}, mall, { switch_qs_flag: !this.freightChecked ? 1 : 2 })
      })
      
      this.assignState({
        isClickedQsFreight: true
      })
      shem_caculate_data.mall_params = params
      this.assignState({ cacuMallParams: shem_caculate_data.mall_params })


      this.mallCacuTotal({
        opt: {
          ...shem_caculate_data,
        },
      })

      daEventCenter.triggerNotice({
        daId: '1-11-1-103',
        extraData: {
          qs_freight_status: this.freightChecked ? 1 : 0,
          mall_code: this.mallCode,
          shipping_method: this.shippingMethod?.transport_type || '',
        }
      })
    },
    // quickship文案描述
    quickShipDescribe(item) {
      if(!item.desc_quick_ship_time) return ''
      let _html = item.desc_quick_ship_time || ''

      if( _html ){
        _html = _html.replace(/script/g, '').replace(/\s/g, ' ').replace(/&nbsp;/g, ' ')
        this.quickShipCheckoutStatus?.quickShipLabelText && (_html = _html.replace(/{QSname}/g, `${this.quickShipCheckoutStatus.quickShipLabelText}`)
          .replace(/\[time\]/g, `${item.quick_ship_time}`))
      }
      return _html
    },
    showQuickPop() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-89',
        extraData: {
          quickship_tp: !this.quickShipCarts.length ? '0' : (this.quickShipCarts.length == this.cartsInfo.length ? '1' : '2')
        }
      })
      this.assignState({
        isShowQuickPop: true
      })
    },
    cartNumTotal: function (carts) {
      if( !carts.length ) return ''
      let total = carts.reduce((total, item)=> {
        total = total + Number(item.quantity)
        return total
      }, 0)
      return total
    },
    cartProductTotal: function (carts) {
      if( !carts  || !carts.length ) return ''
      const total = carts.reduce((total, item) => {
        total = total + Number(item.quantity)
        return total
      }, 0)

      return total > 1 ? this.template(total, this.language.SHEIN_KEY_PWA_23258) : this.language.SHEIN_KEY_PWA_23260
    },
    clickShoppingBagBi(source, carts) {
      const storeCodeList = carts?.map(item => item.store_code) || []
      const store_code = storeCodeList.join(',') || ''
      const selfCarts = carts?.filter(item => item.business_model == 0) || []
      const is_self_num = !selfCarts.length ? '0' : (selfCarts.length == carts.length ? '1' : '0,1')
      daEventCenter.triggerNotice({
        daId: '1-11-1-87',
        extraData: {
          is_self_num,
          store_code,
          click_from: source,
        }
      })
    },
    openModal: function (source) {
      if (this.showShoppingBagItemDetail) return
      const carts = this.cartsInfo

      this.assignState({
        cartModalMallCode: this.mallCode,
        descQuickShipTime: this.descQuickShipTime,
      })
      this.updateState({
        key: 'shoppingBagModalShow', value: true
      })
      
      this.clickShoppingBagBi(source, carts)
      this. trackPr65Event(true)
    },
    // 区分shopping bag内页、外页
    trackPr65Event(isPopUp) {
      // abt控制evoluSHEIN打开，且有evoluSHEIN标签的商品才上报事件
      if(this.switchOnEvoluSHEIN) {
        let tag_info = '', isSendEvent = false
        this.cartsInfo && Array.isArray(this.cartsInfo) && this.cartsInfo.forEach(item => {
          if(item?.rules?.includes(this.EVOLU_SHEIN_ID)) {
            isSendEvent = true
            tag_info === '' ? tag_info += `${item?.product?.sku_code}${'`'}show_evolushein` : tag_info += `,${item?.product?.sku_code}${'`'}show_evolushein`
          }
        })
        if(!this.isSendedEvent && isSendEvent) {
          daEventCenter.triggerNotice({
            daId: '1-11-1-237',
            extraData: {
              postion: isPopUp ? 'popup' : 'page',
              tag_info
            }
          })
          if(isPopUp) {
            this.isSendedEvent = true
          }
        }
      }
      this.cartsInfo && Array.isArray(this.cartsInfo) && this.cartsInfo.forEach(item => {
        if(this.chemicalsIds.includes(item.product.goods_sn)) {
          daEventCenter.triggerNotice({
            daId: '1-19-1-75',
            extraData: {
              sku_code: item?.product?.goods_sn
            }
          })
        }
      })
    },
    clickTaxHint() {
      // 结算车内最后一个商品无点击行为（非mall下）
      if(this.isLastGoods) return

      // 修改购物车状态接口无法正常获取下单页券赠品勾选状态，弹窗内不展示该商品
      const carts = this.cartsInfo.filter(item => item.promotion_type_id != '1000')
      this.assignState({
        taxCartsInfo: {
          list: carts,
          mallCode: this.mallCode
        },
      })
      this.updateState({
        key: 'showBagTax', value: true
      })
    },
    handleBenefitReport() {
      if (typeof window === 'undefined') return
      this.$nextTick(() => {
        let ref = this.$refs.shoppingBagItemRef
        if (this.showShoppingBagItemDetail) ref = this.$refs.shoppingBagItemDetailRef
        if (!ref) return
        if (!Array.isArray(ref)) ref = [ref]
        const beltList = ref?.map(item => {
          const beltEle = item.$el.querySelector('.shopping-bag-item__tips')
          // 只上报 abt 有配置的腰带类型
          return this.shoppingBagBeltPriority.includes(beltEle?.dataset?.type) ? beltEle?.dataset?.type : '-'
        })
        const headlineTypeList = this.benefitList.map(item => (
          `${item.type}\`${item.quantity}`
        ))

        let extraData = {
          product_tips: beltList.join(',')
        }

        if (headlineTypeList.length) {
          extraData = { 
            ...extraData, 
            headline_type: headlineTypeList.join(',') 
          }
        }

        daEventCenter.triggerNotice({
          daId: '1-11-1-201',
          extraData
        })
         
        daEventCenter.triggerNotice({
          daId: '1-11-1-172',
          extraData: {
            scenes: 'shoppingbag_headline_tips',
            type: this.benefitReportType
          }
        })

        const hasBelt = ref?.some(item => {
          const beltEle = item.$el.querySelector('.shopping-bag-item__tips')
          return Boolean(beltEle?.dataset?.beltAvailable)
        })
        const hasAbt = !!this.shoppingBagBeltPriority.length
        let type
        if (hasBelt) {
          type = hasAbt ? 3 : 4
        } else {
          type = hasAbt ? 1 : 2
        }
        daEventCenter.triggerNotice({
          daId: '1-11-1-172',
          extraData: {
            scenes: 'shoppingbag_product_tips',
            type
          }
        })
      })
    },
  }
}
</script>

<style lang="less">
.cart-bag-wrap {
  display: flex;
  justify-content: space-between;
  padding: 24/75rem;
  .padding-l(0);
  padding-bottom: 0;
  padding-top: 20/75rem;

  .store-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .padding-r(16/75rem);
    font-weight: 700;
    & when (@IS_RW) {
			font-weight: 400;
      font-family: Adieu-Regular, Adieu;
		}

    max-width: 80%;
		display: inline-block;
		vertical-align: middle;
  }
  position: relative;
  .bag-total-num{
    .font-dpr(24px);
    color: #222;
    font-weight: normal;
    white-space: nowrap;

    i {
      color: #767676;
    }
  }
}
.store-title-mall {
  .font-dpr(32px);
}
.bag-quick-wrap {
  margin-bottom: 12/75rem;
  margin-top: 20/75rem;
  .padding-r(24/75rem);
}
.bag-quick-mt{
  margin-top: -3/75rem;
}
.quickShip-tage{
  display: flex;
  span{
    margin-left: 8/75rem;
    font-family: "SF Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 590;
    line-height: normal;
  }
}
.bag-quick-ship {
  .font-dpr(24px);
  color: @sui_color_gray_dark1;
}
.bag-quick-time {
  white-space: initial;
  .font-dpr(24px);
  color: @sui_color_gray_dark1;
  i {
    color: @sui_color_gray_light1;
  }
}
.qs__freight-wrap {
  margin: 12/75rem 0 0 0;
  display: flex;
  align-items: flex-start;

  .qs__freight-name {
    color: @sui_color_main;
    font-weight: 700;
    i {
      .margin-l(8/75rem);
      color: @sui_color_gray_light2;
      font-weight: normal;
    }
  }
  .bag-quick-time {
    margin-top: 8/75rem;
  }
}
.qs__freight-content {
  font-size: 12px;
  display: inline-block;
}
.bg-effiency {
  background: url(/pwa_dist/images/checkout/shopping_mall_title-87f1df1cc5.png);
  background-size: 100% 100%;
  .margin-l(-24/75rem);
  padding: 16/75rem 20/75rem;
}
.bg-ar-effiency {
  background: url(/pwa_dist/images/checkout/shopping_mall_title_ar-202a0604e7.png);
  background-size: 100% 100%;
  .margin-l(-24/75rem);
  padding: 16/75rem 20/75rem;
}

.shopping-bag-content {
  &.shopping-bag-content__edit {
    .shopping-bag-options__list {
      position: relative;
      align-items: stretch;
      .padding-r(0);
      overflow-x: scroll;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    }
  }
  padding-top: 12/75rem;
  white-space: nowrap;
  
  li:last-child{
    .margin-r(0);
  }

  .shopping-bag-options__item-detail {
    border: none;
    padding-top: 0;
    padding-bottom: 0; 
    .item-content {
      padding-top: 0;
    }
  }
}
.shopping-bag-options__wrap {
  &:not(:has(.shopping-bag-content__edit)) {
    .shopping-bag-dynamic__last {
      /* stylelint-disable-next-line selector-max-specificity */
      .shopping-bag-options__item-wrap:last-child{
        width: 0;
        opacity: 0;
      }
    }
  }
}

.shopping-bag__hint {
  margin: 20/75rem 24/75rem 8/75rem;
  .margin-l(0);
  padding: 20/75rem;
  .padding-r(0);
}

.shopping-bag-dynamic__body {
  .shopping-bag-options__item-wrap:first-child{
    width: 0;
    opacity: 0;
  }

  .goodsli-out-mask {
    opacity: 0;
  }
}
.shopping-bag-dynamic__body-mask {
  .goodsli-out-mask {
    opacity: 1;
  }
}

.single-bag .shopping-bag-content {
  padding-bottom: 20/75rem;
}

.shopping-bag-options__list {
  display: flex;
  .padding-r(24/75rem);
}

.fadeedit-move,
.fadeedit-enter-active,
.fadeedit-leave-active {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}

.fadeedit-leave-active {
  position: absolute;
}
</style>
