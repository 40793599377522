<template>
  <div class="checkout-activity-popover__tips">
    <div
      v-if="bubbleCopyWritingByAbt"
      class="activity-popover-content"
    >
      <div 
        ref="activityPopoverTipsContentRef"
        class="activity-popover-content__wrapper"
      >
        <span
          ref="activityPopoverTipsDescRef"
          class="activity-popover-content__tips-content"
        >
          <Icon
            v-if="icon.name"
            class="activity-popover-content__tips-icon"
            :name="icon.name"
            :color="icon.color"
            :size="icon.size"
          />
          <span
            ref="descriptionRef"
            class="activity-popover-content__desc-text"
            v-html="description"
          ></span>
        </span>
        <div
          v-if="timeTips"
          ref="timedownRef"
          class="activity-popover-content__tips-timedown"
          v-html="timeTips"
        >
        </div>
      </div>
    </div>
    <div
      v-else
      class="activity-popover-content"
    >
      <div 
        ref="activityPopoverTipsContentRef"
        class="activity-popover-content__wrapper"
        :class="[!!timeTips ? 'two-rows-ellipsis' : 'three-rows-ellipsis']"
      >
        <Icon
          v-if="icon.name"
          class="activity-popover-content__tips-icon"
          :name="icon.name"
          :color="icon.color"
          :size="icon.size"
        />
        <span
          ref="descriptionRef"
          class="activity-popover-content__desc-text"
          v-html="description"
        ></span>
      </div>
      <div
        v-if="timeTips"
        class="activity-popover-content__timewdown"
      >
        {{ timeTips }}
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue2'

import { template, CountDown } from '@shein/common-function'

export default {
  name: 'ActivityTips',
  components: {
    Icon,
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    icon: {
      type: Object,
      default: () => {}
    },
    description: {
      type: String,
      default: ''
    },
    timedown: {
      type: [Number, String],
      default: 0
    },
    bubbleCopyWritingByAbt: {
      type: Boolean,
      default: false
    },
    isShowNewActivityPopverLayoutByAbt: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timer: null,
    }
  },
  computed: {
    // 倒计时文案
    timeTips () {
      if (!this.timer) return ''
      const { H, M, S } = this.timer?.timeObj || {}
      if (this.bubbleCopyWritingByAbt) {
        return `<span class="time">${H}</span> : <span class="time">${M}</span> : <span class="time">${S}</span>`
      }
      return this.template(
        `${H}:${M}:${S}`,
        this.language.SHEIN_KEY_PWA_29054
      )
    },
  },
  watch: {
    timedown: {
      handler(newVal, oldVal) {
        this.initTimer()
        if (newVal !== oldVal) {
          this.handleDescriptionFontSize()
        }
      },
      immediate: true
    },
    description: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.handleDescriptionFontSize()
        }
      }
    }
  },
  methods: {
    template,
    initTimer() {
      let endTime = +this.timedown
      if (!endTime) return
      if (endTime.toString().length < 13) endTime *= 1000
      const newDate = new Date().getTime()
      const countdown = endTime - newDate
      if (countdown <= 0) return
      this.timer = new CountDown()
      this.timer.start({
        seconds: Math.floor(countdown / 1000),
      })
    },
    handleFontSize(width) {
      if (this.$refs?.activityPopoverTipsContentRef) {
        Object.assign(this.$refs.activityPopoverTipsContentRef.style, {
          whiteSpace: 'normal',
          width
        })

        if (this.bubbleCopyWritingByAbt) {
          // 文本+倒计时超过3行的时候，需要缩小字号为10px，三行省略，但是倒计时单独一行
          if (this.$refs.activityPopoverTipsContentRef?.scrollHeight > 42) {
            this.$refs.descriptionRef.style.fontSize = '10px'
            this.$refs.activityPopoverTipsDescRef.className = 'three-rows-ellipsis'
          
            if (this.$refs?.timedownRef) {
              this.$refs.timedownRef.style.display = 'block'
            }
          }
        } else {
          // 超过一定高度，缩小字号
          if(this.$refs.activityPopoverTipsContentRef?.scrollHeight > 27) {
            this.$refs.descriptionRef.style.fontSize = '10px'
          } else {
            this.$refs.descriptionRef.style.fontSize = '12px'
          }
        }
      }
    },
    /**
     * @description: 用于处理descript的字号
     * @return {*}
     */    
    handleDescriptionFontSize() {
      if(typeof window == 'undefined') return
      this.$nextTick(() => {
        setTimeout(()=>{
          this.$refs.activityPopoverTipsContentRef.style.whiteSpace = 'nowrap'

          const rootFontSize = window.getComputedStyle(document.documentElement).fontSize.replace('px', '') // 根元素的px大小
          const rightContentWidth = this.$refs.activityPopoverTipsContentRef?.offsetWidth || 0
          const rowGapDistance = ((this.isShowNewActivityPopverLayoutByAbt ? 26 : 32) / 37.5) * rootFontSize // 26 32 是375设计稿所有的横向间距，包括气泡内边距、图片和文案的间距
          const leftImgWrapperWidth = document.querySelector('.j-activity-left')?.offsetWidth || 0
          let maxWidth = 0
          
          if (this.isShowNewActivityPopverLayoutByAbt) {
            // 300 表示设计稿的最大宽度
            if (rowGapDistance + leftImgWrapperWidth + rightContentWidth > 300) {
              maxWidth = 192 // 设计稿的右侧最大内容宽度
              if (rightContentWidth >= maxWidth) {
                this.handleFontSize(`${maxWidth / rootFontSize}rem`)
              }
            }
          } else {
            // 设计稿最大宽度260整个气泡的最大宽度，这里是进行像素计算，这块需要去掉小数，因为上面的offsetWidth取得是整数，否则下面在个别机型比较的时候总会有误差
            maxWidth = (260 - rowGapDistance - leftImgWrapperWidth)?.toFixed(0)
            if (rightContentWidth >= maxWidth) {
              this.handleFontSize(`${maxWidth / rootFontSize}rem`)    
            }
          }
        }, 0)
      })
    }
  }
}
</script>

<style lang="less">
.checkout-activity-popover__tips {
  .margin-l(16/75rem);
}
.activity-popover-content {
  line-height: 1;
  &__tips-icon {
    .margin-r(4/75rem);
    margin-top: -10/75rem;
  }
  &__desc-text {
    .font-dpr(24px);
    color: @sui_color_white;
    em {
      font-weight: 700;
      color: @sui_color_discount;
    }
    .prime {
      color: @sui_color_club_rosegold_dark3;
    }
    .flash {
      color: @sui_color_flash;
    }
    .prime-club {
      font-weight: 700;
      color: #C96E3F;
    }
  }
  &__tips-timedown {
    display: inline-block;
    color: var(---sui_color_discount, #FA6338);
    margin-left: 12/75rem;
    .time {
      display: inline-block;
      width: 28/75rem;
      height: 28/75rem;
      padding: 2/75rem;
      text-align: center;
      border-radius: 2/75rem;
      background: #FA6338;
      font-weight: 500;
      .font-dpr(20px);
      line-height: 12px;
      color: var(---sui_color_white, #FFF);
    }
  }
  &__timewdown {
    .font-dpr(20px);
    color: @sui_color_guide;
    margin-top: 4/75rem;
  }
}
.two-rows-ellipsis {
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.three-rows-ellipsis {
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
</style>
