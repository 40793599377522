<template>
  <div
    v-enterkey
    class="mcheo-addr default mshe-flexbetween mshe-wrapper j-go-address-list"
    role="button"
    @click="clickAddressList"
  >
    <div
      v-if="checkout.default_address"
      tabindex="0"
      class="item j-default-address address-content"
    >
      <div class="name-phone">
        <div class="name">
          {{ addressDetail }}
        </div>
        <div class="phone">
          {{ checkout.default_address.tel }}
        </div>
      </div>
      <p v-if="checkout.default_address.countryId == '108'">
        〒 {{ checkout.default_address.postcode }}
      </p>
      <p v-else>
        {{ checkout.default_address.address1 }}
        {{ checkout.default_address.address2 }}
        <template v-if="checkout.default_address.countryId == '209' && checkout.default_address.type == '3'">
          ({{ language.SHEIN_KEY_PWA_17662 }})
        </template>
      </p>

      <p 
        v-if="checkout.default_address.countryId == '108'" 
        :data-country="checkout.default_address.countryId"
      >
        {{ checkout.default_address.countryName }}
        {{ checkout.default_address.state }}
        {{ checkout.default_address.city }}
        {{ checkout.default_address.district != 0? checkout.default_address.district: '' }}
        {{ checkout.default_address.address1 }}
        {{ checkout.default_address.address2 }}
      </p>
      <p 
        v-else 
        :data-country="checkout.default_address.countryId"
      >
        {{ checkout.default_address.district != 0? checkout.default_address.district: '' }}{{ secondLanguage.district ? `/${ secondLanguage.district }` : '' }}
        {{ [checkout.default_address.city, secondLanguage.city].filter(Boolean).join('/') }}
        {{ [checkout.default_address.state, secondLanguage.state].filter(Boolean).join('/') }}
        {{ checkout.default_address.countryName }}
        {{ checkout.default_address.postcode }}
      </p>
      <div
        v-if="sensitiveNote"
        class="mcheo-addr__tips"
      >
        {{ sensitiveNote }}
      </div>
    </div>
    <i
      class="sui_icon_more_right_16px"
      :class="[fsIconPrefix]"
    ></i>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'AddressHome',
  props: {
    addressDetail: {
      type: String,
      default: ''
    },
    secondLanguage: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    ...mapState('checkout', ['language', 'checkout']),
    ...mapGetters('checkout', ['fsIconPrefix']),
    sensitiveNote () {
      const sensitiveRuleVoList = this.checkout?.default_address?.sensitiveRuleVoList || []
      if (!sensitiveRuleVoList.length) {
        return ''
      }
      return sensitiveRuleVoList?.[0]?.rule_content || ''
    }
  },
  methods: {
    clickAddressList() {
      this.$emit('clickAddressList')
    },
  }
}
</script>

<style lang="less" scoped>
.item{
  padding: 0;
  .text-overflow();
  .name-phone{
    display: flex;
    line-height: 38/@w2-375rem;
    font-size: 28/@w2-375rem;
    margin-bottom: 8/75rem;
    .name{
      .margin-r(16/@w2-375rem);
      font-weight: 600;
      .text-overflow();
    }
    .phone{
      font-size: 24/@w2-375rem;
      color: #767676;
      white-space: nowrap;
      overflow: unset;
      text-overflow: unset;
    }
  }
  p{
    line-height: 34/@w2-375rem;
    font-size: 28/@w2-375rem;
    margin-bottom: 8/@w2-375rem;
    color: #222;
    .text-overflow();
    .padding-r(24/@w2-375rem);
    & when (@IS_RW) {
      color: #333;
    }
  }
}
.address-home-wrap {
  .address-content {
    p {
      .padding-r(12/75rem);
    }
  }
}
.mcheo-addr {
  &__tips {
    padding-top: .106rem;
    color: @sui_color_unusual;
    font-size: 12px;
    white-space: normal;
    word-break: break-all;
  }
}
</style>
