import Vue from 'vue'
import Vuex from 'vuex'

const getState = () => ({
  abtInfo: {},
  language: {},
  cardConfig: {},
  isFullScreen: false,
  progressDataPromise: null,
  addSize: 0,
})

Vue.use(Vuex)
const store = new Vuex.Store({
  state: getState(),
  getters: {
  },
  mutations: {
    setAbtInfo(state, payload) {
      state.abtInfo = payload
    },
    setLanguage(state, payload) {
      state.language = payload
    },
    setCardConfig(state, payload) {
      state.cardConfig = payload
    },
    setIsFullScreen(state, payload) {
      state.isFullScreen = payload
    },
    setProgressDataPromise(state, payload) {
      state.progressDataPromise = payload
    },
    resetState(state) {
      const defaultState = getState()
      Object.keys(defaultState).forEach(key => {
        state[key] = defaultState[key]
      })
    },
    setAddSize(state, payload) {
      state.addSize = payload
    },
  },
})

export default store
