<template>
  <li
    v-enterkey
    v-expose="{
      id: '1-11-1-204',
      data: {
        is_choosed: !!applyCouponsSuccess.length ? 1 : 0,
        is_folded: isFolded,
      }
    }"
    class="use-coupon checkout-coupon__entry"
    tabindex="0"
    @click="goCouponPage"
  >
    <div class="stand-left">
      <div class="c-form-group">
        <div class="input-icon input-coupon">
          <span
            style="white-space: nowrap;"
            :class="{'font': IS_RW}"
            class="input-icon-cell"
          >
            {{ language.SHEIN_KEY_PWA_15095 }}:
            <span
              v-if="showCouponHintByAbt && applyCouponsSuccess.length > 0"
              class="coupon-prime-tips"
              v-html="usedTips"
            ></span>
          </span>
          <div class="use-coupon-right input-icon-cell">
            <span
              v-if="isShowCouponError"
              class="use-red"
            >
              {{ language.SHEIN_KEY_PWA_17965 }}
            </span>
            <template v-else-if="price.coupon && coupon.costShow">
              <div 
                v-for="item in applyCouponList" 
                :key="item"
                class="use-coupon-item"
                :class="{
                  'use-free-tip__new': item == 1,
                  'use-red': item == 2,
                }"
              >
                <template v-if="item == 1">
                  <span
                    v-if="primeCouponCount > 0 && isFreePrice"
                    class="coupon-prime"
                  >
                    <primeVipIcon />
                    {{ language.SHEIN_KEY_PWA_15807 }}
                  </span>
                  <span v-else>
                    {{ language.SHEIN_KEY_PWA_15807 }}
                  </span>
                </template>
                <code
                  v-else-if="item == 2"
                  style="white-space: nowrap;"
                >
                  -{{ checkCouponPrice }}
                </code>
              </div>
            </template>
            <template v-else-if="couponAvaliableTip">
              <span class="use-coupon-available">
                {{ couponAvaliableTip }}
                <em class="available-dot j-available-dot"></em>
              </span>
            </template>
            <i
              class="sui_icon_more_right_16px mcheo-icon-grep"
              :class="[fsIconPrefix]"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="couponShipPriceDiff.length"
      class="price-coupon-diff"
    >
      <div 
        v-for="item in couponShipPriceDiff" 
        :key="item.mall_code"
        class="price-diff-tip"
      >
        <strong v-if="isSiteMallList">{{ item.mall_name }}: </strong>
        <span v-html="freeShipDiffTips(item)"></span>
      </div>
    </div>

    <pick-coupon-gift
      v-if="couponGift"
      @pickgift="pickGift"
    />
  </li>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'

import analysisCoupon from 'public/src/pages/checkout/components/analysis_coupon.js'

import primeVipIcon from 'public/src/pages/common/prime/primeVipIcon.vue'
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'


export default {
  name: 'AssetsCoupon',
  components: {
    primeVipIcon,
    PickCouponGift: () => import('../coupon_gift/PickCouponGift.vue'),
  },
  props: {
    checkCouponPrice: {
      type: String,
      default: ''
    },
    isSiteMallList: {
      type: Boolean,
      default: false
    },
    isFolded: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapState('checkout', [
      'checkout',
      'language',
      'coupon',
      'price',
      'isShowCouponError',
      'couponGift',
      'couponAvaliableTip',
      'locals',
      'checkoutAbtInfo',
      'freeShipTip',
      'usableCouponList'
    ]),
    ...mapGetters('checkout', [
      'isFreePrice',
      'fsIconPrefix',
      'showNewCouponLanguage'
    ]),
    IS_RW(){
      return this.locals?.IS_RW || gbCommonInfo?.IS_RW
    },
    showCouponHintByAbt(){
      return this.checkoutAbtInfo?.usedCouponHint?.param == 'concealUsedCouponHint' ? false : true
    },
    applyCouponsSuccess() {
      return this.coupon?.applyCouponsSuccess || []
    },
    applyCouponList () {
      const applyCouponArr = []
      this.coupon.applyCouponsSuccess.forEach(item => {
        if ([5, 6, 9].includes(+item.applyFor) && !applyCouponArr.includes(1) && this.isFreePrice) {
          applyCouponArr.push(1)
        } else if (this.checkCouponPrice && !applyCouponArr.includes(2)) {
          applyCouponArr.push(2)
        }
      })
      // 金额在免邮下方展示 
      if (applyCouponArr.length) applyCouponArr.sort((a, b) => b - a)
      return applyCouponArr
    },
    primeCouponCount() {
      const applyCouponsSuccess = this.coupon?.applyCouponsSuccess || []
      const primeCoupon = applyCouponsSuccess.filter(item => item.applyFor == 9 && item.typeId == 53) || []
      return primeCoupon.length || 0
    },
    couponShipPriceDiff () {
      const { coupon, other, otherType } = this.freeShipTip
      let newShipList = []
      if (coupon.length) newShipList = coupon
      if (otherType == 'coupon' && other.length) newShipList = newShipList.concat(other)
      return newShipList
    },
    usedTips() {
      if (this.showNewCouponLanguage && this.coupon.isAutoUse) return this.language.SHEIN_KEY_PWA_31496
      return this.template(
        this.applyCouponsSuccess.length, 
        this.language.SHEIN_KEY_PWA_22735
      )
    }
  },
  methods: {
    ...mapMutations('checkout', ['changeParamsStatus']),
    template,
    freeShipDiffTips (item) {
      const { not_free_shipping_tips, not_free_shipping_diff } = item
      let tips = ''
      switch (not_free_shipping_tips){
        case 1:
          tips = this.language.SHEIN_KEY_PWA_16086   // coupon
          break
        case 2:
          tips = this.language.SHEIN_KEY_PWA_16087  // point
          break
        case 3:
          tips = this.language.SHEIN_KEY_PWA_16106 // both
          break
        case 4:
          tips = this.language.SHEIN_KEY_PWA_16105  // or
          break
      }

      typeof window != 'undefined' && analysisCoupon.ApplyCouponNotFreeShipping()

      return tips.replace('{money}', '<em class="tip-red">' + not_free_shipping_diff.amountWithSymbol + '</em>')
    },
    goCouponPage() {
      this.changeParamsStatus({ type: 'pageStatus', params: { chooseCouponDrawer: true } })
      $('.j-available-dot').addClass('mshe-hide')

      daEventCenter.triggerNotice({
        daId: '1-11-1-131'
      })

      const { applyCouponsSuccess = [] } = this.coupon || {}
      daEventCenter.triggerNotice({
        daId: '1-11-1-185',
        extraData: {
          available_coupon: !!this.usableCouponList.length ? 1 : 0,
          used_coupon: applyCouponsSuccess?.length ? 
            applyCouponsSuccess?.map(coupon => coupon.couponCode).join(',') : 
            '-',
          is_choosed: !!this.coupon.applyCouponsSuccess?.length ? 1 : 0,
          is_folded: this.isFolded,
        }
      })
    },
    pickGift() {
      this.changeParamsStatus({ type: 'pageStatus', params: { couponGiftAddItem: true } })
    },
  }
}
</script>

<style lang="less" scoped>
.checkout-coupon__entry {
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.use-coupon-right {
  .padding-l(20/75rem);
}
.price-coupon-diff {
  margin-top: 12/75rem;
  background: @sui_color_prompt_warning;
  .price-diff-tip {
    background: @sui_color_prompt_warning;
  }
}
.use-free-tip__new {
  color: @sui_color_discount;
  display: inline-block;
  .font-dpr(28px);
  font-weight: 700;
}
.coupon-prime-tips {
  white-space: initial;
  color: @sui_color_gray_dark3;
  .font-dpr(24px);
}

.use-coupon-available{
  color: @sui_color_gray_dark3;
  display: inline-flex;
  align-items: center;
  .available-dot{
    display: inline-block;
    background: @sui_color_guide;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    .margin-l(8/75rem);
  }
}
</style>
