var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '1-11-1-209',
    data: _setup.shippingAddItemAnalysis
  }),expression:"{\n    id: '1-11-1-209',\n    data: shippingAddItemAnalysis\n  }"},{name:"tap",rawName:"v-tap",value:({
    id: '1-11-1-210',
    data: _setup.shippingAddItemAnalysis
  }),expression:"{\n    id: '1-11-1-210',\n    data: shippingAddItemAnalysis\n  }"}],staticClass:"shipping-method-item__add",on:{"click":function($event){$event.stopPropagation();return _setup.handleAddFree.apply(null, arguments)}}},[_c('div',{staticClass:"shipping-method-item__add-desc",domProps:{"innerHTML":_vm._s(_setup.hints)}}),_vm._v(" "),_c('span',{staticClass:"shipping-method-item__add-btn"},[_vm._v("\n    "+_vm._s(_setup.language.SHEIN_KEY_PWA_29778)+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }