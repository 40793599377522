import { computed, ref, watch, onMounted } from 'vue'
import { useStore } from 'public/src/pages/checkout/hooks/index.js'
import schttp from 'public/src/services/schttp'

const isInstallmentPayment = (payment) => {
  return payment?.code === 'routepay-cardinstallment' || 
    payment?.payment_type === 5
}

const isFreeInstallment = (installment) => {
  return installment?.installment_fee_numeric <= 0
}

// const isHomogenizationPayment = ({ payment, homogenization }, isPaymentHomogenizationShow) => {
//   const { sub_cate_code_list = [] } = homogenization
//   return isPaymentHomogenizationShow && sub_cate_code_list?.includes(payment.code)
// }

export const usePaymentBenefit = () => {
  let cacheParams = {
    orderAmount: undefined,
    orderCurrency: undefined,
    shipCountry: undefined
  }
  const isMounted = ref(false)

  const isLoading = ref(true)

  const store = useStore()

  const cardInstallmentInfo = ref({})

  const benefit_installmentInfo = ref({})

  const installmentInfoCache = ref({})

  const allCheckoutData = computed(() => {
    return store?.state?.checkout
  })

  // watch(benefit_installmentInfo, (val) => { console.log('benefit_installmentInfo', val) })

  const checkout = computed(() => {
    return allCheckoutData.value?.checkout
  })

  const status = computed(() => {
    return allCheckoutData.value?.status
  })

  // 支付方式
  const paymentMethods = computed(() => {
    return checkout.value?.results?.paymentMethods || []
  })

  // 地址信息
  const addressValue = computed(() => {
    return checkout.value?.default_address?.value
  })

  // 计价信息
  const priceValue = computed(() => {
    return checkout.value?.mall_caculate_info
  })

  // bnpl开关
  const benefitTipShowBNPL = computed(() => {
    return checkout.value?.results?.benefitTipShowBNPL
  })

  // 当前支付方式
  const currentPayment = computed(() => {
    return paymentMethods.value.find(
      item => item.code === status.value?.cardPay
    )
  })

  // 用于展示的信息
  const recommendPaymentInfo = computed(() => {
    // console.log('isLoading', isLoading.value, 'currentPayment is installment', isInstallmentPayment(currentPayment.value))
    if (isLoading.value) return null

    if (isInstallmentPayment(currentPayment.value)) return null
    const cardInstallment = []
    const otherInstallment = []
    const allInstallmentInfo = benefit_installmentInfo.value?.front_list_items || {}
    paymentMethods.value.forEach(item => {
      if (item.code === 'routepay-cardinstallment' && cardInstallmentInfo.value?.stage > 1) {
        cardInstallment.push(item)
      } else if (benefitTipShowBNPL.value && item.payment_type === 5 && allInstallmentInfo?.[item.code]?.stage > 1) {
        otherInstallment.push(item)
      }
    })

    // console.log('benefitTipShowBNPL', benefitTipShowBNPL.value)

    // console.log('cardInstallment', cardInstallment, 'otherInstallment', otherInstallment)

    if (cardInstallment.length > 0) {
      // 卡分期只有路由卡分期一种
      const paymentInfo = cardInstallment[0]
      const cardToKenInfo = paymentInfo?.card_token
      const isTokenPay = paymentInfo?.card_token_list?.length > 0
      return {
        isTokenPay,
        cardToKenInfo,
        logo_list: paymentInfo?.card_logo_list,
        paymentInfo,
        ...cardInstallmentInfo.value
      }
    } else if (otherInstallment.length > 0) {
      const signUpPayment = otherInstallment.filter(item => item.paymentSignUp?.length > 0)
      if (signUpPayment.length > 0) {
        const payment = signUpPayment[0]
        return {
          isFreeInstallment: false,
          isTokenPay: false,
          cardToKenInfo: {},
          paymentInfo: payment,
          logo_list: payment?.logo_url,
          stage: allInstallmentInfo?.[payment.code]?.stage,
          amountPerTerm: allInstallmentInfo?.[payment.code]?.place_order_period_price?.amountWithSymbol
        }
      } else {
        const payment = otherInstallment[0]
        return {
          isFreeInstallment: false,
          isTokenPay: false,
          cardToKenInfo: {},
          paymentInfo: payment,
          logo_list: payment?.logo_url,
          stage: allInstallmentInfo?.[payment.code]?.stage,
          amountPerTerm: allInstallmentInfo?.[payment.code]?.place_order_period_price?.amountWithSymbol
        }
      }
    } else {
      return null
    }
  })

  const initPaymentBenefit = async () => {
    const shipCountry = addressValue.value
    const orderAmount = priceValue.value?.grandTotalPrice?.amount
    const orderCurrency = priceValue.value?.orderCurrency?.code
    // console.log('initPaymentBenefit params:', shipCountry, orderCurrency, orderAmount)

    const cardInstallment = paymentMethods.value.find(item => item.code === 'routepay-cardinstallment')
    // 支付方式包含卡分期
    if (cardInstallment) {
      // 参数没有改变
      if (
        cacheParams.shipCountry === shipCountry &&
        cacheParams.orderCurrency === orderCurrency &&
        cacheParams.orderAmount === orderAmount
      ) {
        return
      }
      
      cacheParams.shipCountry = shipCountry
      cacheParams.orderCurrency = orderCurrency
      cacheParams.orderAmount = orderAmount

      const cacheKey = shipCountry + '_' + orderCurrency + '_' + orderAmount + '_routepay-cardinstallment'

      if (installmentInfoCache.value?.[cacheKey]) {
        cardInstallmentInfo.value = installmentInfoCache.value?.[cacheKey]
        isLoading.value = false
        return
      }

      const res = 
        await getCardInstallmentInfo({
          countryCode: shipCountry,
          orderCurrency,
          orderAmount,
          paymentCode: 'routepay-cardinstallment'
        }, cacheKey)
      // turn res into object
      cardInstallmentInfo.value = res?.installmentInfo || {}
      // console.log('cardInstallmentInfo', cardInstallmentInfo.value)

      isLoading.value = false
      
    } else if (benefitTipShowBNPL.value) {
      await getInstallmentInfo({
        shipCountry,
        orderCurrency,
        orderAmount
      })
    } else {
      isLoading.value = false
    }
  }

  const getCardInstallmentInfo = async ({
    countryCode,
    orderCurrency,
    orderAmount,
    paymentCode
  }, cacheKey) => {
    const res = await schttp({
      url: '/api/checkout/installmentInfo/get',
      method: 'get',
      params: {
        countryCode,
        orderCurrency,
        orderAmount,
        paymentCode
      }
    })

    let { installments = [] } = res?.info || {}
    let amountPerTerm = orderAmount
    let stage = 1
    
    const freeFeeInstallment = installments.filter(item => isFreeInstallment(item) && item.stage_num > 1)
    if (freeFeeInstallment.length > 0) {
      installments = freeFeeInstallment
    }

    // 找到期数最大的
    installments.forEach(item => {
      if (item.stage_num > stage) {
        stage = item.stage_num
        amountPerTerm = item.installment_amount
      }
    })

    const installmentInfo = {
      isFreeInstallment: freeFeeInstallment.length > 0,
      stage,
      amountPerTerm
    }
    
    installmentInfoCache.value[cacheKey] = installmentInfo || {}

    return {
      code: paymentCode,
      originData: res?.info || {},
      installmentInfo
    }
  }

  const resetInstallmentInfoData = () => {
    benefit_installmentInfo.value = {}
    isLoading.value = false
  }
  const setInstallmentInfo = (res, cacheKey) => {
    if (res && res.code == 0) {
      if (
        res.info &&
        res.info.front_show_list &&
        res.info.front_show_list.length
      ) {
        let front_list_items = {}
        res.info.front_show_list.forEach(item => {
          front_list_items[item.payment_code] = item
        })
        res.info.front_list_items = front_list_items
      }
  
      benefit_installmentInfo.value = res?.info || {}
      installmentInfoCache.value[cacheKey] = res.info || {}
      isLoading.value = false
    } else {
      benefit_installmentInfo.value = {}
      isLoading.value = false
    }
  }
  function getInstallmentInfo({ shipCountry, orderCurrency, orderAmount } = {}) {
    let data = {
      country: shipCountry,
      amount: orderAmount,
      currency: orderCurrency
    }

    const cacheKey = data.country + '_' + data.currency + '_' + data.amount
    if (installmentInfoCache.value?.[cacheKey]) {
      benefit_installmentInfo.value = installmentInfoCache.value?.[cacheKey]
      isLoading.value = false
    } else {   
      schttp({
        method: 'POST',
        url: '/api/checkout/installmentDetail/get',
        data
      })
        .then(res => {
          setInstallmentInfo(res, cacheKey)
        })
        .catch(() => {
          resetInstallmentInfoData()
        })
    }
  }

  onMounted(() => {
    isMounted.value = true
    if (paymentMethods.value.length) {
      initPaymentBenefit()
    }
  })

  // watch(recommendPaymentInfo, (val) => { console.log('recommendPaymentInfo', val) })

  watch(
    [priceValue, addressValue, currentPayment],
    () => {
      // console.log('checkout changed', checkout.value)
      if (isMounted.value && paymentMethods.value.length) {
        initPaymentBenefit()
      }
    },
    { immediate: true }
  )



  return {
    isLoading,
    initPaymentBenefit,
    recommendPaymentInfo
  }
}
