<template>
  <li
    v-enterkey
    v-expose="{
      id: '1-11-1-197',
      data: {
        is_choosed: isShowGifcardAmount ? 1 : 0,
        is_folded: isFolded,
      }
    }"
    class="mshe-flexbetween giftcard"
    tabindex="0"
    @click="goGiftCardPage"
  >
    <div class="stand-left">
      <div class="c-form-group">
        <div class="input-icon">
          <span
            style="white-space: nowrap;vertical-align: middle;"
            :class="{'font': IS_RW}"
            class="input-icon-cell"
          >
            {{ language.SHEIN_KEY_PWA_15846 }}:
            <span
              v-if="isAllStore && IS_RW"
              class="wallet-self"
            >
              {{ SiteUID.endsWith('th') ? language.SHEIN_KEY_PWA_21612 : language.SHEIN_KEY_PWA_21611 }}
            </span>
          </span>
          <div class="giftcard-discount input-icon-cell">
            <code v-if="giftcard.cardNo && price.giftcard && price.giftcard.giftCardUsedPrice">
              <span class="giftcard-currency-used">-{{ price.giftcard.giftCardUsedPrice.amountWithSymbol }}</span><br />
              <span class="giftcard-balance">
                {{ language.SHEIN_KEY_PWA_15717 }}:{{ price.giftcard.giftcardBalanceUSDPrice.amountWithSymbol }}</span>
            </code>
            <i
              class="sui_icon_more_right_16px mcheo-icon-grep"
              :class="[fsIconPrefix]"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'


export default {
  name: 'AssetsGiftcard',
  props: {
    isFolded: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapState('checkout', [
      'checkout',
      'language',
      'giftcard',
      'locals',
      'price',
    ]),
    ...mapGetters('checkout', [
      'fsIconPrefix',
      'isAllStore',
      'isShopType',
    ]),
    IS_RW(){
      return this.locals?.IS_RW ?? gbCommonInfo?.IS_RW
    },
    SiteUID(){
      return this.locals?.SiteUID ?? gbCommonInfo?.SiteUID
    },
    isShowGifcardAmount() {
      return Number(this.price?.giftcard?.giftCardUsedPrice?.amount) > 0
    }
  },
  methods: {
    ...mapMutations('checkout', ['changeParamsStatus']),
    async goGiftCardPage() {
      var giftcardUsedList = false
      if (window.localStorage) {
        const userKey = UserInfoManager.get({ key: 'memberId', actionType: '/checkout/vue_tpls/assets_list/goGiftCardPage' })
        var gift_card_used_records = localStorage.getItem('gift_card_used_records')
        giftcardUsedList = (gift_card_used_records && userKey ? JSON.parse(gift_card_used_records)[userKey] : false) || false
      }
      this.changeParamsStatus({ type: 'pageStatus', params: { giftCardDrawer: true } })
      this.changeParamsStatus({ type: 'giftcard', params: { giftcardUsedList: giftcardUsedList } })

      daEventCenter.triggerNotice({
        daId: '1-11-1-141',
        extraData: {
          is_shop: this.isShopType,
          is_choosed: this.isShowGifcardAmount ? 1 : 0,
          is_folded: this.isFolded,
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.giftcard-discount{
  .txt-r();
  .padding-r(.6rem);
  .giftcard-currency-used{
    font-weight: bold;
    color: @sui_color_discount;
  }
  .giftcard-balance{
    color: #999999;
    .font-dpr(20px);
  }
}
</style>
