<template>
  <div class="mcheo-address address-options-wrapper j-mcheo-address">
    <div
      v-if="isHasAddress"
      v-tap="{
        id: '1-11-1-127',
      }"
    >
      <!-- 宅配 -->
      <div
        v-show="!isShopTransit"
        class="address-home-wrapper"
      >
        <i
          class="sui_icon_location_fill_15px address-home-wrapper__icon"
          :class="[iconPrefix]"
        ></i>
        <div class="address-home-wrapper__detail">
          <AddressHome 
            :addressDetail="sortAddressCardInfo({ detail: checkout.default_address })"
            :secondLanguage="secondLanguage"
            @clickAddressList="clickAddressList"
          />
          <div
            v-if="addressTitleTips"
            tabindex="0"
            class="address-home-wrapper__tips"
          >
            <p
              class="address-home-wrapper__tips-text"
              @click="clickAddressTips"
              v-html="addressTitleTips"
            ></p>
          </div>
        </div>
      </div>

      <!-- 店配 -->
      <s-sticky
        v-if="isShopTransit"
        :offset="offsetTop"
        :offset-scroll="addrHeight"
        :type="'fixed'"
        :fixedWork="(work) => isFixedWork = work"
      >
        <template v-if="!isFixedWork">
          <div
            v-show="isShopTransit"
            class="address-store-wrapper"
          >
            <i
              v-if="storeData && storeData.status == 1"
              class="sui_icon_location_fill_15px address-store-wrapper__icon"
              :class="[iconPrefix]"
            ></i>
            <div :class="isNotStoreAddressScene ? 'address-store-wrapper__no-address-detail' : 'address-store-wrapper__detail'">
              <AddressStore 
                @emitStoreAddress="emitStoreAddress"
              />
              <div
                v-if="addressTitleTips"
                tabindex="0"
                class="mcheo-card address-store-wrapper__tips"
              >
                <p
                  class="address-store-wrapper__tips-text"
                  @click="clickAddressTips"
                  v-html="addressTitleTips"
                ></p>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-if="isNotStoreAddressScene"
            class="address-tips-fixeding"
            @click="emitStoreAddress('add')"
          >
            <div class="address-tips-content">
              <Icon
                name="sui_icon_add_16px"
                size="12px"
              />
              <span class="address-tips-text">{{ language.SHEIN_KEY_PWA_16616 }}</span>
            </div>
          </div>
        </template>
      </s-sticky>
    </div>
    <!-- 当没有宅配地址的时候，引导用户填写地址模块 -->
    <s-sticky
      v-else
      :type="'fixed'"
      :offset="offsetTop"
      :fixedWork="(work) => isNoAddressTipsFixedWork = work"
    >
      <div
        v-expose="{ id:'1-11-1-218' }"
        :class="['no-address-tips', {'address-tips-fixeding': isNoAddressTipsFixedWork }]"
        @click="handleAddAddressClick"
      >
        <div class="address-tips-content">
          <Icon
            name="sui_icon_add_16px"
            :size="isNoAddressTipsFixedWork ? '12px' : '16px'"
          />
          <span class="address-tips-text">{{ language.SHEIN_KEY_PWA_31043 }}</span>
        </div>
      </div>
    </s-sticky>

    <!-- 引导用户填写地址的提醒弹窗 -->
    <ClientOnly>
      <s-dialog
        :visible="isShowNoAddressTipsDialog"
        :show-close="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        @close-from-icon="handleDialogCloseBtnClick"
      >
        <template
          v-if="!isShowSelectShippingAddressTips"
          slot="title"
        >
          <header class="address-dialog-header">
            <img
              class="address-dialog-header__img"
              src="https://img.ltwebstatic.com/images3_ccc/2024/05/08/2c/17151580830d8cf4b3c2f35fc4d71f3e392f21b74b.webp"
              alt=""
            />
            <p class="address-dialog-header__text">
              {{ language.SHEIN_KEY_PWA_31037 }}
            </p>
          </header>
        </template>
        <div class="address-dialog-body">
          {{ isShowSelectShippingAddressTips ? language.SHEIN_KEY_PWA_31310 : language.SHEIN_KEY_PWA_31038 }}
        </div>
        <template
          slot="footer"
        >
          <s-button-group
            hor
            style="width: 100%"
          >
            <s-button-group-item
              :type="['H72PX']"
              @click="handleAddressTipsDialogCancle"
            >
              {{ language.SHEIN_KEY_PWA_31039 }}
            </s-button-group-item>
            <s-button-group-item
              :type="['primary', 'H72PX']"
              @click="handleAddressTipsDialogConfirm"
            >
              {{ language.SHEIN_KEY_PWA_31040 }}
            </s-button-group-item>
          </s-button-group>
        </template>
      </s-dialog>
    </ClientOnly>
  </div>
</template>

<script>
/* globals analysisCheckoutEvent */
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import ClientOnly from 'vue-client-only'
import { template, throttle } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import { sortAddressCardInfo } from 'public/src/pages/components/address/config/tools'

import { Icon } from '@shein-aidc/icon-vue2'
import AddressHome from 'public/src/pages/checkout/vue_tpls/address_options/address_home.vue'
import AddressStore from 'public/src/pages/checkout/vue_tpls/address_options/address_store.vue'
import { fetchCheckTwNameApi } from 'public/src/services/api/pay/checkout.js'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

const { IS_RW, EZ_WAY_APP } = gbCommonInfo
export default {
  name: 'AddressOptions',
  components: {
    Icon,
    AddressHome,
    AddressStore,
    ClientOnly,
  },
  data () {
    return {
      IS_RW,
      adrTitleSaTips: false,
      adrTitleTwTips: false,
      adrTitleIlTips: false,
      krCopywriteTip: '',
      adrTitleSaTipsContent: '',
      isFixedWork: false,
      isNoAddressTipsFixedWork: false,
      addrHeight: 0,
      saTips: '',
      checkTwNameReqParam: '',
      isClient: false,
    }
  },
  computed: {
    ...mapState('checkout', ['language', 'checkout', 'adrTitleTipsShow', 'storeData', 'defaultShipping', 'price', 'noticeHeight', 'secondLanguage', 'addresslist', 'checkoutAbtInfo', 'isShowNoAddressTipsDialog', 'isShowSelectShippingAddressTips', 'isStoreShipAtShowAddressDialog']),
    ...mapGetters('checkout', ['iconPrefix', 'twJumpUrl', 'isShopTransit', 'isNotStoreAddressScene']),
    isHasAddress() {
      return (this.isShopTransit && Object.keys(this.storeData).length > 0) || this.addresslist?.length > 0
    },
    adrTitleRuTips () {
      const default_address = this.checkout && this.checkout.default_address && this.checkout.default_address || {}
      if (default_address.countryId == '178' && (!default_address.passportNumber || !default_address.passportIssuePlace || !default_address.passportIssueDate || !default_address.taxNumber || !default_address.birthday)) {
        if(typeof window != 'undefined') {
          analysisCheckoutEvent.sendToBi({ name: 'expose_constomsinfo_guide' })
        }
        return true
      } else {
        return false
      }
    },
    adrTitlePsTips () {
      const default_address = this.checkout && this.checkout.default_address && this.checkout.default_address || {}
      return default_address.countryId == '165' && !default_address.nationalId
    },
    adrTitleClTips () {
      const default_address = this.checkout && this.checkout.default_address && this.checkout.default_address || {}
      if (default_address.countryId == '43' && !default_address.taxNumber) {
        if(typeof window != 'undefined') {
          analysisCheckoutEvent.sendToBi({ name: 'expose_constomsinfo_guide' })
        }
        return true
      } else {
        return false
      }
    },
    addressTitleTips () {
      const { 
        adrTitleTwTips, 
        adrTitleSaTips, 
        adrTitleRuTips, 
        adrTitleClTips, 
        adrTitleIlTips, 
        krCopywriteTip,
        adrTitlePSTips,
        language
      } = this
      if (adrTitleTwTips) {
        if( !this.twJumpUrl ){
          return language?.SHEIN_KEY_PWA_19578
        }
        let osType = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ? 'ios' : 'android'
        return template(EZ_WAY_APP[osType], language?.SHEIN_KEY_PWA_22202) // "為符合台灣海關實名認證規定，請<a href=\"{0}\">提前下載</a>官方實名認證App“EZ WAY易利委”快速註冊，讓包裹無憂通關！"
      }
      if (adrTitlePSTips) return this.saTips
      if (adrTitleSaTips) return this.saTips
      if (adrTitleRuTips) return language?.SHEIN_KEY_PWA_16997
      if (adrTitleClTips) return language?.SHEIN_KEY_PWA_17123
      if (adrTitleIlTips) return language?.SHEIN_KEY_PWA_17894
      if (krCopywriteTip) return krCopywriteTip
      return ''
    },
    offsetTop() {
      let noticeHeight = this.noticeHeight || 0
      const headerHeight = 1.17
      return headerHeight + (+noticeHeight) * 2 / 75
    },
    analysizeData() {
      return this.isShowSelectShippingAddressTips ? 'shipping_method_interception' : 'first_time_page_guide'
    }
  },
  watch: {
    'price' () {
      this.checkNationalId()
      this.checkTwName()
    },
    'checkout.default_address': function (val) {
      this.checkNationalId()
      this.checkTwName()
      this.getSecondLanguage(val)
      this.checkKRIdentity()
    },
    addressTitleTips: {
      handler(v) {
        if (v) this.handleAddrHeight()
      },
      immediate: true
    },
    storeData: {
      handler(v) {
        if (v) this.handleAddrHeight()
      },
      immediate: true,
      deep: true
    },
    isShowNoAddressTipsDialog(val){
      if(val && this.isClient) {
        daEventCenter.triggerNotice({
          daId: '1-11-1-215',
          extraData: {
            scene: this.analysizeData
          }
        })
      }
    }
  },
  mounted() {
    this.isClient = true
    // 获取当前的地址提示
    this.checkNationalId()
    this.checkTwName()
    this.getSecondLanguage(this.checkout?.default_address)
    this.checkKRIdentity()
  },
  methods: {
    ...mapMutations('checkout', ['assignState', 'updateState']),
    ...mapActions('checkout', ['getSecondLanguage']),
    // 地址模块高度变化后需要更新sticky滚动距离
    handleAddrHeight() {
      if(typeof window == 'undefined') return
      this.$nextTick(() => {
        const addrHeight = $('.j-mcheo-address')?.height() || 0
        this.addrHeight = +addrHeight * 2 / 75
      })
    },
    sortAddressCardInfo,
    closeAdrTitleTips: function () {
      this.assignState({ adrTitleTipsShow: false })
      localStorage && localStorage.setItem('twAddressNameTips', new Date().getTime())
    },
    clickAddressList: function () {
      location.hash = '#addressList'
      this.updateState({
        key: 'showAddrPage', value: true 
      })
    },
    // 当没有地址的时候，增加地址
    handleAddAddressClick(){
      daEventCenter.triggerNotice({
        daId: '1-11-1-214',
        extraData: {
          entry_source: 'no_address_module'
        }
      })
      this.assignState({ isHasBeenNoAddressDrawerShow: false })
      this.$emit('add-default-address')
    },
    emitStoreAddress: function (type) {
      this.$emit('emit-store-address', type)
    },
    checkNationalId () {
      //沙特展示文案取价格计算接口返回
      const { rule_id, hint_type, msg } = this.price?.rule_intercept || {}
      if( rule_id > 0 && hint_type == 3 && msg ){
        this.adrTitleSaTips = true
        this.saTips = msg || this.language.SHEIN_KEY_PWA_18567
      } else {
        this.adrTitleSaTips = false
        this.saTips = ''
      }
    },
    /**
     * @description FR-8218 韩国地址提示
     * @date 2022-02-01 15:18
     * @param {*}
     * @returns
     */
    checkKRIdentity () {
      const defaultAddress = this.checkout && this.checkout.default_address
      if (defaultAddress && defaultAddress.countryId == 197) {
        this.krCopywriteTip = ''
        // 只存在税号，或两者都不存在的时候
        if ((defaultAddress.taxNumber && !defaultAddress.passportNumber) || (!defaultAddress.taxNumber && !defaultAddress.passportNumber)) {
          this.krCopywriteTip = this.language.SHEIN_KEY_PWA_19898
        }
        // 只存在护照号
        if (defaultAddress.passportNumber && !defaultAddress.taxNumber) {
          this.krCopywriteTip = this.language.SHEIN_KEY_PWA_19896
        }
      }
    },
    checkTwName: throttle({ func() {
      const defaultAddress = this.checkout && this.checkout.default_address
      let reqParam = `country_id=${defaultAddress.countryId}&fname=${defaultAddress.fname}&lname=${defaultAddress.lname}&tel=${defaultAddress.tel}`
      if(reqParam === this.checkTwNameReqParam) {
        return
      }
      this.checkTwNameReqParam = `country_id=${defaultAddress.countryId}&fname=${defaultAddress.fname}&lname=${defaultAddress.lname}&tel=${defaultAddress.tel}`
      if (defaultAddress && defaultAddress.countryId == 209) {
        if (TRANSPORT_TYPE_SHOP.includes(this.defaultShipping[0]?.shipping_method?.transport_type) && typeof this.storeData.nameVerified != 'undefined') {
          this.adrTitleTwTips = this.storeData.nameVerified == 0
          if( this.adrTitleTwTips ){
            daEventCenter.triggerNotice({ daId: '1-11-1-106' })
          }
        } else {
          fetchCheckTwNameApi({ country_id: defaultAddress.countryId, fname: defaultAddress.fname, lname: defaultAddress.lname, tel: defaultAddress.tel }).then(data => {
            if (data.code == 0 && data.info && data.info.result && data.info.result.name_verified == 0) {
              if( !this.adrTitleTwTips ){
                daEventCenter.triggerNotice({ daId: '1-11-1-106' })
              }
              this.adrTitleTwTips = true
              this.storeData && (this.storeData.nameVerified = 0)
            } else {
              this.adrTitleTwTips = false
              this.storeData && (this.storeData.nameVerified = 1)
            }
          })
        }
      } else {
        this.adrTitleTwTips = false
      }
    }, wait: 3000 }),
    clickAddressTips (e) {
      const { 
        adrTitleRuTips,
        adrTitleClTips,
        adrTitleIlTips,
        adrTitleTwTips
      } = this

      if (e.target.tagName == 'A' && adrTitleTwTips) {
        daEventCenter.triggerNotice({ daId: '1-11-1-107' })
        return
      }

      if (!adrTitleRuTips && !adrTitleClTips && !adrTitleIlTips) return
      if (e.target.tagName == 'A' && !adrTitleTwTips) {
        this.$emit('edit-default-address')
        analysisCheckoutEvent.sendToBi({ name: 'ClickCustomsClearanceInfoGuide' })
      }
    },
    handleChangeAddressTips() {
      this.assignState({ 
        isShowNoAddressTipsDialog: false,
        isShowSelectShippingAddressTips: false,
        isStoreShipAtShowAddressDialog: false
      })
    },
    handleDialogCloseBtnClick() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-216',
        extraData: {
          btn_type: 'close',
          scene: this.analysizeData
        }
      })
      this.handleChangeAddressTips()
    },
    handleAddressTipsDialogCancle() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-216',
        extraData: {
          btn_type: 'later',
          scene: this.analysizeData
        }
      })
      this.handleChangeAddressTips()
    },
    handleAddressTipsDialogConfirm() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-216',
        extraData: {
          btn_type: 'add',
          scene: this.analysizeData
        }
      })

      this.handleChangeAddressTips()
      setTimeout(() => {
        // 区分选择店配还是选择宅配
        if (this.isStoreShipAtShowAddressDialog) {
          this.emitStoreAddress('edit')
        } else {
          this.$emit('add-default-address')
        }
      }, 350)
    }
  }
}
</script>

<style lang="less" scoped>
.address-options-wrapper {
  background: #fff;
}
.address-home-wrapper {
  position: relative;
  padding: 20/75rem 24/75rem;
  background: #fff;
  display: flex;
  &::before {
    position: absolute;
		.left(0);
		bottom: 0;
		content: '';
		height: 3px;
		width: 100%;
		background-size: 100%;
    background-image: url(/pwa_dist/images/border-bg-b2776f3817.png);
		& when (@IS_RW) {
			background-image: url(/pwa_dist/rw_images/border-bg-13dda8625e.jpg);
		}
  }
  &:active{
    background: @sui_color_gray_weak2;
  }
  &__icon {
    .margin-r(16/75rem);
  }
  &__detail {
    width: 94%;
  }
  &__tips {
    padding: 12/75rem 0 0;
    background: #fff;
		.font-dpr(24px);
    a {
      color: #1860a7;
      text-decoration: none
    }
  }
  &__tips-text {
    background: #F6F6F6;
      padding: 20/75rem;
			color: #666;
  }

  .default {
    padding: 0;
    &::before {
      background-image: none;
      height: 0;
    }
  }
}

.address-store-wrapper {
  position: relative;
  display: flex;
  padding: 20/75rem 24/75rem;
  background: #fff;

  &:active{
    background: @sui_color_gray_weak2;
  }
  &::before {
    position: absolute;
		.left(0);
		bottom: 0;
		content: '';
		height: 3px;
		width: 100%;
		background-size: 100%;
    background-image: url(/pwa_dist/images/store-border-bg-9e28e072d4.png);
  }
  &__icon {
    .margin-r(16/75rem);
  }
  &__detail {
    width: 94%;
  }
  &__no-address-detail {
    width: 100%;
  }
  &__tips {
    padding: 12/75rem 0 0;
    background: #fff;
		.font-dpr(24px);
    a {
      color: #1860a7;
      text-decoration: none
    }
  }
  &__tips-text {
    background: #F6F6F6;
    padding: 20/75rem;
		color: #666;
  }
  .store-address {
    min-height: 120/75rem;
    padding: 0;
    &::before {
      background-image: none;
      height: 0;
    }
  }
}

.no-address-tips {
  position: relative;
  padding: 24/75rem;
  background: #fff;
  &:active{
    background: @sui_color_gray_weak2;
  }
  &::before {
    position: absolute;
		.left(0);
		bottom: 0;
		content: '';
		height: 3px;
		width: 100%;
		background-size: 100%;
    background-image: url(/pwa_dist/images/border-bg-b2776f3817.png);
  }
  .address-tips-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32/75rem 0;
    border-radius: 8/75rem;
    border: 2/75rem dashed var(---sui_color_gray_dark3, #767676);
    background: #FFF;
  }
  .address-tips-text {
    height: 32/75rem;
    margin-left: 8/75rem;
    line-height: 1;
    color: var(---sui_color_brand, #000);
    font-size: 14px;
    font-weight: 700;
  }
}
.address-tips-fixeding {
  padding: 20/75rem 24/75rem;
  background: #FFF;
  &::before {
    width: 0;
    height: 0;
  }
  .address-tips-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12/75rem 0;
    border-radius: 8/75rem;
    border: 2/75rem dashed var(---sui_color_gray_dark3, #767676);
    background: #FFF;
  }
  .address-tips-text {
    height: 32/75rem;
    margin-left: 8/75rem;
    color: var(---sui_color_brand, #000);
    font-weight: 700;
    line-height: 1.4;
    font-size: 12px;
  }
}

.address-dialog-header {
  &__img {
    width: 150/75rem;
    height: 150/75rem;
  }
  &__text {
    margin: 24/75rem 0;
    color: var(---sui_color_gray_dark1, #000);
    text-align: center;
    font-size: 16px;
    font-weight: 700;
  }
}
.address-dialog-body {
  color: var(---sui_color_gray_dark1, #000);
  text-align: center;
  font-size: 14px;
}

.addr-sticky-wrapper {
  font-weight: 700;
  .font-dpr(28px);
  color: #222;
  text-align: center;
  background: #fff;
  &__addr-pad {
    padding: 28/75rem 0;
  }
}

.mcheo-address .mcheo-title {
	.padding-l(.32rem);
}

.mcheo-addr {
	background: #fff;
  &:active{
    background: @sui_color_gray_weak2;
  }
	.align-center();
	position: relative;
	padding: .4rem .32rem;
	&.store-address {
		.checkpay-store {
			flex: 1;
			text-align: center;
		}
	}
	> i {
		.font-dpr(32px);
		color: @sui_color_gray_light1;
	}
}
</style>
