<template>
  <div class="task-points_popover">
    <img
      class="money"
      src="https://img.ltwebstatic.com/images3_ccc/2024/07/09/c4/1720517868f9bee8f850b333ea7db41b12588c8abb.png"
    />
    <div class="point-tips">
      <div class="exrta">
        <!-- <span>Exrta bonus 300</span> -->
        <!-- <img
          class="icon"
          src="https://img.ltwebstatic.com/images3_ccc/2024/07/09/10/1720517926b233f37ee56c7507caa47fbc8e5f2328.png"
        /> -->
        <!-- <span>500 points!</span> -->
        <span>{{ exrtaPoints }}</span>
      </div>
      <div class="time">
        {{ language.SHEIN_KEY_PWA_32179 }}
        <div class="time-text">
          <span>{{ timeText.hours_transform }}</span> : <span>{{ timeText.minutes_transform }}</span> : <span>{{ timeText.seconds_transform }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, computed } from 'vue'
import { useCountDown } from 'public/src/pages/checkout/utils.js'
import { template } from '@shein/common-function'
const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  }
})
const timeText = ref('')
const exrtaPoints = computed(() => template(300, 500, props.language.SHEIN_KEY_PWA_32118))

const timeDownFn = () => {
  const startTime = +Date.parse(new Date()) / 1000
  const endTime = startTime + 10
  useCountDown({
    startTime,
    endTime,
    onCompleted() {
      console.log('已过期---', )
    },
  }, info => {
    const { hours_transform = '00', minutes_transform = '00', seconds_transform = '00' } = info || {}
    timeText.value = { hours_transform, minutes_transform, seconds_transform }
  })
}
onMounted(() => {
  timeDownFn()
})
</script>
<style lang='less' scoded>
.task-points_popover{
    display: flex;
    .money{
        height: 28/37.5rem;
        margin-right: 8/37.5rem;  
    }
    .point-tips{
        .exrta{
            color: var(---sui_color_discount, #FA6338);
            font-size: 12px;
            font-style: normal;
            font-weight: 1000;
            .icon{
                height: 10/37.5rem;
                margin-right: 4/37.5rem;
            }
        }
        .time{
            color: var(---sui_color_white, #FFF);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
        }
    }
    .time{
        display: flex;
        .time-text{
            margin-left: 3/37.5rem;
            color: #FA6338;
            display: flex;
            justify-content: center;
            align-content: center;
            span{
                color: var(---sui_color_white, #FFF);
                font-size: 10px;
                font-style: normal;
                font-weight: 510;
                line-height: normal; 
                min-width: 15/37.5rem;
                min-height: 15/37.5rem;
                background: #FA6338;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 2px;
                margin: 0 2/37.5rem;
            }
        }
    }
}
</style>
