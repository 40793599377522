<template>
  <div class="checkout-activity-popover__wrap">
    <component
      :is="componentInfo.name"
      :key="componentInfo.name"
      class="checkout-activity-popover__item"
      v-bind="componentInfo.props"
      @handleActivityPopoverBehavior="handleActivityPopoverBehavior"
    />
  </div>
</template>

<script>
import Promotion from './Promotion.vue'
import Lowstock from './Lowstock.vue'
import Coupon from './Coupon.vue'
import LowestPrice from './LowestPrice.vue'
import PrimeClub from './PrimeClub.vue'
import SaverCard from './SaverCard.vue'
import TaskPoints from './TaskPoints.vue'

export default {
  name: 'ActivityPopover',
  components: {
    Promotion,
    Lowstock,
    Coupon,
    LowestPrice,
    PrimeClub,
    SaverCard,
    TaskPoints
  },
  props: {
    checkout: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    activityPopoverType: {
      type: String,
      default: ''
    },
    integrityPromotionInfo: {
      type: Object,
      default: () => {}
    },
    promotionList: {
      type: Array,
      default: () => []
    },
    underPriceAbtValue: {
      type: Boolean,
      default: false
    },
    flashSaleGroupList: {
      type: Object,
      default: () => {}
    },
    earliestExpiryCoupon: {
      type: Object,
      default: () => {}
    },
    lowStockCartsData: {
      type: Object,
      default: () => {}
    },
    lowestPriceBySomeDays: {
      type: Object,
      default: () => {}
    },
    primeClubPromotion: {
      type: Array,
      default: () => []
    },
    saverCardPromotion: {
      type: Array,
      default: () => []
    },
    bubbleCopyWritingByAbt: {
      type: Boolean,
      default: false
    },
    isShowNewActivityPopverLayoutByAbt: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    componentList() {
      return [
        {
          name: 'Promotion',
          type: 'promotion',
          props: {
            checkout: this.checkout,
            language: this.language,
            integrityPromotionInfo: this.integrityPromotionInfo,
            underPriceAbtValue: this.underPriceAbtValue,
            flashSaleGroupList: this.flashSaleGroupList,
            promotionList: this.promotionList,
            bubbleCopyWritingByAbt: this.bubbleCopyWritingByAbt,
            isShowNewActivityPopverLayoutByAbt: this.isShowNewActivityPopverLayoutByAbt
          },
        },
        {
          name: 'Lowstock', // 低库存
          type: 'inventory',
          props: {
            checkout: this.checkout,
            language: this.language,
            lowStockCartsData: this.lowStockCartsData,
            bubbleCopyWritingByAbt: this.bubbleCopyWritingByAbt,
            isShowNewActivityPopverLayoutByAbt: this.isShowNewActivityPopverLayoutByAbt
          },
        },
        {
          name: 'Coupon',
          type: 'coupon',
          props: {
            checkout: this.checkout,
            language: this.language,
            coupon: this.earliestExpiryCoupon,
            bubbleCopyWritingByAbt: this.bubbleCopyWritingByAbt,
            isShowNewActivityPopverLayoutByAbt: this.isShowNewActivityPopverLayoutByAbt
          },
        },
        // 任务积分利诱点
        {
          name: 'TaskPoints',
          type: 'taskpoints',
          props: {
            checkout: this.checkout,
            language: this.language,
          },
        },
        {
          name: 'LowestPrice', // 30天最低价
          type: 'lowestprice',
          props: {
            checkout: this.checkout,
            language: this.language,
            lowestPriceBySomeDays: this.lowestPriceBySomeDays,
            bubbleCopyWritingByAbt: this.bubbleCopyWritingByAbt,
            isShowNewActivityPopverLayoutByAbt: this.isShowNewActivityPopverLayoutByAbt
          },
        },
        {
          name: 'PrimeClub', // 随单购付费会员
          type: 'sheinclubpromotion',
          props: {
            checkout: this.checkout,
            language: this.language,
            primeClubPromotion: this.primeClubPromotion,
            bubbleCopyWritingByAbt: this.bubbleCopyWritingByAbt,
            isShowNewActivityPopverLayoutByAbt: this.isShowNewActivityPopverLayoutByAbt
          }
        },
        {
          name: 'SaverCard', // 随单购超省卡
          type: 'saverpromotion',
          props: {
            checkout: this.checkout,
            language: this.language,
            saverCardPromotion: this.saverCardPromotion,
            bubbleCopyWritingByAbt: this.bubbleCopyWritingByAbt,
            isShowNewActivityPopverLayoutByAbt: this.isShowNewActivityPopverLayoutByAbt
          },
        },
      ]
    },
    componentInfo() {
      return this.componentList.find(item => item.type == this.activityPopoverType) || {}
    },
  },
  methods: {
    handleActivityPopoverBehavior(options) {
      this.$emit('handleActivityPopoverBehavior', options)
    }
  },
}
</script>

<style lang="less" scoped>
.checkout-activity-popover__item {
  display: flex;
  align-items: center;
}
</style>
