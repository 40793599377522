<template>
  <li 
    v-if="isShowInsurance"
    v-expose="{
      id: '1-11-1-109',
      data: insuranceAnalysisData
    }"
    class="mshe-flexbetween j-insurance-check insurance-effiency"
  >
    <div class="stand-left">
      <span class="shipping-title">
        <!-- 使用SHEIN标语 -->
        {{ insuranceItem && insuranceItem.hint ? insuranceItem.hint : '' }}
        <span :class="{ 'label__success_green': insuranceItem.isFREE == 'FREE' }">
          {{ insuranceItem.isFREE == 'FREE' ? language.SHEIN_KEY_PWA_24633 : insuranceItem && insuranceItem.insurance_price && insuranceItem.insurance_price.amountWithSymbol }}
        </span>
      </span>
      <i 
        v-enterkey 
        class="sui_icon_doubt_16px_2 icon-tips"
        :class="[iconPrefix]" 
        tabindex="0" 
        role="button" 
        @click="tipsInsurance"
      ></i>
    </div>
    <s-checkbox-switch 
      :value="insuranceCheck"
      :aria-checked="insuranceCheck" 
      tabindex="0" 
      :aria-label="(insuranceItem && insuranceItem.hint ? insuranceItem.hint : '') + insuranceItem.insurance_price.amountWithSymbol" 
      :disabled="!!insuranceItem.isForceCheck"
      role="menuitemradio"
      @click.native="insuranceCheckOpt(insuranceItem)"
    />
  </li>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'

import { CheckboxSwitch as SCheckboxSwitch } from '@shein/sui-mobile'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'ShippingInsurrance',
  components: {
    SCheckboxSwitch,
  },
  props: {
    mallCode: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapState('checkout', [
      'language', 
      'checkout', 
      'mallInsuranceCheck', 
    ]),
    ...mapGetters('checkout', [
      'iconPrefix', 
      'forcedShippingByAbt',
    ]),
    insuranceItem() {
      let insuranceMallData = this.checkout?.results?.insuranceMallData || {}
      const { mall_list = [] } = insuranceMallData
      if (!mall_list.length) return {}
      const filterList = mall_list.filter(item => item.mall_code == this.mallCode)
      const isForceCheck = this.forcedShippingByAbt && +filterList[0]?.is_force_tick === 1
      const { insurance_type, insurance_price } = filterList[0] || {}
      const isFREE = [0, 1].includes(+insurance_type) && insurance_price?.amount == 0 ? 'FREE' : 'noFREE'
      return Object.assign({}, filterList[0] || {}, { isForceCheck, isFREE })
    },
    isDisplayShippingInsurance() {
      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      return mall_list.find(mall => mall.mall_code == this.mallCode)?.isDisplayShippingInsurance || 0
    },
    isShowInsurance() {
      const { insuranceItem, isDisplayShippingInsurance } = this
      if (insuranceItem.insurance_type == 3) return false
      // 当前险种为退货险且配置不支持退货，不展示
      const insuranceTypeFlag = isDisplayShippingInsurance == 0 && insuranceItem?.insurance_type == 1
      const insuranceHintType = insuranceItem.hint
      return !insuranceTypeFlag && insuranceHintType
    },
    insuranceCheck() {
      let mallInsuranceCheck = this.mallInsuranceCheck
      const filterInsurance = mallInsuranceCheck.find(insurance => insurance.mall_code == this.mallCode) || {}
      return !!+filterInsurance?.use_insurance || false
    },
    insuranceAnalysisData () {
      const { insuranceItem = {} } = this
      return {
        select_type: this.insuranceCheck ? 1 : 0,
        is_forced: insuranceItem.isForceCheck ? 1 : 0,
        insurance_type: insuranceItem.insurance_type,
        mall_code: insuranceItem.mall_code,
        default_value: insuranceItem.is_default
      }
    },
    insuranceDescript(){
      const { ControlFreightInFree } = this.checkout?.abtInfo?.FreightInFree?.param || {}
      // 1.退货险，金额为0，abt判断, gift_tips
      if( [0, 1].includes(+this.insuranceItem.insurance_type) && this.insuranceItem.isFREE == 'FREE' && ControlFreightInFree === 'on' && this.insuranceItem.gift_tips ) return this.insuranceItem.gift_tips
      return this.insuranceItem?.description
    },
  },
  methods: {
    tipsInsurance() {
      this.$emit('click-insurance-tips', this.insuranceDescript)
    },
    insuranceCheckOpt(item) {
      this.$nextTick(() => {
        daEventCenter.triggerNotice({
          daId: '1-11-1-108',
          extraData: this.insuranceAnalysisData
        })
      })
      this.$emit('toggle-insurance', item)
    },
  }
}
</script>
