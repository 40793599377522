<template>
  <div>
    <s-sticky
      :type="'fixed'"
      :offset="1.17"
      class="checkout-sticky-notice"
    >
      <div>
        <ClientOnly v-if="mxTaxNotice.fixedNoticeVisible && mxTaxNoticeDesc">
          <!-- 政策条需要等弹窗关闭再出现；若没有弹窗，直接展示政策条 -->
          <s-slide
            :visible="!mxTaxNotice.dialogVisible"
            :duration="!mxTaxNotice.dialogVisible && mxTaxNotice.fixedNoticeVisible ? 0 : 800"
          >
            <div 
              ref="taxNoticeRef"
              class="checkout-sticky-notice__tax"
            >
              <Icon 
                name="sui_icon_activity_orange_18px_color" 
                size="16px"
                color="#FA6338"
                style="line-height: 1;"
              />
              <div v-html="mxTaxNoticeDesc"></div>
            </div>
          </s-slide>
        </ClientOnly>
        <s-alert
          v-else-if="communalNotice"
          class="j-checkout-top-notice checkout-top-notice" 
          type="info" 
          :closable="true"
          :show-icon="true"
          :rtl-icon-roate="true"
          :load-more-txt="language.SHEIN_KEY_PWA_15698"
          :description="communalNotice"
          @close="close"
          @onLoadMoreClick="onLoadMoreClick"
          @onInit="changeCartTop"
        />
        <ClientOnly>
          <network-error-alert :description="language.SHEIN_KEY_PWA_31762" />
        </ClientOnly>
      </div>
    </s-sticky>
    <!-- 公告信息弹窗 -->
    <s-dialog
      :visible.sync="isShowNotic"
      :show-close="false"
      :close-on-click-modal="false"
      append-to-body
    >
      <div v-html="communalNotice"></div>
      <template slot="footer">
        <s-button
          :type="['primary']"
          :width="'100%'"
          @click="noticClosehandle"
        >
          {{ language.SHEIN_KEY_PWA_15312 }}
        </s-button>
      </template>
    </s-dialog>
  </div>
</template>

<script>
import ClientOnly from 'vue-client-only'
import { mapMutations, mapState, mapGetters } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue2'
import { NetworkErrorAlert, Slide as SSlide } from '@shein/sui-mobile'
import { template, times } from '@shein/common-function'

export default {
  components: {
    ClientOnly,
    NetworkErrorAlert,
    Icon,
    SSlide
  },
  data(){
    return {
      isShowNotic: false,
      communalNoticeHeight: 0,
      taxNoticeHeight: 0
    }
  },
  computed: {
    ...mapState('checkout', ['language', 'price', 'noticeHeight', 'mxTaxNotice']),
    ...mapGetters('checkout', ['communalNotice']),
    mxTaxNoticeDesc() {
      if (!this.mxTaxNotice.fixedNoticeVisible) return ''
      const { personal_tax, total_tax, tax_subsidy_list, tax } = this.price || {}
      
      // 个人税金 = 0，代表公司 100% 补贴，否则公司部分补贴
      if (+personal_tax?.amount === 0) {
        return template(
          // 空格占位符
          '&nbsp;',
          total_tax?.amountWithSymbol,
          this.language.SHEIN_KEY_PWA_33565
        )
      } 

      const subsidyItem = tax_subsidy_list?.find(item => +item.tax_type === 7)
      if (!subsidyItem) return ''
      // 实际补贴（公司支付）
      const actualSubsidy = tax?.find(item => item.taxType == 1 && item.taxPayType == 1) || {}
      return template(
        `${times(+subsidyItem.actual_subsidy_ratio, 100)}%`,
        // 空格占位符
        '&nbsp;',
        actualSubsidy.taxPrice.amountWithSymbol,
        this.language.SHEIN_KEY_PWA_33566
      )
    },
  },
  watch: {
    mxTaxNotice: {
      handler(newVal, oldVal) {
        if (typeof window == 'undefined' || JSON.stringify(newVal) === JSON.stringify(oldVal)) return
        this.handleReport()
      },
      immediate: true,
      deep: true
    },
    mxTaxNoticeDesc: {
      handler(desc, oldDesc) {
        if (typeof window == 'undefined' || desc === oldDesc) return
        if (!desc) {
          this.assignState({ noticeHeight: this.noticeHeight - this.taxNoticeHeight })
          this.taxNoticeHeight = 0
          return 
        }
        this.$nextTick(() => {
          if (!this.$refs?.taxNoticeRef) return
          const { clientHeight } = this.$refs.taxNoticeRef
          this.taxNoticeHeight = clientHeight
          this.assignState({ noticeHeight: this.communalNoticeHeight + clientHeight })
        })
      },
      immediate: true
    },
    price: {
      handler(newVal = {}, oldVal = {}) {
        if (typeof window == 'undefined' || JSON.stringify(newVal) === JSON.stringify(oldVal)) return
        this.updateMxTaxStatus()
      },
      deep: true
    }
  },
  mounted () {
    daEventCenter.triggerNotice({ daId: '1-11-1-39' })
  },
  methods: {
    ...mapMutations('checkout', ['assignState', 'updateMxTaxStatus']),
    onLoadMoreClick () {
      daEventCenter.triggerNotice({ daId: '1-11-1-41' })
      this.isShowNotic = true
    },
    close () {
      daEventCenter.triggerNotice({ daId: '1-11-1-40' })
      this.assignState({ noticeHeight: this.noticeHeight - this.communalNoticeHeight })
      this.communalNoticeHeight = 0
    },
    noticClosehandle () {
      this.isShowNotic = false
      daEventCenter.triggerNotice({ daId: '1-11-1-42' })
    },
    changeCartTop () {
      if(typeof window == 'undefined') return

      this.$nextTick(() => {
        const noticeHeight = $('.j-checkout-top-notice').height() || 0
        this.communalNoticeHeight = noticeHeight
        this.assignState({ noticeHeight: this.taxNoticeHeight + noticeHeight })
      })
    },
    handleReport() {
      const { dialogVisible, fixedNoticeVisible } = this.mxTaxNotice
      if (!dialogVisible && fixedNoticeVisible && this.mxTaxNoticeDesc) {
        daEventCenter.triggerNotice({ 
          daId: '1-11-1-263',
          extraData: { TaxShowTypeStatus: 'bar' }
        })
      }
    }
  },
}
</script>

<style lang="less">
.checkout-sticky-notice {
  z-index: 10; // 大件海陆运商品穿透，需要比3大

  &__tax {
    padding: 20/75rem;
    background: #FFF3D3;
    font-size: 12px;
    font-family: Arial;
    color: #000;
    display: flex;
    align-items: center;
    line-height: 1.2;
    transition: height 0.8s;
    gap: 8/75rem;
  }
}
.checkout-top-notice {
  max-height: 2.1rem;
  overflow: hidden;
}
.is-ssr {
  .checkout-top-notice {
    .S-alert__description {
      max-height: 1.38rem;
      overflow: hidden;
      word-break: break-word;
      .font-dpr(24px);
    }
  }
}
</style>
