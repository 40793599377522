<template>
  <!-- 如果是爆破黑名单用户，不管钱包是否有钱都展示入口 riskStr == 2 -->
  <!-- 当walletFreez.showReason为true时，只要钱包有其它货币或者有钱包冻结记录，都展示钱包入口 -->
  <li
    v-enterkey
    v-expose="{
      id: '1-11-1-198',
      data: {
        is_choosed: isShowWallet ? 1 : 0,
        is_folded: isFolded,
      }
    }"
    class="mshe-flexbetween use-coupon flex-wrap assets-wallet__content"
    tabindex="0"
    @click="goWalletPage"
  >
    <div class="stand-left">
      <div class="c-form-group">
        <div class="input-icon">
          <span
            style="white-space: nowrap;"
            :class="{'font': IS_RW}"
            class="input-icon-cell"
          >
            {{ language.SHEIN_KEY_PWA_15109 }}:
            <span
              v-if="isAllStore && IS_RW"
              class="wallet-self"
            >
              {{ SiteUID.endsWith('th') ? language.SHEIN_KEY_PWA_21612 : language.SHEIN_KEY_PWA_21611 }}
            </span>
          </span>
          <div class="use-coupon-right input-icon-cell">
            <code v-show="riskStr != 2">
              <span
                v-if="isShowWallet"
                class="use-red"
              >
                -{{ price.walletPrice.amountWithSymbol }}
              </span>
              <template v-else>
                <span
                  v-if="showWalletTips"
                  class="use-total wallet-tips"
                >
                  {{ language.SHEIN_KEY_PWA_24801 }}
                  <em
                    v-if="!alreadyShowWalletPanel"
                    class="wallet-tips-dot"
                  ></em>
                </span>
              </template>
            </code>
            <i
              class="sui_icon_more_right_16px mcheo-icon-grep"
              :class="[fsIconPrefix]"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState, mapGetters } from 'vuex'


export default {
  name: 'AssetsWallet',
  props: {
    riskStr: {
      type: Number,
      default: 1
    },
    goWalletPage: {
      type: Function,
      default: () => {}
    },
    isFolded: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapState('checkout', [
      'checkout',
      'language',
      'price',
      'locals',
      'alreadyShowWalletPanel',
    ]),
    ...mapGetters('checkout', [
      'fsIconPrefix',
      'isAllStore'
    ]),
    IS_RW(){
      return this.locals?.IS_RW ?? gbCommonInfo?.IS_RW
    },
    SiteUID(){
      return this.locals?.SiteUID ?? gbCommonInfo?.SiteUID
    },
    showWalletTips() {
      return this.price?.wallet?.subtractPrice?.amountWithSymbol && +this.price?.wallet?.subtractPrice?.amount > 0
    },
    isShowWallet() {
      return +this.price?.walletPrice?.amount > 0
    }
  },
}
</script>

<style lang="less" scoped>
.assets-wallet__content {
  .wallet-tips {
    display: inline-flex;
    align-items: center;
  }
  .wallet-tips-dot {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: @sui_color_guide;
    .margin-l(8/75rem);
  }
}
</style>
